import React, { useEffect, useState } from "react";

// Antd - Componets

import { Table, Tag } from "antd";

// @utils
import moment from "moment";
import "moment/locale/tr";
moment.locale("tr");

const WithDrawalTabs = ({ data }) => {
  const [tableData, setTabelData] = useState([]);
  const columns = [
    {
      title: "Hesap Adı Soyadı",
      dataIndex: "AccountName",
      key: "AccountName",
    },
    {
      title: "IBAN",
      dataIndex: "IBAN",
      key: "IBAN",
    },
    {
      title: "İsim",
      dataIndex: "CustomerName",
      key: "CustomerName",
    },
    {
      title: "Miktar",
      dataIndex: "amount",
      key: "amount",
    },
    {
      title: "Talep Tarihi",
      dataIndex: "createdAt",
      key: "createdAt",
    },

    {
      title: "Onay Tarihi",
      dataIndex: "ApprovedDate",
      key: "ApprovedDate",
    },
    {
      title: "Durum",
      dataIndex: "status",
      key: "status",
    },
  ];

  useEffect(() => {
    const dataList = [];
    data.forEach((b) => {
      dataList.push({
        key: b._id,
        AccountName: <>{b.BankAccounts.AccountName}</>,
        IBAN: <>{b.BankAccounts.IBAN}</>,
        CustomerName: <>{b.BankAccounts.CustomerName}</>,
        amount: <>{b.RequestAmount.toFixed(2)}₺</>,
        createdAt: <>{moment(b.createdAt).format("Do MMMM YYYY - HH:mm")}</>,

        ApprovedDate: (
          <>
            {b.Status === "Approved"
              ? moment(b.ApprovedDate).format("Do MMMM YYYY - HH:mm")
              : ""}
          </>
        ),
        status: <Tag color={getColor(b.Status)}>{getText(b.Status)}</Tag>,
      });
    });
    setTabelData(dataList);
    // eslint-disable-next-line
  }, [data]);

  return (
    <Table
      columns={columns}
      dataSource={tableData}
      pagination={false}
      className="tableContainer"
      scroll={{ x: 1200, y: "80%" }}
    />
  );
};

function getColor(data) {
  switch (data) {
    case "Approved":
      return "success";
    case "Rejected":
      return "error";
    default:
      return "warning";
  }
}

function getText(data) {
  switch (data) {
    case "Approved":
      return "Onaylandı";
    case "Rejected":
      return "Reddedildi";
    default:
      return "Onay Bekliyor";
  }
}

export default WithDrawalTabs;
