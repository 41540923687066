import React from "react";

// @Hooks
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

// Antd - Componets
import { Button } from "antd";

// @Redux
import { fetchCity } from "../../redux/actions/auth";

// @styles
import "./SingleCity.scss";

const SingleCity = ({ title, _id }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const onLink = async () => {
    await dispatch(fetchCity(_id));
    history.push(`/update-city`);
  };
  return (
    <div className="single-city-container">
      <h1>{title}</h1>
      <Button type="primary" className="discount-title-link" onClick={onLink}>
        <span>Düzenle</span>
      </Button>
    </div>
  );
};

export default SingleCity;
