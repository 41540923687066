import React, { useState, useCallback, useEffect } from "react";

// Antd - Componets
import { Button, Input, DatePicker, Select } from "antd";

// Icons
import { CloudDownloadOutlined } from "@ant-design/icons";

// Styles
import "./BuyerReport.scss";

// Utils
import ExportJsonExcel from "js-export-excel";
import axios from "axios";
import moment from "moment";
import "moment/locale/tr";
import locale from "antd/es/date-picker/locale/tr_TR";
moment.locale("tr");

const { RangePicker } = DatePicker;
const { Option } = Select;
const BuyerReport = () => {
  const [loading, setLoading] = useState(false);
  const [sectorFilter, setSectorFilter] = useState([]);
  const [cityFilter, setCityFilter] = useState([]);
  const [sector, setSector] = useState();
  const [city, setCity] = useState();
  const [userType, setUserType] = useState();
  const [date, setDate] = useState();
  const [minMoney, setMinMoney] = useState();
  const [maxMoney, setMaxMoney] = useState();
  const [minBonus, setMinBonus] = useState();
  const [maxBonus, setMaxBonus] = useState();
  const [limit, setLimit] = useState();
  const [skip, setSkip] = useState();

  const handelSector = (value) => {
    setSector(value);
  };
  const handelCity = (value) => {
    setCity(value);
  };
  const handelUserType = (value) => {
    setUserType(value);
  };
  const handelMinMoney = (value) => {
    setMinMoney(value.target.value);
  };
  const handelMaxMoney = (value) => {
    setMaxMoney(value.target.value);
  };
  const handelMinBonus = (value) => {
    setMinBonus(value.target.value);
  };
  const handelMaxBonus = (value) => {
    setMaxBonus(value.target.value);
  };

  const handelDate = (value) => {
    const startDate = moment(value[0]).format("YYYY-MM-DD");
    const endDate = moment(value[1]).format("YYYY-MM-DD");
    setDate({ startDate, endDate });
  };

  const handelLimit = (value) => {
    setLimit(value.target.value);
  };
  const handelSkip = (value) => {
    setSkip(value.target.value);
  };

  const handleSubmit = async (values) => {
    setLoading(true);

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const data = {};

    if (sector) {
      data.sector = sector;
    }
    if (city) {
      data.city = city;
    }
    if (userType) {
      data.userType = userType;
    }
    if (date) {
      data.startDate = date.startDate;
      data.endDate = date.endDate;
    }
    if (minMoney && maxMoney) {
      data.minMoney = minMoney;
      data.maxMoney = maxMoney;
    }
    if (minBonus && maxBonus) {
      data.minBonus = minBonus;
      data.maxBonus = maxBonus;
    }
    if (limit) {
      data.limit = limit;
    }
    if (skip) {
      data.skip = skip;
    }

    const body = JSON.stringify(data);
    try {
      const res = await axios.put(
        "https://jetd-backend.herokuapp.com/api/v1/auth/buyerReport",
        body,
        config
      );

      console.log(res.data.data);

      const data = res.data.data;
      const dataList = [];
      data.forEach((b) => {
        dataList.push({
          displayName: b.displayName,
          userType: b.userType,
          email: b.email,
          gender: b.gender,
          phone: b.phone,
          sector: b.sector,
          sales: b.sales.length,
          money: b.money,
          bonus: b.bonus,
          totalSalesAmount: b.totalSalesAmount,
          city: b.city,
          area: b.area,
          expoToken: b.expoToken,
          birthDate: moment(b.birthDate).format("Do MMMM YYYY HH:mm"),
        });
      });
      const option = {
        fileName: `Alıcılar Raporu ${moment().format("DD-MM-YYYY")}`,
        datas: [
          {
            sheetData: dataList,
            sheetName: `Alıcılar Raporu ${moment().format("DD-MM-YYYY")}`,
            sheetFilter: [
              "displayName",
              "userType",
              "email",
              "gender",
              "phone",
              "sector",
              "sales",
              "money",
              "bonus",
              "totalSalesAmount",
              "city",
              "area",
              "expoToken",
              "birthDate",
            ],
            sheetHeader: [
              "Müşteri Adı",
              "Kullanıcı tipi",
              "e-posta",
              "Cinsiyet",
              "telefon",
              "sektör",
              "satış",
              "bakiye",
              "bonus",
              "toplam Satış Tutarı",
              "şehir",
              "ilçe",
              "Bildirim ID",
              "Tarihi",
            ],
          },
        ],
      };
      const toExcel = new ExportJsonExcel(option);
      toExcel.saveExcel();
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const fetchFiltersOptions = useCallback(async () => {
    const sectorRex = await axios.get(
      "https://jetd-backend.herokuapp.com/sector.json"
    );
    const sector = sectorRex.data.map((item) => ({
      value: item.value,
      text: item.label,
    }));
    const cityRex = await axios.get(
      "https://jetd-backend.herokuapp.com/api/v1/city"
    );

    const city = cityRex.data.data.map((item) => ({
      value: item.title,
      text: item.title,
    }));

    setSectorFilter(sector);
    setCityFilter(city);
  }, [setSectorFilter]);

  useEffect(() => {
    fetchFiltersOptions();
  }, [fetchFiltersOptions]);

  return (
    <section className="buyer-report-container">
      <div className="buyer-report-option">
        <Select placeholder="Sector" onChange={handelSector}>
          {sectorFilter.map((item) => (
            <Option key={item.value} value={item.value}>
              {item.text}
            </Option>
          ))}
        </Select>
        <Select placeholder="İl" onChange={handelCity}>
          {cityFilter.map((item) => (
            <Option key={item.value} value={item.value}>
              {item.text}
            </Option>
          ))}
        </Select>

        <Select placeholder="Kullanıcı tipi" onChange={handelUserType}>
          <Option value="Bireysel">Bireysel</Option>
          <Option value="Kurumsal">Kurumsal</Option>
        </Select>

        <RangePicker
          placeholder={["Başlangıç Tarihi", "Bitiş Tarihi"]}
          locale={locale}
          onChange={handelDate}
        />
        <Input
          placeholder="en az Bakiy"
          value={minMoney}
          style={{ width: "10%" }}
          onChange={handelMinMoney}
        />
        <Input
          placeholder="en çok Bakiy"
          value={maxMoney}
          style={{ width: "10%" }}
          onChange={handelMaxMoney}
        />
        <Input
          placeholder="en az Bonus"
          value={minBonus}
          style={{ width: "10%" }}
          onChange={handelMinBonus}
        />
        <Input
          placeholder="en çok Bonus"
          value={maxBonus}
          style={{ width: "10%" }}
          onChange={handelMaxBonus}
        />
      </div>
      <div className="buyer-report-button">
        <Input
          placeholder="limit"
          value={limit}
          style={{ width: "10%" }}
          onChange={handelLimit}
        />
        <Input
          placeholder="skip"
          value={skip}
          style={{ width: "10%" }}
          onChange={handelSkip}
        />
        <Button
          className="btn-add-q"
          htmlType="submit"
          icon={<CloudDownloadOutlined />}
          disabled={loading}
          onClick={handleSubmit}
        >
          {loading ? "Yükleniyor..." : "Raporu İndir"}
        </Button>
      </div>
    </section>
  );
};

export default BuyerReport;

// {loading ? "Yükleniyor..." : "Kaydet"}
