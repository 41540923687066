import React from "react";

// @Styles
import "./SingleAdmin.scss";

// @assets
import logo from "../../assets/wlogo.svg";

// @utils
import moment from "moment";
import "moment/locale/tr";
moment.locale("tr");

const SingleAdmin = ({ displayName, email, phone, createdAt, adminRule }) => {
  return (
    <div className="admin-main">
      <div className="image-container-admin">
        <img src={logo} alt="jetd logo" className="logo-admin-container" />
      </div>
      <div className="admin-info">
        <h3>{displayName}</h3>
        <h4>{email}</h4>
      </div>

      <div className="admin-info">
        <h3>{phone}</h3>
        <h4>{moment(createdAt).format("Do MMMM YYYY")}</h4>
      </div>
      <div className="admin-info">
        <h3>
          <span className="amount-title-admin">role:</span>
          {adminRule}
        </h3>
      </div>
    </div>
  );
};

export default SingleAdmin;
