import React, { useEffect, useCallback, useState } from "react";

// Container
import PagerContainer from "../../layouts/PageContainer/PageContainer";

// Components
import { CopyToClipboard } from "react-copy-to-clipboard";
// Antd - Componets

import { Spin, Input, Table, Button, Tag, Select, Modal, Switch } from "antd";

// Icons
import { LoadingOutlined, CloudDownloadOutlined } from "@ant-design/icons";

// Hooks

import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

// Styles
import "./Buyer.scss";

// @Redxu
import {
  fetchBuyer,
  fetchNextBuyer,
  searchBuyer,
  getSingleBuyer,
  setBuyerSector,
  setBuyerCity,
  setBuyerOrder,
  getBuyerInfo,
  setAdvanced,
} from "../../redux/actions/auth";
import { setAlert } from "../../redux/actions/alerts";

// @Layouts
import UserImage from "../../layouts/UserImage/UserImage";

// @utils
import ExportJsonExcel from "js-export-excel";

import axios from "axios";
import moment from "moment";
import "moment/locale/tr";
moment.locale("tr");

const antIcon = <LoadingOutlined style={{ fontSize: 128 }} spin />;
const { Search } = Input;
const { Option } = Select;

const Buyer = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [searchText, setSearchText] = useState("");
  const [tabelData, setTabelData] = useState([]);
  const [sectorFilter, setSectorFilter] = useState([]);
  const [selectedSector, setSelectedSector] = useState([]);
  const [selectedCity, setSelectedCity] = useState([]);
  const [cityFilter, setCityFilter] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [isSearch, setIsSearch] = useState(false);
  const advancedOptionsBuyer = useSelector(
    (state) => state.auth.advancedOptions
  );
  const [advancedOptions, setAdvancedOptions] = useState(advancedOptionsBuyer);
  const loading = useSelector((state) => state.auth.loading);
  const buyer = useSelector((state) => state.auth.buyer);
  const listLoading = useSelector((state) => state.auth.listLoading);

  const user = useSelector((state) => state.auth.user);

  const rule = user?.adminRule ? user.adminRule : "user";

  const fetchFiltersOptions = useCallback(async () => {
    const sectorRex = await axios.get(
      "https://jetd-backend.herokuapp.com/sector.json"
    );
    const sector = sectorRex.data.map((item) => ({
      value: item.value,
      text: item.label,
    }));
    const cityRex = await axios.get(
      "https://jetd-backend.herokuapp.com/api/v1/city"
    );

    const city = cityRex.data.data.map((item) => ({
      value: item.title,
      text: item.title,
    }));

    setSectorFilter(sector);
    setCityFilter(city);
  }, [setSectorFilter]);

  const handelSector = (value) => {
    setSelectedSector(value);
  };
  const handelCity = (value) => {
    setSelectedCity(value);
  };

  const filterBySector = () => {
    dispatch(setBuyerSector(selectedSector));
  };

  const filterByCity = () => {
    dispatch(setBuyerCity(selectedCity));
  };

  const handleChange = (pagination, filters, sorter) => {
    const { columnKey, order } = sorter;
    console.log(order);
    if (!order) {
      dispatch(setBuyerOrder({}));
      return;
    }
    dispatch(
      setBuyerOrder({
        [columnKey]: order,
      })
    );
  };

  const openModal = () => {
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  const changeSwitch = (kind, value) => {
    setAdvancedOptions({
      ...advancedOptions,
      [kind]: value,
    });
  };

  const searchAd = () => {
    dispatch(setAdvanced(advancedOptions));
    setIsOpen(false);
  };

  const onButtonClick = async () => {
    if (rule === "user") {
      dispatch(setAlert("Başarısız", "bu eylemi yapmaya yetkiniz yok", "fail"));
      return;
    }
    const data = buyer;
    const dataList = [];
    data.forEach((b) => {
      dataList.push({
        displayName: b.displayName,
        userType: b.userType,
        email: b.email,
        gender: b.gender,
        phone: b.phone,
        sector: b.sector,
        sales: b.sales.length,
        totalSalesAmount: b.totalSalesAmount,
        city: b.city,
        area: b.area,
        expoToken: b.expoToken,
        birthDate: moment(b.birthDate).format("Do MMMM YYYY HH:mm"),
      });
    });
    const option = {
      fileName: `Alıcılar Raporu ${moment().format("DD-MM-YYYY")}`,
      datas: [
        {
          sheetData: dataList,
          sheetName: `Alıcılar Raporu ${moment().format("DD-MM-YYYY")}`,
          sheetFilter: [
            "displayName",
            "userType",
            "email",
            "gender",
            "phone",
            "sector",
            "sales",
            "totalSalesAmount",
            "city",
            "area",
            "expoToken",
            "birthDate",
          ],
          sheetHeader: [
            "Müşteri Adı",
            "Kullanıcı tipi",
            "e-posta",
            "Cinsiyet",
            "telefon",
            "sektör",
            "satış",
            "toplam Satış Tutarı",
            "şehir",
            "ilçe",
            "Bildirim ID",
            "Tarihi",
          ],
        },
      ],
    };
    const toExcel = new ExportJsonExcel(option);
    toExcel.saveExcel();
  };

  const onCopyIds = () => {
    if (rule === "user") {
      dispatch(setAlert("Başarısız", "bu eylemi yapmaya yetkiniz yok", "fail"));
      return;
    }
    const token = buyer.map((b) => b.expoToken !== undefined && b.expoToken);
    const tokenToCopy = [];

    token.forEach((t) => {
      if (t) {
        tokenToCopy.push(t);
      }
    });
    const tokenString = tokenToCopy.join(" ");

    navigator.clipboard.writeText(tokenString);
    dispatch(setAlert("", "Bildirimi Id kopyalandı", "success"));
  };
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div className="custom-filter-dropdown">
        <Select
          mode="multiple"
          allowClear
          style={{ width: "90%", margin: "0 auto" }}
          placeholder="Lütfen sektör seçin"
          onChange={handelSector}
        >
          {sectorFilter.map((item) => (
            <Option key={item.value} value={item.value}>
              {item.text}
            </Option>
          ))}
        </Select>
        <div className="filter-button-container">
          <Button type="primary" onClick={filterBySector}>
            Ara
          </Button>
          <div className="filter-div" />
        </div>
      </div>
    ),

    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
  });

  const getColumnSearchPropsCity = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div className="custom-filter-dropdown">
        <Select
          mode="multiple"
          allowClear
          style={{ width: "90%", margin: "0 auto" }}
          placeholder="Lütfen İl seçin"
          onChange={handelCity}
        >
          {cityFilter.map((item) => (
            <Option key={item.value} value={item.value}>
              {item.text}
            </Option>
          ))}
        </Select>
        <div className="filter-button-container">
          <Button type="primary" onClick={filterByCity}>
            Ara
          </Button>
          <div className="filter-div" />
        </div>
      </div>
    ),

    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
  });

  const columns = [
    {
      title: "İsim",
      dataIndex: "userInfo",
      key: "userInfo",
      width: 400,
    },
    {
      title: "Sector",
      dataIndex: "sector",
      key: "sector",
      filters: sectorFilter,
      filterSearch: true,
      ...getColumnSearchProps("sector"),
    },

    {
      title: "İl",
      dataIndex: "city",
      key: "city",
      filters: cityFilter,
      ...getColumnSearchPropsCity("city"),
    },
    {
      title: "İlçe",
      dataIndex: "area",
      key: "area",
    },
    {
      title: "Telefon",
      dataIndex: "phone",
      key: "phone",
    },
    {
      title: "Tarihi",
      dataIndex: "date",
      key: "date",
    },

    {
      title: "hesap bilgisi",
      dataIndex: "provider",
      key: "provider",
    },
    {
      title: "Kullanıcı tipi",
      dataIndex: "userType",
      key: "userType",
    },
    {
      title: "Bakiy",
      dataIndex: "money",
      key: "money",
      sorter: true,
    },
    {
      title: "Bonus",
      dataIndex: "bonus",
      key: "bonus",
      sorter: true,
    },
    {
      title: "İşlemler",
      key: "operation",
      dataIndex: "operation",
    },
  ];

  const getBuyer = useCallback(async () => {
    await fetchFiltersOptions();
    dispatch(fetchBuyer());
  }, [dispatch, fetchFiltersOptions]);

  const onLink = async (id) => {
    await dispatch(getSingleBuyer(id));
    await dispatch(getBuyerInfo(id));
    history.push(`/buyer-details`);
  };

  const onCopy = () => {
    if (rule === "user") {
      dispatch(setAlert("Başarısız", "bu eylemi yapmaya yetkiniz yok", "fail"));
      return;
    }
    dispatch(setAlert("", "Bildirimi ID kopyalandı", "success"));
  };

  useEffect(() => {
    getBuyer();
  }, [getBuyer]);

  useEffect(() => {
    const dataList = [];
    buyer.forEach((b) => {
      dataList.push({
        key: b._id,
        userInfo: (
          <div className="userInfo-container">
            <UserImage photoURL={b.photoURL} />
            <div className="userInfo-text">
              <h1>{b.displayName}</h1>
              <h2>{b.email}</h2>
            </div>
          </div>
        ),

        city: <>{b.city}</>,
        area: <>{b.area}</>,
        phone: <>{b.phone}</>,
        sector: <>{b.sector}</>,
        provider: (
          <div className="userInfo-tags">
            <Tag color={pickColor(b.provider)}>{b.provider}</Tag>
            <Tag color={b.isVerify ? "green" : "red"}>Telefon</Tag>
            <Tag color={b.isComplated ? "green" : "red"}>bilgisi</Tag>
          </div>
        ),
        userType: <>{b.userType}</>,
        money: <>{b.money.toFixed(2)}₺</>,
        bonus: <>{b.bonus.toFixed(2)}₺</>,
        totalBonusEarned: <>{b.totalBonusEarned.toFixed(2)}₺</>,
        date: <>{moment(b.createdAt).format("Do MMMM YYYY")}</>,
        operation: (
          <div className="buyer-operation">
            <Button
              type="primary"
              onClick={onLink.bind(this, b._id)}
              size="small"
            >
              <span> Fazla Bilgi</span>
            </Button>
            <CopyToClipboard text={b.expoToken} disabled={!b.expoToken}>
              <Button
                type="danger"
                onClick={onCopy}
                size="small"
                disabled={!b.expoToken}
              >
                <span> Bildirimi ID</span>
              </Button>
            </CopyToClipboard>
          </div>
        ),
      });
    });
    setTabelData(dataList);
    // eslint-disable-next-line
  }, [buyer]);

  const cahngTextHandler = (e) => {
    setSearchText(e.target.value);
  };

  const searchHandler = () => {
    if (searchText === "") {
      dispatch(fetchBuyer());
      setIsSearch(false);
    } else {
      dispatch(searchBuyer(searchText.trim()));
      setIsSearch(true);
    }
  };

  return (
    <PagerContainer>
      {loading ? (
        <Spin indicator={antIcon} className="spinner" />
      ) : (
        <>
          <div className="buyer-title">
            <h1>Alıcılar</h1>
          </div>
          <div className="search-option-buyer">
            <Button type="primary" onClick={openModal}>
              Gelişmiş Arama
            </Button>
            <div className="log-buttons-container">
              <Button onClick={onButtonClick} icon={<CloudDownloadOutlined />}>
                Raporu İndir
              </Button>
              <Button onClick={onCopyIds} type="danger">
                Bildirimi IDs kopyala
              </Button>
            </div>
            <Modal
              title="Gelişmiş Arama"
              visible={isOpen}
              onOk={searchAd}
              onCancel={closeModal}
              okText="Ara"
              cancelText="Kapat"
            >
              <div className="swtich-container">
                <h1>sadece aktif kullanıcıyı ara</h1>
                <Switch
                  checkedChildren="Evet"
                  unCheckedChildren="Hayır"
                  onChange={changeSwitch.bind(this, "isActive")}
                  defaultChecked={advancedOptions.isActive}
                />
              </div>
              <div className="swtich-container">
                <h1>doğrulanmış telefon numarasına sahip kullanıcı ara</h1>
                <Switch
                  checkedChildren="Evet"
                  unCheckedChildren="Hayır"
                  onChange={changeSwitch.bind(this, "isVerify")}
                  defaultChecked={advancedOptions.isVerify}
                />
              </div>

              <div className="swtich-container">
                <h1>kullanıcıyı yalnızca tamamlanmış hesap bilgileriyle ara</h1>
                <Switch
                  checkedChildren="Evet"
                  unCheckedChildren="Hayır"
                  onChange={changeSwitch.bind(this, "isComplated")}
                  defaultChecked={advancedOptions.isComplated}
                />
              </div>
            </Modal>
          </div>
          <div className="sales-date-container">
            <Search
              enterButton
              size="large"
              placeholder="Alıcı Ara"
              onSearch={searchHandler}
              onChange={cahngTextHandler}
              value={searchText}
            />
          </div>

          <>
            {buyer.length !== 0 ? (
              <>
                <Table
                  columns={columns}
                  dataSource={tabelData}
                  pagination={false}
                  className="tableContainer"
                  loading={listLoading}
                  scroll={{ x: 1400, y: "80%" }}
                  onChange={handleChange}
                />
                <div className="discount-container">
                  <Button
                    type="primary"
                    onClick={() => {
                      dispatch(fetchNextBuyer());
                    }}
                    disabled={isSearch}
                  >
                    Daha Fazla Yükle
                  </Button>
                </div>
              </>
            ) : (
              <h1>Bu e-postayla hiçbir Alıcı bulunamadı </h1>
            )}
          </>
        </>
      )}
    </PagerContainer>
  );
};

export default Buyer;

function pickColor(provider) {
  switch (provider) {
    case "facebook":
      return "#55acee";
    case "google":
      return "#ff6f00";
    case "Apple":
      return "black";
    default:
      return "";
  }
}
