import React from "react";

// @styles
import "./SingleWithdrawC.scss";

// @utils
import moment from "moment";
import "moment/locale/tr";
moment.locale("tr");

const SingleWithdrawC = ({
  BankAccounts,
  BuyerID,
  SellerID,
  BrokerID,
  createdAt,
  RequestAmount,
  ApprovedDate,
  Status,
}) => {
  const { CustomerName, IBAN, BankName } = BankAccounts;

  return (
    <div className="withdraw-main">
      <div className="withdraw-main__column">
        <h1>
          Müşteri adı: <span> {CustomerName}</span>
        </h1>
        <h1>
          IBAN:<span> {IBAN}</span>
        </h1>
        <h1>
          Banka adı: <span>{BankName}</span>
        </h1>
      </div>
      <div className="withdraw-main__column">
        <h1>Hesap Türü :</h1>
        <div className={`tag-container ${BuyerID !== "" ? "tagGreen" : null}`}>
          {BuyerID ? "Alıcı" : SellerID ? "Satıcı" : BrokerID ? "Broker" : ""}
        </div>
        <h1>
          <span>{BuyerID !== "" ? BuyerID : SellerID}</span>
        </h1>
      </div>
      <div className="withdraw-main__column">
        <h1>
          Tarih: <span>{moment(createdAt).format("Do MMMM YYYY - HH:mm")}</span>
        </h1>
        <h1>
          Miktar:
          <span className="important-text">{RequestAmount.toFixed(2)} ₺</span>
        </h1>
      </div>
      <div className="withdraw-main__column">
        {Status === "Approved" && (
          <h1>
            Onay Tarihi:{" "}
            <span>{moment(ApprovedDate).format("Do MMMM YYYY - HH:mm")}</span>
          </h1>
        )}
        <div
          className={`withdraw-status ${
            Status === "Approved" ? "approved" : null
          } `}
        >
          {Status === "Approved" ? "Onaylandı" : "Reddedildi"}
        </div>
      </div>
    </div>
  );
};

export default SingleWithdrawC;
