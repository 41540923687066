import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

// @Router
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

// @Layouts
import Navbar from "../Navbar/Navbar";
import NavPanel from "../NavPanel/NavPanle";
import Alerts from "../Alerts/Alerts";
// @pages
import Login from "../../pages/Login/Login";
import ChangePassword from "../../pages/ChangePassword/ChangePassword";
import Buyer from "../../pages/Buyer/Buyer";
import Seller from "../../pages/Seller/Seller";
import Admin from "../../pages/Admin/Admin";
import Localdiscount from "../../pages/Localdiscount/Localdicount";
import Sale from "../../pages/Sales/Sales";
import AddNewDiscount from "../../pages/AddNewDiscount/AddNewDiscount";
import UpdateDiscount from "../../pages/UpdateDiscount/UpdateDiscount";
import EditSeller from "../../pages/EditSeller/EditSeller";
import Cities from "../../pages/Cities/Cities";
import Invoices from "../../pages/Invoices/Invoices";
import Statistics from "../../pages/Statistics/Statistics";
import AddCity from "../../pages/AddCity/AddCity";
import UpdateCities from "../../pages/UpdateCities/UpdateCities";
import Categories from "../../pages/Categories/Categories";
import CompletedAddMoneyRequest from "../../pages/CompletedAddMoneyRequest/CompletedAddMoneyRequest";
import AddCategory from "../../pages/AddCategory/AddCategory";
import UpdateCategory from "../../pages/UpdateCategory/UpdateCategory";
import Sliders from "../../pages/Sliders/Sliders";
import AddSlider from "../../pages/AddSlider/AddSlider";
import UpdateSlider from "../../pages/UpdateSlider/UpdateSlider";
import WithdrawRequest from "../../pages/WithdrawRequest/WithdrawRequest";
import CompletedWithdraws from "../../pages/CompletedWithdraws/CompletedWithdraws";
import AddMoneyReq from "../../pages/AddMoneyRequest/AddMoneyRequest";
import Notfications from "../../pages/Notfications/Notfications";
import AddAdmin from "../../pages/AddAdmin/AddAdmin";
import EditBuyer from "../../pages/EditBuyer/EditBuyer";
import BuyerDetails from "../../pages/BuyerDetails/BuyerDetails";
import BuyerInfo from "../../pages/BuyerInfo/BuyerInfo";
import EditBuyerEmail from "../../pages/EditBuyerEmail/EditBuyerEmail";
import EditBuyerPassword from "../../pages/EditBuyerPassword/EditBuyerPassword";
import SellerDetails from "../../pages/SellerDetails/SellerDetails";
import EditSellerMoney from "../../pages/EditSellerMoney/EditSellerMoney";
import EditSellerPassword from "../../pages/EditSellerPassword/EditSellerPassword";
import EditSellerEmail from "../../pages/EditSellerEmail/EditSellerEmail";
import AddSellerDiscount from "../../pages/AddSellerDiscount/AddSellerDiscount";
import UserNotfications from "../../pages/UserNotfications/UserNotfications";
import Reports from "../../pages/Reports/Reports";
import UsersStatistics from "../../pages/UsersStatistics/UsersStatistics";
import SalesStatistics from "../../pages/SalesStatistics/SalesStatistics";
import MonthlyStatistics from "../../pages/MonthlyStatistics/MonthlyStatistics";
import WeeklyStatistics from "../../pages/WeeklyStatistics/WeeklyStatistics";
import DailyStatistics from "../../pages/DailyStatistics/DailyStatistics";
import PickMonth from "../../pages/PickMonth/PickMonth";
import Month from "../../pages/Month/Month";
import { loadUser } from "../../redux/actions/auth";

const AppContainer = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(loadUser());
  }, [dispatch]);
  const isAuth = useSelector((state) => state.auth.isAuth);
  return (
    <Router>
      <Alerts />
      {isAuth ? (
        <>
          <nav>
            <Navbar />
            <NavPanel />
          </nav>
          <main>
            <Switch>
              <Route path="/" exact component={Statistics} />
              <Route path="/change-password" exact component={ChangePassword} />
              <Route path="/buyer" exact component={Buyer} />
              <Route path="/buyer-details" exact component={BuyerDetails} />
              <Route path="/seller" exact component={Seller} />
              <Route path="/admins" exact component={Admin} />
              <Route path="/local-discount" exact component={Localdiscount} />
              <Route
                path="/acceptAddMoneyRequest"
                exact
                component={CompletedAddMoneyRequest}
              />
              <Route path="/invoices" exact component={Invoices} />
              <Route path="/sales" exact component={Sale} />
              <Route
                path="/add-new-discount"
                exact
                component={AddNewDiscount}
              />
              <Route
                path="/update-localDiscount"
                exact
                component={UpdateDiscount}
              />
              <Route path="/edit-seller" exact component={EditSeller} />
              <Route path="/edit-buyer" exact component={EditBuyer} />
              <Route path="/buyer-info" exact component={BuyerInfo} />
              <Route path="/buyer-email" exact component={EditBuyerEmail} />
              <Route path="/seller-email" exact component={EditSellerEmail} />
              <Route
                path="/add-seller-discount"
                exact
                component={AddSellerDiscount}
              />
              <Route
                path="/buyer-password"
                exact
                component={EditBuyerPassword}
              />
              <Route
                path="/seller-password"
                exact
                component={EditSellerPassword}
              />
              <Route path="/seller-details" exact component={SellerDetails} />
              <Route
                path="/edit-seller-money"
                exact
                component={EditSellerMoney}
              />

              <Route path="/cities" exact component={Cities} />

              <Route path="/add-city" exact component={AddCity} />
              <Route path="/update-city" exact component={UpdateCities} />
              <Route path="/categories" exact component={Categories} />
              <Route path="/add-category" exact component={AddCategory} />
              <Route path="/update-category" exact component={UpdateCategory} />
              <Route path="/sliders" exact component={Sliders} />
              <Route path="/add-slider" exact component={AddSlider} />
              <Route path="/update-slider" exact component={UpdateSlider} />
              <Route path="/addMoney-request" exact component={AddMoneyReq} />
              <Route path="/notfications" exact component={Notfications} />
              <Route
                path="/user-notfications"
                exact
                component={UserNotfications}
              />
              <Route
                path="/withdraws-completed"
                exact
                component={CompletedWithdraws}
              />
              <Route
                path="/withdraw-request"
                exact
                component={WithdrawRequest}
              />
              <Route path="/add-admin" exact component={AddAdmin} />
              <Route path="/reports" exact component={Reports} />
              <Route
                path="/users-statistics"
                exact
                component={UsersStatistics}
              />

              <Route
                path="/sales-statistics"
                exact
                component={SalesStatistics}
              />

              <Route
                path="/monthly-statistics"
                exact
                component={MonthlyStatistics}
              />

              <Route
                path="/weekly-statistics"
                exact
                component={WeeklyStatistics}
              />

              <Route
                path="/daily-statistics"
                exact
                component={DailyStatistics}
              />
              <Route path="/pick-month" exact component={PickMonth} />
              <Route path="/month" exact component={Month} />
            </Switch>
          </main>
        </>
      ) : (
        <Login />
      )}
    </Router>
  );
};

export default AppContainer;
