import React, { useCallback, useEffect, useState } from "react";
//import { useHistory } from "react-router-dom";

// Container
import PageContainer from "../../layouts/PageContainer/PageContainer";
import UserImage from "../../layouts/UserImage/UserImage";

// Icons
import { LoadingOutlined, CloudDownloadOutlined } from "@ant-design/icons";
// Antd - Componets
import { Spin, Timeline, Table, Tag, Button } from "antd";

// Hooks
import { useDispatch, useSelector } from "react-redux";

// @Redux
import { fetchStatistics } from "../../redux/actions/auth";

// Styles
import "./Statistics.scss";
import ExportJsonExcel from "js-export-excel";
import moment from "moment";
import "moment/locale/tr";
moment.locale("tr");
const antIcon = <LoadingOutlined style={{ fontSize: 128 }} spin />;

const Statistics = () => {
  const dispatch = useDispatch();
  const [localLoading, setLocalLoading] = useState(true);
  const [tabelData, setTabelData] = useState([]);
  const loading = useSelector((state) => state.auth.loading);

  const { logs, todaySales } = useSelector((state) => state.auth);

  const columns = [
    {
      title: "Müşteri Adı",
      dataIndex: "customer",
      key: "customer",
      width: 300,
    },
    {
      title: "Kampanya Adı",
      dataIndex: "title",
      key: "title",
    },

    {
      title: "Tarihi",
      dataIndex: "date",
      key: "date",
    },

    {
      title: "Normal Tutar",
      dataIndex: "salesAmountWithOutDiscount",
      key: "salesAmountWithOutDiscount",
    },
    {
      title: "İndirimli Tutar",
      dataIndex: "salesAmountWithDiscount",
      key: "salesAmountWithDiscount",
    },
    {
      title: "Jetd Komisyonu",
      dataIndex: "jetdProfitAmount",
      key: "jetdProfitAmount",
    },
    {
      title: "Net",
      dataIndex: "netDiscountRate",
      key: "netDiscountRate",
    },

    {
      title: "Ödeme Yöntemi",
      dataIndex: "paymentType",
      key: "paymentType",
    },
    {
      title: "Kazanılan Bonus",
      dataIndex: "settingDetailRateEarned",
      key: "settingDetailRateEarned",
    },
  ];

  const fetchFirstLoad = useCallback(async () => {
    await dispatch(fetchStatistics());
    setTimeout(() => {
      console.log("test");
    }, 5000);
    setLocalLoading(false);
  }, [dispatch, setLocalLoading]);

  useEffect(() => {
    fetchFirstLoad();
  }, [fetchFirstLoad]);

  useEffect(() => {
    const dataTodisplay = todaySales ? todaySales : [];
    const dataList = [];
    dataTodisplay.forEach((b) => {
      dataList.push({
        key: b._id,
        customer: (
          <div className="sale-customer">
            <UserImage
              photoURL={
                b.LocalDiscount ? b.LocalDiscount?.image : b.user.photoURL
              }
            />
            <div>{b.user?.displayName}</div>
          </div>
        ),
        //   image: <UserImage photoURL={b.LocalDiscount?.image} />,

        title: (
          <>
            {b.LocalDiscount?.title > 30
              ? b.LocalDiscount?.title.substring(0, 20) + "..."
              : b.LocalDiscount?.title}
          </>
        ),

        date: <>{moment(b.payedAt).format("Do MMMM YYYY HH:mm")}</>,
        salesAmountWithOutDiscount: (
          <>{b.salesAmountWithOutDiscount.toFixed(2)}₺</>
        ),
        salesAmountWithDiscount: <>{b.salesAmountWithDiscount.toFixed(2)}₺</>,
        jetdProfitAmount: <>{b.jetdProfitAmount.toFixed(2)}₺</>,
        netDiscountRate: <>{b.netDiscountRate}%</>,
        grossDiscountRate: <>{b.grossDiscountRate}%</>,
        paymentType: (
          <Tag
            className="mr-0"
            color={b.paymentType === "Kredi Kartı" ? "blue" : "geekblue"}
          >
            {b.paymentType}
          </Tag>
        ),

        settingDetailRateEarned: (
          <>
            {b.settingDetailRateEarned
              ? b.settingDetailRateEarned.toFixed(2)
              : 0}
            ₺
          </>
        ),
      });
    });
    setTabelData(dataList);
    // eslint-disable-next-line
  }, [todaySales]);

  const onButtonSeClick = async () => {
    const dataList = [];
    todaySales.forEach((b) => {
      dataList.push({
        displayName: b.user.displayName,
        discountName: b.LocalDiscount.title,
        net: b.netDiscountRate,
        paymentType: b.paymentType,
        salesAmountWithOutDiscount: b.salesAmountWithOutDiscount.toFixed(2),
        salesAmountWithDiscount: b.salesAmountWithDiscount.toFixed(2),
        jetdProfitAmount: b.jetdProfitAmount,
        date: moment(b.payedAt).format("Do MMMM YYYY HH:mm"),
      });
    });
    const option = {
      fileName: `Bugünün Satış Raporu ${moment().format("DD-MM-YYYY HH:mm")}`,
      datas: [
        {
          sheetData: dataList,
          sheetName: `Bugünün Satış Raporu ${moment().format("DD-MM-YYYY")}`,
          sheetFilter: [
            "displayName",
            "discountName",
            "net",
            "paymentType",
            "salesAmountWithOutDiscount",
            "salesAmountWithDiscount",
            "jetdProfitAmount",
            "date",
          ],
          sheetHeader: [
            "Müşteri Adı",
            "Kampanya Adı",
            "Net İndirim",
            "Ödeme Yöntemi",
            "Normal Tutar",
            "Ödenen Tutar",
            "Jetd Komisyonu",
            "Tarih",
          ],
        },
      ],
    };
    const toExcel = new ExportJsonExcel(option);
    toExcel.saveExcel();
  };

  return (
    <PageContainer>
      {loading || localLoading ? (
        <Spin indicator={antIcon} className="spinner" />
      ) : (
        <div className="statistics-container">
          <div className="statistics-container__list">
            <h1>Son 50 Panel Etkinliği</h1>
            {logs?.length !== 0 ? (
              <div className="statistics-container__logs">
                <Timeline mode="left">
                  {logs?.map((c) => (
                    <Timeline.Item
                      label={moment(c.createdDate).format(
                        "Do MMMM YYYY - HH:mm"
                      )}
                      key={c._id}
                      color={getColor(randomIntFromInterval(1, 4))}
                    >
                      {c.ProcessDescription}
                    </Timeline.Item>
                  ))}
                </Timeline>
              </div>
            ) : (
              <h1> - </h1>
            )}
          </div>
          <div className="statistics-container__list">
            <h1>Bugünün Satışları Toplam: {todaySales?.length}</h1>
            <div
              className="button-sta-container"
              style={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
                marginBottom: "2rem",
              }}
            >
              <Button
                // onClick={onButtonClick}
                onClick={onButtonSeClick}
                icon={<CloudDownloadOutlined />}
              >
                Raporu İndir
              </Button>
            </div>

            {todaySales.length !== 0 ? (
              <Table
                columns={columns}
                dataSource={tabelData}
                pagination={false}
                className="tableContainer"
                scroll={{ x: 1200, y: 500 }}
                bordered={false}
              />
            ) : (
              <h1> - </h1>
            )}
          </div>
        </div>
      )}
    </PageContainer>
  );
};

function randomIntFromInterval(min, max) {
  // min and max included
  return Math.floor(Math.random() * (max - min + 1) + min);
}

function getColor(data) {
  switch (data) {
    case 1:
      return "blue";
    case 2:
      return "green";
    case 3:
      return "red";
    default:
      return "gray";
  }
}

export default Statistics;
