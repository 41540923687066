import React, { useCallback, useEffect } from "react";
import { useHistory } from "react-router-dom";

// @Components
import PageContainer from "../../layouts/PageContainer/PageContainer";
import SingleCategory from "../../components/SingleCategory/SingleCategory";
// Icons
import { LoadingOutlined } from "@ant-design/icons";
import FeatherIconsReact from "feather-icons-react";

// @Antd - Componets
import { Spin, Button } from "antd";

// @~Redux - Hooks
import { useSelector, useDispatch } from "react-redux";
import { fetchCategories } from "../../redux/actions/auth";

const antIcon = <LoadingOutlined style={{ fontSize: 128 }} spin />;

const Categories = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const loading = useSelector((state) => state.auth.loading);
  const categories = useSelector((state) => state.auth.categories);

  const onLink = () => {
    history.push("/add-category");
  };

  const fetchCategoriesOnFirstLoad = useCallback(async () => {
    await dispatch(fetchCategories());
  }, [dispatch]);

  useEffect(() => {
    fetchCategoriesOnFirstLoad();
  }, [fetchCategoriesOnFirstLoad]);
  return (
    <PageContainer>
      {loading ? (
        <Spin indicator={antIcon} className="spinner" />
      ) : (
        <>
          <div className="cities-container__header">
            <h1>Kategoriler</h1>
            <div>
              <Button
                type="primary"
                className="discount-title-link"
                onClick={onLink}
              >
                <FeatherIconsReact icon="plus" width="1.5rem" />
                <span>Yeni kategori Ekle</span>
              </Button>
            </div>
          </div>

          <div className="cities-container__body">
            {categories.length !== 0 ? (
              <>
                {categories.map((c) => (
                  <SingleCategory key={c._id} {...c} />
                ))}
              </>
            ) : (
              <h1>Veri Bulunamadı.</h1>
            )}
          </div>
        </>
      )}
    </PageContainer>
  );
};

export default Categories;
