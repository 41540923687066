import React from "react";

// @styles
import "./UserInfoTab.scss";

const UserInfoTab = ({ totalAmount, lastSales, totalWithDraw, salesCount }) => {
  return (
    <div className="user-info-tab-container">
      <div className="user-info-card">
        <h1>Toplam Harcama Tutarı</h1>
        <h2>{totalAmount.toFixed(2)}₺</h2>
      </div>
      <div className="user-info-card">
        <h1>Toplam Çekilen Tutar</h1>
        <h2>{totalWithDraw.toFixed(2)}₺</h2>
      </div>
      <div className="user-info-card">
        <h1>Son İşlem Tarihi</h1>
        <h2>{lastSales}</h2>
      </div>
      <div className="user-info-card">
        <h1>Toplam İşlem Sayısı</h1>
        <h2>{salesCount}</h2>
      </div>
    </div>
  );
};

export default UserInfoTab;
