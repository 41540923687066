import React, { useEffect, useState } from "react";

// @Layouts
import UserImage from "../../layouts/UserImage/UserImage";
// Components

import { CopyToClipboard } from "react-copy-to-clipboard";

// Antd - Componets

import { Spin, Table, Button } from "antd";

// Icons
import { LoadingOutlined, CloudDownloadOutlined } from "@ant-design/icons";

// Hooks

import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

// // Styles
// import "./Seller.scss";

// @Redxu
import { getSingleSeller, getSellerInfo } from "../../redux/actions/auth";
//import SingleSeller from "../../components/SingleSeller/SingleSeller";
import { setAlert } from "../../redux/actions/alerts";

// @utils
import ExportJsonExcel from "js-export-excel";
import moment from "moment";
import "moment/locale/tr";
moment.locale("tr");

const antIcon = <LoadingOutlined style={{ fontSize: 128 }} spin />;

const BestSellerMonth = ({ sellerData, header }) => {
  const dispatch = useDispatch();

  const history = useHistory();
  // const onLink = async (id) => {
  //   await dispatch(getSingleSeller(id));
  //   history.push(`edit-seller`);
  // };

  const onLink = async (id) => {
    await dispatch(getSingleSeller(id));
    await dispatch(getSellerInfo(id));
    history.push(`seller-details`);
  };

  const loading = useSelector((state) => state.auth.loading);
  const seller = useSelector((state) => state.auth.seller);
  const listLoading = useSelector((state) => state.auth.listLoading);
  const [tabelData, setTabelData] = useState([]);

  const columns = [
    {
      title: "İsim",
      dataIndex: "userInfo",
      key: "userInfo",
      width: 400,
    },

    {
      title: "Şehir",
      dataIndex: "city",
      key: "city",
      filters: [
        { value: "İstanbul", text: "İstanbul" },
        { value: "Adana", text: "Adana" },
        { value: "Mersin", text: "Mersin" },
      ],
      onFilter: (value, record) => {
        return record.city.props.children === value;
      },
    },
    {
      title: "İlçe",
      dataIndex: "area",
      key: "area",
    },
    {
      title: "Telefon",
      dataIndex: "companyPhone",
      key: "companyPhone",
      width: 200,
    },

    {
      title: "Güncel Cari Tutar",
      dataIndex: "currentSalesAmount",
      key: "currentSalesAmount",
      sorter: (a, b) => {
        return new Intl.Collator("tr", { numeric: true }).compare(
          +a.currentSalesAmount.props.children[0],
          +b.currentSalesAmount.props.children[0]
        );
      },
    },
    {
      title: "Toplam İndirimli Satış",
      dataIndex: "totalSalesAmount",
      key: "totalSalesAmount",
      sorter: (a, b) => {
        return new Intl.Collator("tr", { numeric: true }).compare(
          +a.totalSalesAmount.props.children[0],
          +b.totalSalesAmount.props.children[0]
        );
      },
    },
    {
      title: "Toplam JETD Kazancı",
      dataIndex: "totalJetdCommission",
      key: "totalJetdCommission",
      sorter: (a, b) => {
        return new Intl.Collator("tr", { numeric: true }).compare(
          +a.totalJetdCommission.props.children[0],
          +b.totalJetdCommission.props.children[0]
        );
      },
    },
    {
      title: "Toplam Müşteri Sayısı",
      dataIndex: "customers",
      key: "customers",
      sorter: (a, b) => {
        return new Intl.Collator("tr", { numeric: true }).compare(
          +a.customers.props.children,
          +b.customers.props.children
        );
      },
    },
    {
      title: "Toplam Satış Sayısı",
      dataIndex: "sales",
      key: "sales",
      sorter: (a, b) => {
        return new Intl.Collator("tr", { numeric: true }).compare(
          +a.sales.props.children,
          +b.sales.props.children
        );
      },
    },

    {
      title: "Tarihi",
      dataIndex: "date",
      key: "date",
    },

    {
      title: "İşlemler",
      key: "operation",
      dataIndex: "operation",
      // width: 300,
    },
  ];

  //   const getBuyer = useCallback(async () => {
  //     dispatch(fetchSeller());
  //   }, [dispatch]);

  //   useEffect(() => {
  //     getBuyer();
  //   }, [getBuyer]);

  const onCopy = () => {
    dispatch(setAlert("", "Satıcı Id kopyalandı", "success"));
  };

  const onButtonClick = async () => {
    const data = sellerData;
    const dataList = [];
    data.forEach((b) => {
      dataList.push({
        companyName: b.companyName,
        companyType: b.companyType,
        companyEmail: b.companyEmail,
        companyPhone: b.companyPhone,
        sector: b.sector,
        sales: b.sales.length,
        customers: b.customers.length,
        totalSalesAmount: b.totalSalesAmount,
        currentSalesAmount: b.currentSalesAmount,
        totalSalesAmountWithOutDiscount: b.totalSalesAmountWithOutDiscount,
        totalJetdCommission: b.totalJetdCommission,
        city: b.city,
        area: b.area,
        createdDate: moment(b.createdDate).format("Do MMMM YYYY HH:mm"),
      });
    });
    const option = {
      fileName: `Satıcı Raporu ${moment().format("DD-MM-YYYY")}`,
      datas: [
        {
          sheetData: dataList,
          sheetName: `Satıcı  Raporu ${moment().format("DD-MM-YYYY")}`,
          sheetFilter: [
            "companyName",
            "companyType",
            "companyEmail",
            "companyPhone",
            "sector",
            "sales",
            "customers",
            "totalSalesAmount",
            "currentSalesAmount",
            "totalSalesAmountWithOutDiscount",
            "totalJetdCommission",
            "city",
            "area",
            "createdDate",
          ],
          sheetHeader: [
            "Şirket Adı",
            "Şirket Türü",
            "Şirket e-posta",
            "Şirket Telefon",
            "Sektör",
            "Satış Sayısı",
            "Müşteri Sayısı",
            "toplam Satış Tutarı",
            "Güncel Cari Tutar",
            "Toplam Satış Mikarı Dışarı İndirimli",
            "Toplam JETD Kazancı",
            "Şehir",
            "İlçe",
            "Tarih",
          ],
        },
      ],
    };
    const toExcel = new ExportJsonExcel(option);
    toExcel.saveExcel();
  };

  useEffect(() => {
    const dataList = [];
    sellerData.forEach((b) => {
      dataList.push({
        key: b._id,
        userInfo: (
          <div className="userInfo-container">
            <UserImage photoURL={b.photoURL} isSeller={true} />
            <div className="userInfo-text">
              <h1>{b.companyName}</h1>
              <h2>{b.companyEmail}</h2>
            </div>
          </div>
        ),
        id: (
          <>
            <CopyToClipboard text={b._id}>
              <Button onClick={onCopy}>{b._id}</Button>
            </CopyToClipboard>
          </>
        ),

        city: <>{b.city}</>,
        area: <>{b.area}</>,
        companyPhone: <>{b.companyPhone}</>,
        currentSalesAmount: <>{b.currentSalesAmount.toFixed(2)}₺</>,
        totalSalesAmount: <>{b.totalSalesAmount.toFixed(2)}₺</>,
        totalJetdCommission: <>{b.totalJetdCommission.toFixed(2)}₺</>,
        customers: <>{b.customers.length}</>,
        sales: <>{b.sales.length}</>,
        date: <>{moment(b.createdDate).format("Do MMMM YYYY")}</>,
        operation: (
          <div className="buyer-operation">
            {/* <Button
              type="danger"
              className="questions-title-link"
              onClick={() => {
                onLink(b._id);
              }}
              size="small"
            >
              <span>Düzenle</span>
            </Button> */}
            <Button
              type="danger"
              className="questions-title-link"
              onClick={onLink.bind(this, b._id)}
              size="small"
            >
              <span> Fazla Bilgi</span>
            </Button>
          </div>
        ),
      });
    });
    setTabelData(dataList);
    // eslint-disable-next-line
  }, [seller]);

  return (
    <div>
      {loading ? (
        <Spin indicator={antIcon} className="spinner" />
      ) : (
        <>
          <div className="seller-title">
            <h1>{header}</h1>
          </div>
          <div className="seller-report-button">
            <Button onClick={onButtonClick} icon={<CloudDownloadOutlined />}>
              Raporu İndir
            </Button>
          </div>
          {sellerData.length !== 0 ? (
            <>
              <Table
                columns={columns}
                dataSource={tabelData}
                pagination={false}
                // className="tableContainer"
                loading={listLoading}
                scroll={{ x: 1500, y: 800 }}
              />
              {/* <Button
                type="danger"
                onClick={() => {
                  dispatch(fetchNextSeller());
                }}
              >
                Daha Fazla Yükle
              </Button> */}
            </>
          ) : (
            <h1>Bu e-postayla hiçbir Satıcı bulunamadı </h1>
          )}
        </>
      )}
    </div>
  );
};

export default BestSellerMonth;
