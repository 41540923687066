import React from "react";

// @styles
import "./SingleCompletedAddMoneyRequest.scss";

// @utils
import moment from "moment";
import "moment/locale/tr";
moment.locale("tr");

const SingleCompletedAddMoneyRequest = (props) => {
  const { _id, user, type, amount, bonusRateEarned, isBonus, createdAt } =
    props;

  return (
    <div className="discount-main">
      <div className="discount-title-container">
        <h1>
          <span className="amount-title-discount">
            Müşteri Adı: {user?.displayName}
          </span>
        </h1>
      </div>
      <div className="discount-title-container">
        {isBonus ? "Bonus" : "Bakiye"}
      </div>
      <div className="discount-info">
        <h1>Tutar: {amount.toFixed(2)}</h1>
        <h2>Ödeme Türü: {type}</h2>
        {bonusRateEarned > 0 && (
          <>
            <h2>
              Kazanılan Bonus Tutar:{" "}
              {(amount * (bonusRateEarned / 100)).toFixed(2)}
            </h2>
            <h2>Kazanılan Bonus %: {bonusRateEarned.toFixed(2)}</h2>
          </>
        )}
      </div>
      <div className="discount-info">
        <h2>{moment(createdAt).format("Do MMMM YYYY HH:mm")}</h2>
        <h4>{_id}</h4>
      </div>
    </div>
  );
};

export default SingleCompletedAddMoneyRequest;
