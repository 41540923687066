import React from "react";

// Styles
import "./Login.scss";

// Components
import Logo from "../../components/Logo/Logo";

// Images
import image from "../../assets/login-1.png";
import image2 from "../../assets/login-2.png";
import image3 from "../../assets/login-3.png";

// Comonents
import LoginForm from "../../components/LoginForm/LoginForm";

const Login = () => {
  return (
    <section className="loginContainer">
      <div className="title-card">
        <Logo />
        <h1> Yönetim Paneli</h1>
        <img src={image} alt="login page  one" className="login__image-one" />
        <img src={image2} alt="login page  two" className="login__image-two" />
        <img
          src={image3}
          alt="login page  three"
          className="login__image-three"
        />
      </div>

      <LoginForm styles="login__form" />
    </section>
  );
};

export default Login;
