import { initializeApp } from "firebase/app";
import { getStorage } from "firebase/storage";

const app = initializeApp({
  // apiKey: "AIzaSyAzy5lHxald630JUJAX3V7-ESiPu8p3HGI",
  // authDomain: "jetdtest.firebaseapp.com",
  // projectId: "jetdtest",
  // storageBucket: "jetdtest.appspot.com",
  // messagingSenderId: "554826489750",
  // appId: "1:554826489750:web:c81c056ba588052d4447b7",
  // measurementId: "G-MW5DZZZ9RF",
  apiKey: "AIzaSyDfGN6r3GWQPTn6bPw3ppMZEVqxJdUC8D4",
  authDomain: "jetd-40373.firebaseapp.com",
  databaseURL: "https://jetd-40373.firebaseio.com",
  projectId: "jetd-40373",
  storageBucket: "jetd-40373.appspot.com",
  messagingSenderId: "318428341336",
  appId: "1:318428341336:web:820114867d995c298ad62d",
  measurementId: "G-ZE8JX12M6B",
});

export const storge = getStorage(app);
export default app;
