import React, { useState } from "react";
import { useHistory } from "react-router-dom";

// Container
import PagerContainer from "../../layouts/PageContainer/PageContainer";

// Icons
import { LoadingOutlined, InboxOutlined } from "@ant-design/icons";

// Antd - Componets
import { Spin, Input, Button, Upload, Image, Progress, Form } from "antd";

// Redux
import { FETCH_DROPDWON_INFO_SUCCESS } from "../../redux/types";
import { createCity, createLogs } from "../../redux/actions/auth";

// Hooks
import { useDispatch, useSelector } from "react-redux";

// Utils
import { v4 as uuidv4 } from "uuid";
import { storge } from "../../config/firebase";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { fallback } from "../../utils/fallback";

// Styles
import "./AddCity.scss";

const antIcon = <LoadingOutlined style={{ fontSize: 128 }} spin />;
const { Dragger } = Upload;

const AddCity = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const loading = useSelector((state) => state.auth.loading);
  const [image, setImage] = useState();
  const [showProgress, setShowProgress] = useState(false);
  const [progressNumber, setProgressNumber] = useState(0);
  const adminName = useSelector((state) => state.auth.user.displayName);
  const [form] = Form.useForm();

  const props = {
    beforeUpload(file, fileList) {
      setShowProgress(true);

      const metadata = {
        contentType: "image/jpeg",
      };
      const imageRef = ref(storge, `images/cities/main/${uuidv4()}`);
      const uploadTask = uploadBytesResumable(imageRef, file, metadata);
      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          setProgressNumber(progress.toFixed(2));
        },
        (error) => {
          // Handle unsuccessful uploads
          console.log(error);
        },
        () => {
          getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
            setImage(downloadURL);

            setShowProgress(false);
            dispatch({
              type: FETCH_DROPDWON_INFO_SUCCESS,
            });
          });
        }
      );

      return Upload.LIST_IGNORE;
    },
  };

  const onLink = () => {
    history.push(`/cities`);
  };

  const handleSubmit = async (values) => {
    const data = {
      title: values.title,
    };
    if (image) {
      data.popupImage = image;
    }
    if (values.popupAction) {
      data.popupAction = values.popupAction;
    }

    await dispatch(createCity(data));

    let log = {
      ProcessName: "CreateCity",
      ProcessDescription: `(${adminName}) Admin ,${data.title} isimli şehir eklendi. `,
      SellerId: null,
      UserId: null,
      Request: JSON.stringify(data),
    };
    await dispatch(createLogs(log));

    form.resetFields();
    history.push(`/cities`);
  };
  return (
    <PagerContainer>
      {loading ? (
        <Spin indicator={antIcon} className="spinner" />
      ) : (
        <>
          <div className="add-city-container">
            <div className="cities-container__header">
              <h1>Şehir Ekle</h1>
              <div>
                <Button
                  type="primary"
                  className="discount-title-link"
                  onClick={onLink}
                >
                  <span>Geri Git</span>
                </Button>
              </div>
            </div>
            <div className="add-city__upload-images-container">
              {showProgress ? (
                <div className="progress">
                  <Progress type="circle" percent={progressNumber} />
                </div>
              ) : (
                <div className="upload-main">
                  <div className="uploadsContainer">
                    <div className="uploader">
                      <p className="image-upload-title">
                        Popup Resim (Opsiyonel)
                      </p>
                      <Dragger
                        {...props}
                        disabled={loading}
                        className="image-uploader"
                      >
                        <p className="ant-upload-drag-icon">
                          <InboxOutlined />
                        </p>
                        <p className="ant-upload-text">Yükle</p>
                      </Dragger>
                    </div>
                  </div>
                  <div className="images-container">
                    {image && (
                      <div className="image-container-preview">
                        <Image width="100%" src={image} fallback={fallback} />
                      </div>
                    )}
                  </div>
                </div>
              )}
            </div>
            <div className="form-container">
              <Form
                layout="vertical"
                onFinish={handleSubmit}
                className="discount-add-form"
                form={form}
                name="addCity"
              >
                <Form.Item
                  name="title"
                  label="Şehir Adı"
                  rules={[
                    {
                      message: "Şehir Adı Giriniz.",
                      required: true,
                    },
                  ]}
                >
                  <Input />
                </Form.Item>

                <Form.Item
                  name="popupAction"
                  label="Popup tıklanıldığında açılacak kampanya Id"
                  rules={[
                    {
                      message:
                        "Popup tıklanıldığında açılacak kampanya Id Giriniz.",
                      required: !image ? false : true,
                    },
                  ]}
                >
                  <Input disabled={!image} />
                </Form.Item>

                <Form.Item>
                  <Button
                    className="btn-add-q"
                    htmlType="submit"
                    type="primary"
                    size="large"
                    disabled={showProgress}
                  >
                    {showProgress ? "Yükleniyor..." : "Kaydet"}
                  </Button>
                </Form.Item>
              </Form>
            </div>
          </div>
        </>
      )}
    </PagerContainer>
  );
};

export default AddCity;
