import React, { useEffect, useState } from "react";

// Antd - Componets

import { Table, Tag, Button } from "antd";

// layouts
import UserImage from "../../layouts/UserImage/UserImage";

// Redux
import { getSingleDiscount } from "../../redux/actions/auth";
import { setAlert } from "../../redux/actions/alerts";

import { useDispatch } from "react-redux";

// @ Hooks
import { useHistory } from "react-router-dom";

// @utils
import { CopyToClipboard } from "react-copy-to-clipboard";
import moment from "moment";
import "moment/locale/tr";
moment.locale("tr");

const DiscountSellerTab = ({ data }) => {
  const [tableData, setTabelData] = useState([]);
  const history = useHistory();
  const dispatch = useDispatch();
  const columns = [
    {
      title: "Kampanya Adı",
      dataIndex: "discountDetail",
      key: "discountDetail",
    },
    {
      title: "İndirim Oranı(Brüt)",
      dataIndex: "grossDiscountRate",
      key: "grossDiscountRate",
    },

    {
      title: "İnidirim Oranı",
      dataIndex: "netDiscountRate",
      key: "netDiscountRate",
    },
    {
      title: "Durum",
      dataIndex: "status",
      key: "status",
    },
    {
      title: "ID",
      dataIndex: "discountId",
      key: "discountId",
    },
    {
      title: "İşlemler",
      key: "operation",
      dataIndex: "operation",
    },
  ];

  useEffect(() => {
    const dataList = [];
    data.forEach((b) => {
      dataList.push({
        key: b._id,
        discountDetail: (
          <div className="userInfo-container">
            <UserImage photoURL={b.image} isSeller={true} />
            <div className="userInfo-text">
              <h1>{b.title}</h1>
            </div>
          </div>
        ),

        grossDiscountRate: <>{b.grossDiscountRate}%</>,
        netDiscountRate: <>{b.netDiscountRate}%</>,
        status: <Tag color={getColor(b.status)}>{getText(b.status)}</Tag>,
        discountId: (
          <>
            <CopyToClipboard text={b._id}>
              <Button onClick={onCopy} type="danger">
                ID kopyala
              </Button>
            </CopyToClipboard>
          </>
        ),
        operation: (
          <Button type="primary" onClick={onChange.bind(this, b._id)}>
            Düzenle
          </Button>
        ),
      });
    });
    setTabelData(dataList);
    // eslint-disable-next-line
  }, [data]);

  const onChange = async (id) => {
    await dispatch(getSingleDiscount(id));
    history.push(`/update-localDiscount`);
  };

  const onCopy = () => {
    dispatch(setAlert("", "Kampanya Id kopyalandı", "success"));
  };

  return (
    <Table
      columns={columns}
      dataSource={tableData}
      pagination={false}
      className="tableContainer"
      scroll={{ x: 1200, y: "80%" }}
    />
  );
};

function getColor(data) {
  switch (data) {
    case "Approved":
      return "success";
    case "Rejected":
      return "error";
    default:
      return "warning";
  }
}

function getText(data) {
  switch (data) {
    case "Approved":
      return "Onaylandı";
    case "Rejected":
      return "Reddedildi";
    default:
      return "Onay Bekliyor";
  }
}

export default DiscountSellerTab;
