import React, { useState } from "react";
import { useHistory } from "react-router-dom";

// Container
import PagerContainer from "../../layouts/PageContainer/PageContainer";

// Icons
import { LoadingOutlined, InboxOutlined } from "@ant-design/icons";

// Antd - Componets
import {
  Spin,
  Input,
  Button,
  Upload,
  Image,
  Progress,
  Form,
  Select,
} from "antd";

// Redux
import { FETCH_DROPDWON_INFO_SUCCESS } from "../../redux/types";
import { updateCategory, createLogs } from "../../redux/actions/auth";
import { setAlert } from "../../redux/actions/alerts";

// Hooks
import { useDispatch, useSelector } from "react-redux";

// Utils
import { v4 as uuidv4 } from "uuid";
import { storge } from "../../config/firebase";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { fallback } from "../../utils/fallback";

const antIcon = <LoadingOutlined style={{ fontSize: 128 }} spin />;
const { Dragger } = Upload;

const UpdateCategory = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const loading = useSelector((state) => state.auth.loading);
  const category = useSelector((state) => state.auth.category);
  const [image, setImage] = useState(category.icon);
  const [showProgress, setShowProgress] = useState(false);
  const [progressNumber, setProgressNumber] = useState(0);
  const adminName = useSelector((state) => state.auth.user.displayName);
  const [form] = Form.useForm();

  const props = {
    beforeUpload(file, fileList) {
      setShowProgress(true);

      const metadata = {
        contentType: "image/jpeg",
      };
      const imageRef = ref(storge, `images/cities/main/${uuidv4()}`);
      const uploadTask = uploadBytesResumable(imageRef, file, metadata);
      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          setProgressNumber(progress.toFixed(2));
        },
        (error) => {
          // Handle unsuccessful uploads
          console.log(error);
        },
        () => {
          getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
            setImage(downloadURL);

            setShowProgress(false);
            dispatch({
              type: FETCH_DROPDWON_INFO_SUCCESS,
            });
          });
        }
      );

      return Upload.LIST_IGNORE;
    },
  };

  const onLink = () => {
    history.push(`/categories`);
  };

  const handleSubmit = async (values) => {
    const data = {
      value: values.value,
      icon: image,
      sortOrderNumber: +values.sortOrderNumber,
    };

    if (!image) {
      return dispatch(
        setAlert("Başarısız", "Kategori Resmi Ekleyiniz.", "fail")
      );
    }

    await dispatch(updateCategory(data, category._id));

    let log = {
      ProcessName: "UpdateCategory",
      ProcessDescription: `(${adminName}) Admin ,${data.value} isimli kategori güncellendi. , ID: ${category._id} `,
      SellerId: null,
      UserId: null,
      Request: JSON.stringify(data),
    };

    await dispatch(createLogs(log));

    form.resetFields();
    history.push(`/categories`);
  };
  return (
    <PagerContainer>
      {loading ? (
        <Spin indicator={antIcon} className="spinner" />
      ) : (
        <>
          <div className="add-city-container">
            <div className="cities-container__header">
              <h1>Kategori Güncelle</h1>
              <div>
                <Button
                  type="primary"
                  className="discount-title-link"
                  onClick={onLink}
                >
                  <span>Geri Git</span>
                </Button>
              </div>
            </div>
            <div className="add-city__upload-images-container">
              {showProgress ? (
                <div className="progress">
                  <Progress type="circle" percent={progressNumber} />
                </div>
              ) : (
                <div className="upload-main">
                  <div className="uploadsContainer">
                    <div className="uploader">
                      <p className="image-upload-title">Kategori Resim :</p>
                      <Dragger
                        {...props}
                        disabled={loading}
                        className="image-uploader"
                      >
                        <p className="ant-upload-drag-icon">
                          <InboxOutlined />
                        </p>
                        <p className="ant-upload-text">Yükle</p>
                      </Dragger>
                    </div>
                  </div>
                  <div className="images-container">
                    {image && (
                      <div className="image-container-preview">
                        <Image width="100%" src={image} fallback={fallback} />
                      </div>
                    )}
                  </div>
                </div>
              )}
            </div>
            <div className="form-container">
              <Form
                layout="vertical"
                onFinish={handleSubmit}
                className="discount-add-form"
                form={form}
                name="UpdateCategory"
              >
                <Form.Item
                  name="value"
                  initialValue={category.value}
                  label="Kategori Adı"
                  rules={[
                    {
                      message: "Kategori Adı Giriniz.",
                      required: true,
                    },
                  ]}
                >
                  <Input />
                </Form.Item>

                <Form.Item
                  name="sortOrderNumber"
                  initialValue={category.sortOrderNumber}
                  label="Sıra No"
                  rules={[{ message: "Sıra No Seçiniz.", required: true }]}
                >
                  <Select style={{ width: "100%" }} placeholder="">
                    <Select.Option value="1">1</Select.Option>
                    <Select.Option value="2">2</Select.Option>
                    <Select.Option value="3">3</Select.Option>
                    <Select.Option value="4">4</Select.Option>
                    <Select.Option value="5">5</Select.Option>
                    <Select.Option value="6">6</Select.Option>
                    <Select.Option value="7">7</Select.Option>
                    <Select.Option value="8">8</Select.Option>
                    <Select.Option value="9">9</Select.Option>
                    <Select.Option value="10">10</Select.Option>
                  </Select>
                </Form.Item>

                <Form.Item>
                  <Button
                    className="btn-add-q"
                    htmlType="submit"
                    type="primary"
                    size="large"
                    disabled={showProgress}
                  >
                    {showProgress ? "Yükleniyor..." : "Kaydet"}
                  </Button>
                </Form.Item>
              </Form>
            </div>
          </div>
        </>
      )}
    </PagerContainer>
  );
};

export default UpdateCategory;
