import React, { useEffect, useCallback, useState } from "react";

import axios from "axios";

// Container
import PagerContainer from "../../layouts/PageContainer/PageContainer";

// Antd - Componets
import { Button, Form, Input, Select, Spin } from "antd";

// Icons
import { LoadingOutlined } from "@ant-design/icons";

// Hooks

import { useDispatch, useSelector } from "react-redux";

// Redux
import {
  FETCH_DROPDWON_INFO_START,
  FETCH_DROPDWON_INFO_SUCCESS,
} from "../../redux/types";

import { createNotfication, createLogs } from "../../redux/actions/auth";

const antIcon = <LoadingOutlined style={{ fontSize: 128 }} spin />;

const { TextArea } = Input;
const { Option } = Select;

const Notfications = () => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const loading = useSelector((state) => state.auth.loading);
  const adminName = useSelector((state) => state.auth.user.displayName);
  const [cityArr, setCityArr] = useState([]);

  const handleSubmit = (value) => {
    const data = {
      city: value.city,
      title: value.title,
      body: value.body,
    };

    dispatch(createNotfication(data));

    let log = {
      ProcessName: "SendNotification",
      ProcessDescription: `(${adminName}) Admin ,${data.city} için ${data.title} bildirim gönderildi. `,
      SellerId: null,
      UserId: null,
      Request: JSON.stringify(data),
    };
    dispatch(createLogs(log));

    form.resetFields();
  };

  const getLabel = useCallback(async () => {
    dispatch({
      type: FETCH_DROPDWON_INFO_START,
    });

    try {
      const citiesRes = await axios.get(
        "https://jetd-backend.herokuapp.com/api/v1/city"
      );

      const cities = citiesRes.data.data.map((item) => {
        return {
          value: item.title,
          label: item.title,
        };
      });

      cities.unshift({
        value: "all",
        label: "Hepsi",
      });

      setCityArr(cities);

      dispatch({
        type: FETCH_DROPDWON_INFO_SUCCESS,
      });
    } catch (error) {
      console.log(error);
    }
  }, [dispatch]);

  useEffect(() => {
    getLabel();
  }, [getLabel]);
  return (
    <PagerContainer>
      {loading ? (
        <Spin indicator={antIcon} className="spinner" />
      ) : (
        <>
          <div className="add-discount-container">
            <div className="discount-title">
              <h1>Yeni Bildirim Gönder</h1>
            </div>

            <div className="form-container">
              <Form
                layout="vertical"
                onFinish={handleSubmit}
                className="discount-add-form"
                form={form}
                name="addDiscount"
              >
                <Form.Item
                  name="title"
                  label="Bildirim Başlığı"
                  rules={[
                    {
                      message: "Bilirim Başlığı Giriniz.",
                      required: true,
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  name="body"
                  label="Bildirim Açıklama"
                  rules={[{ message: "Açıklama Giriniz.", required: true }]}
                >
                  <TextArea rows={2} placeholder="" />
                </Form.Item>

                <Form.Item
                  name="city"
                  label="Şehir"
                  rules={[{ message: "Şehir Seçiniz.", required: true }]}
                >
                  <Select style={{ width: "100%" }} placeholder="">
                    {cityArr.map((c) => (
                      <Option value={c.value} key={c.value}>
                        {c.label}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>

                <Form.Item>
                  <Button
                    className="btn-add-q"
                    htmlType="submit"
                    type="primary"
                    size="large"
                    disabled={loading}
                  >
                    {loading ? "Yükleniyor..." : "Gönder"}
                  </Button>
                </Form.Item>
              </Form>
            </div>
          </div>
        </>
      )}
    </PagerContainer>
  );
};

export default Notfications;
