import React, { useEffect, useState } from "react";

// Components
import { CopyToClipboard } from "react-copy-to-clipboard";
// Antd - Componets

import { Spin, Table, Button } from "antd";

// Icons
import { LoadingOutlined, CloudDownloadOutlined } from "@ant-design/icons";

// Hooks

import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

// @Redxu
import { getSingleBuyer, getBuyerInfo } from "../../redux/actions/auth";
import { setAlert } from "../../redux/actions/alerts";

// @Layouts
import UserImage from "../../layouts/UserImage/UserImage";

// @utils
import ExportJsonExcel from "js-export-excel";

import moment from "moment";
import "moment/locale/tr";
moment.locale("tr");

const antIcon = <LoadingOutlined style={{ fontSize: 128 }} spin />;

const Buyer = ({ buyerData, header }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [tabelData, setTabelData] = useState([]);

  const loading = useSelector((state) => state.auth.loading);
  const buyer = useSelector((state) => state.auth.buyer);
  const listLoading = useSelector((state) => state.auth.listLoading);

  const onButtonClick = async () => {
    const data = buyerData;
    const dataList = [];
    data.forEach((b) => {
      dataList.push({
        displayName: b.displayName,
        userType: b.userType,
        email: b.email,
        gender: b.gender,
        phone: b.phone,
        sector: b.sector,
        sales: b.sales.length,
        totalSalesAmount: b.totalSalesAmount,
        city: b.city,
        area: b.area,
        expoToken: b.expoToken,
        birthDate: moment(b.birthDate).format("Do MMMM YYYY HH:mm"),
      });
    });
    const option = {
      fileName: `Alıcılar Raporu ${moment().format("DD-MM-YYYY")}`,
      datas: [
        {
          sheetData: dataList,
          sheetName: `Alıcılar Raporu ${moment().format("DD-MM-YYYY")}`,
          sheetFilter: [
            "displayName",
            "userType",
            "email",
            "gender",
            "phone",
            "sector",
            "sales",
            "totalSalesAmount",
            "city",
            "area",
            "expoToken",
            "birthDate",
          ],
          sheetHeader: [
            "Müşteri Adı",
            "Kullanıcı tipi",
            "e-posta",
            "Cinsiyet",
            "telefon",
            "sektör",
            "satış",
            "toplam Satış Tutarı",
            "şehir",
            "ilçe",
            "Bildirim ID",
            "Tarihi",
          ],
        },
      ],
    };
    const toExcel = new ExportJsonExcel(option);
    toExcel.saveExcel();
  };

  const onCopyIds = () => {
    const token = buyerData.map(
      (b) => b.expoToken !== undefined && b.expoToken
    );
    const tokenToCopy = [];

    token.forEach((t) => {
      if (t) {
        tokenToCopy.push(t);
      }
    });
    const tokenString = tokenToCopy.join(" ");

    navigator.clipboard.writeText(tokenString);
    dispatch(setAlert("", "Bildirimi Id kopyalandı", "success"));
  };

  const columns = [
    {
      title: "İsim",
      dataIndex: "userInfo",
      key: "userInfo",
      width: 400,
    },
    {
      title: "Sector",
      dataIndex: "sector",
      key: "sector",
    },

    {
      title: "İl",
      dataIndex: "city",
      key: "city",
    },
    {
      title: "İlçe",
      dataIndex: "area",
      key: "area",
    },
    {
      title: "Telefon",
      dataIndex: "phone",
      key: "phone",
    },
    {
      title: "Tarihi",
      dataIndex: "date",
      key: "date",
    },

    {
      title: "Bakiy",
      dataIndex: "money",
      key: "money",
    },
    {
      title: "Bonus",
      dataIndex: "bonus",
      key: "bonus",
    },
    {
      title: "İşlemler",
      key: "operation",
      dataIndex: "operation",
    },
  ];

  const onLink = async (id) => {
    await dispatch(getSingleBuyer(id));
    await dispatch(getBuyerInfo(id));
    history.push(`/buyer-details`);
  };

  const onCopy = () => {
    dispatch(setAlert("", "Bildirimi ID kopyalandı", "success"));
  };

  useEffect(() => {
    const dataList = [];
    buyerData.forEach((b) => {
      dataList.push({
        key: b._id,
        userInfo: (
          <div className="userInfo-container">
            <UserImage photoURL={b.photoURL} />
            <div className="userInfo-text">
              <h1>{b.displayName}</h1>
              <h2>{b.email}</h2>
            </div>
          </div>
        ),

        city: <>{b.city}</>,
        area: <>{b.area}</>,
        phone: <>{b.phone}</>,
        sector: <>{b.sector}</>,

        money: <>{b.money.toFixed(2)}₺</>,
        bonus: <>{b.bonus.toFixed(2)}₺</>,
        totalBonusEarned: <>{b.totalBonusEarned.toFixed(2)}₺</>,
        date: <>{moment(b.createdAt).format("Do MMMM YYYY")}</>,
        operation: (
          <div className="buyer-operation">
            <Button
              type="primary"
              onClick={onLink.bind(this, b._id)}
              size="small"
            >
              <span> Fazla Bilgi</span>
            </Button>
            <CopyToClipboard text={b.expoToken} disabled={!b.expoToken}>
              <Button
                type="danger"
                onClick={onCopy}
                size="small"
                disabled={!b.expoToken}
              >
                <span> Bildirimi ID</span>
              </Button>
            </CopyToClipboard>
          </div>
        ),
      });
    });
    setTabelData(dataList);
    // eslint-disable-next-line
  }, [buyer]);

  return (
    <>
      {loading ? (
        <Spin indicator={antIcon} className="spinner" />
      ) : (
        <>
          <div className="buyer-title">
            <h1>{header}</h1>
          </div>
          <div className="search-option-buyer">
            <div className="log-buttons-container">
              <Button onClick={onButtonClick} icon={<CloudDownloadOutlined />}>
                Raporu İndir
              </Button>
              <Button onClick={onCopyIds} type="danger">
                Bildirimi IDs kopyala
              </Button>
            </div>
          </div>

          <>
            {buyerData.length !== 0 ? (
              <>
                <Table
                  columns={columns}
                  dataSource={tabelData}
                  pagination={false}
                  className="tableContainer"
                  loading={listLoading}
                  scroll={{ x: 1400, y: "80%" }}
                />
              </>
            ) : (
              <h1>Bu e-postayla hiçbir Alıcı bulunamadı </h1>
            )}
          </>
        </>
      )}
    </>
  );
};

export default Buyer;
