import React from 'react';

// Styles
import './CollapseMenu.scss';

// Icons
import FeatherIcon from 'feather-icons-react';

const CollapseMenu = ({
  icon,
  title,
  children,
  kind,
  collapseHandler,
  user,
}) => {
  return (
    <div onClick={collapseHandler.bind(this, { [kind]: !user })}>
      <button className='CollapseMenu'>
        <FeatherIcon icon={icon} width='1.5rem' />
        <span>{title}</span>
        <FeatherIcon
          icon='chevron-right'
          width='2rem'
          className={` chevronIcon ${user ? 'chevron-right' : null}`}
        />
      </button>
      {user && <div className='CollapseItems'>{children}</div>}
    </div>
  );
};

export default CollapseMenu;
