import React, { useEffect, useCallback } from "react";

// Container
import PagerContainer from "../../layouts/PageContainer/PageContainer";

// Antd - Componets

import { Spin } from "antd";

// Icons
import { LoadingOutlined } from "@ant-design/icons";

// Hooks

import { useDispatch, useSelector } from "react-redux";

// @ Component
import SingleWithdrawC from "../../components/SingleWithdrawC/SingleWithdrawC";

// @Redxu
import { fetchCompletedWithdraws } from "../../redux/actions/auth";

const antIcon = <LoadingOutlined style={{ fontSize: 128 }} spin />;

const CompletedWithdraws = () => {
  const dispatch = useDispatch();

  const loading = useSelector((state) => state.auth.loading);
  const withdraws = useSelector((state) => state.auth.withdraws);

  const getData = useCallback(async () => {
    dispatch(fetchCompletedWithdraws());
  }, [dispatch]);

  useEffect(() => {
    getData();
  }, [getData]);

  return (
    <PagerContainer>
      {loading ? (
        <Spin indicator={antIcon} className="spinner" />
      ) : (
        <>
          <div className="seller-title">
            <h1>Para Çekme Sonuçları</h1>
          </div>

          <div className="seller-container">
            {withdraws.length !== 0 ? (
              <>
                {withdraws.map((b) => (
                  <SingleWithdrawC key={b._id} {...b} />
                ))}
              </>
            ) : (
              <h1>Veri bulunamadı.</h1>
            )}
          </div>
        </>
      )}
    </PagerContainer>
  );
};

export default CompletedWithdraws;
