import React, { useEffect, useCallback, useState } from "react";
import { useHistory } from "react-router-dom";
import axios from "axios";

// Container
import PagerContainer from "../../layouts/PageContainer/PageContainer";

// utils
import { fallback } from "../../utils/fallback";

// @styles
import "./AddNewDiscount.scss";

// Antd - Componets
import {
  Button,
  Form,
  Input,
  Select,
  Upload,
  Progress,
  Spin,
  Image,
} from "antd";

// Icons
import { LoadingOutlined, InboxOutlined } from "@ant-design/icons";
import FeatherIconsReact from "feather-icons-react";

// Hooks

import { useDispatch, useSelector } from "react-redux";

// Redux
import {
  FETCH_DROPDWON_INFO_START,
  FETCH_DROPDWON_INFO_SUCCESS,
} from "../../redux/types";
import { setAlert } from "../../redux/actions/alerts";
import { createDiscount, createLogs } from "../../redux/actions/auth";

// Utils
import { v4 as uuidv4 } from "uuid";
import { storge } from "../../config/firebase";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";

const antIcon = <LoadingOutlined style={{ fontSize: 128 }} spin />;

const { TextArea } = Input;
const { Option } = Select;
const { Dragger } = Upload;

const AddNewDiscount = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const loading = useSelector((state) => state.auth.loading);

  const [areaArr, setAreaArr] = useState({});
  const [cityLabel, setCityLabel] = useState();
  const [categoriesArr, setCategoriesArr] = useState([]);
  const [cityArr, setCityArr] = useState([]);
  const [showProgress, setShowProgress] = useState(false);
  const [progressNumber, setProgressNumber] = useState(0);
  const [image, setImage] = useState();
  const [background, setBackground] = useState();
  const adminName = useSelector((state) => state.auth.user.displayName);

  const daysArr = [
    {
      label: "Pazartesi",
      value: "1",
    },
    {
      label: "Salı",
      value: "2",
    },
    {
      label: "Çarşamba",
      value: "3",
    },
    {
      label: "Perşembe",
      value: "4",
    },
    {
      label: "Cuma",
      value: "5",
    },
    {
      label: "Cumartesi",
      value: "6",
    },
    {
      label: "Pazar",
      value: "0",
    },
  ];

  const props = {
    beforeUpload(file, fileList) {
      setShowProgress(true);

      const metadata = {
        contentType: "image/jpeg",
      };
      const imageRef = ref(storge, `images/localdiscount/main/${uuidv4()}`);
      const uploadTask = uploadBytesResumable(imageRef, file, metadata);
      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          setProgressNumber(progress.toFixed(2));
        },
        (error) => {
          // Handle unsuccessful uploads
          console.log(error);
        },
        () => {
          getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
            setImage(downloadURL);

            setShowProgress(false);
            dispatch({
              type: FETCH_DROPDWON_INFO_SUCCESS,
            });
          });
        }
      );

      return Upload.LIST_IGNORE;
    },
  };

  const propsBackground = {
    beforeUpload(file, fileList) {
      setShowProgress(true);

      const metadata = {
        contentType: "image/jpeg",
      };
      const imageRef = ref(
        storge,
        `images/localdiscount/backgrounds/${uuidv4()}`
      );
      const uploadTask = uploadBytesResumable(imageRef, file, metadata);

      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          setProgressNumber(progress.toFixed(2));
        },
        (error) => {
          // Handle unsuccessful uploads
          console.log(error);
        },
        () => {
          getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
            setBackground(downloadURL);
            setShowProgress(false);
          });
        }
      );
      return Upload.LIST_IGNORE;
    },
  };

  const onLink = () => {
    history.push(`/local-discount`);
  };

  const handleSubmit = (value) => {
    if (+value.netDiscountRate > +value.grossDiscountRate) {
      dispatch(
        setAlert(
          "Başarısız",
          "Net indirim Brüt indirim oranından fazla olamaz",
          "fail"
        )
      );
      return;
    }

    if (!value.phone.startsWith("0")) {
      dispatch(
        setAlert(
          "Başarısız",
          "Telefon numarasının başında 0 olmalıdır!",
          "fail"
        )
      );
      return;
    }

    if (!image) {
      dispatch(setAlert("Başarısız", "Logo Resmi Ekleyiniz", "fail"));
      return;
    }
    if (!background) {
      dispatch(setAlert("Başarısız", "Arkaplan Resmi Ekleyiniz", "fail"));
      return;
    }
    const data = {
      ...value,
      image,
      backgroundImage: background,
      status: "Approved",
    };

    if (value.latitude) {
      data.latitude = value.latitude;
    }

    if (value.longitude) {
      data.longitude = value.longitude;
    }

    dispatch(createDiscount(data));

    let log = {
      ProcessName: "AddLocalDiscount",
      ProcessDescription: `(${adminName}) Admin , ${data.title} isimli kampanya oluşturuldu. `,
      SellerId: data.sellerId,
      UserId: null,
      Request: JSON.stringify(data),
    };
    dispatch(createLogs(log));

    form.resetFields();

    history.push("/local-discount");
  };

  const handleCity = (c) => {
    setCityLabel(c);
  };

  const cancelScroll = (e) => {
    e.target.blur();
  };

  const getLabel = useCallback(async () => {
    dispatch({
      type: FETCH_DROPDWON_INFO_START,
    });

    try {
      const areasJson = await axios.get(
        "https://jetd-backend.herokuapp.com/area.json"
      );

      const categoriesRes = await axios.get(
        "https://jetd-backend.herokuapp.com/api/v1/categories"
      );

      const categories = categoriesRes.data.data.map((item) => {
        return {
          value: item.value,
          label: item.value,
        };
      });

      const citiesRes = await axios.get(
        "https://jetd-backend.herokuapp.com/api/v1/city"
      );

      const cities = citiesRes.data.data.map((item) => {
        return {
          value: item.title,
          label: item.title,
        };
      });

      setAreaArr(areasJson.data);
      setCategoriesArr(categories);
      setCityArr(cities);

      dispatch({
        type: FETCH_DROPDWON_INFO_SUCCESS,
      });
    } catch (error) {
      console.log(error);
    }
  }, [dispatch]);

  useEffect(() => {
    getLabel();
  }, [getLabel]);
  return (
    <PagerContainer>
      {loading ? (
        <Spin indicator={antIcon} className="spinner" />
      ) : (
        <>
          <div className="add-discount-container">
            <div className="discount-title">
              <h1>Yeni Kampanya Ekle</h1>
              <div>
                <Button
                  type="primary"
                  className="discount-title-link"
                  onClick={onLink}
                >
                  <FeatherIconsReact icon="plus" width="1.5rem" />
                  <span>Geri Dön</span>
                </Button>
              </div>
            </div>
            <div className="upload-images-container">
              {showProgress ? (
                <div className="progress">
                  <Progress type="circle" percent={progressNumber} />
                </div>
              ) : (
                <div className="upload-main">
                  <div className="uploadsContainer">
                    <div className="uploader">
                      <p className="image-upload-title">Arka Plan Resim </p>
                      <Dragger
                        {...propsBackground}
                        disabled={loading}
                        className="image-uploader"
                      >
                        <p className="ant-upload-drag-icon">
                          <InboxOutlined />
                        </p>
                        <p className="ant-upload-text">Yükle</p>
                      </Dragger>
                    </div>
                    <div className="uploader">
                      <p className="image-upload-title">Logo Resim</p>
                      <Dragger
                        {...props}
                        disabled={loading}
                        className="image-uploader"
                      >
                        <p className="ant-upload-drag-icon">
                          <InboxOutlined />
                        </p>
                        <p className="ant-upload-text">Yükle</p>
                      </Dragger>
                    </div>
                  </div>
                  <div className="images-container">
                    {background && (
                      <div className="background-container">
                        <Image
                          width="100%"
                          src={background}
                          fallback={fallback}
                        />
                      </div>
                    )}
                    {image && (
                      <div className="image-container-preview">
                        <Image width="100%" src={image} fallback={fallback} />
                      </div>
                    )}
                  </div>
                </div>
              )}
            </div>
            <div className="form-container">
              <Form
                layout="vertical"
                onFinish={handleSubmit}
                className="discount-add-form"
                form={form}
                name="addDiscount"
              >
                <Form.Item
                  name="title"
                  label="Kampanya Başlığı"
                  rules={[
                    {
                      message: "Kampanya Başlığı Giriniz.",
                      required: true,
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  name="description"
                  label="Kampanya Açıklama"
                  rules={[{ message: "Açıklama Giriniz.", required: true }]}
                >
                  <TextArea rows={2} placeholder="" />
                </Form.Item>

                <Form.Item
                  name="sellerId"
                  label="Satıcı ID"
                  rules={[
                    {
                      message: "Satıcı ID Giriniz.",
                      required: true,
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  name="address"
                  label="Adres"
                  rules={[
                    {
                      message: "Adres Giriniz.",
                      required: true,
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  name="phone"
                  label="Telefon"
                  rules={[
                    {
                      message: "Telefon Giriniz.",
                      required: true,
                    },
                  ]}
                >
                  <Input
                    type="number"
                    onScroll={cancelScroll}
                    onWheel={cancelScroll}
                  />
                </Form.Item>
                <Form.Item
                  name="category"
                  label="Kategori"
                  rules={[{ message: "Kategori Seçiniz.", required: true }]}
                >
                  <Select style={{ width: "100%" }} placeholder="">
                    {categoriesArr.map((c) => (
                      <Option value={c.value} key={c.value}>
                        {c.label}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
                <Form.Item
                  name="city"
                  label="Şehir"
                  rules={[{ message: "Şehir Seçiniz.", required: true }]}
                >
                  <Select
                    style={{ width: "100%" }}
                    placeholder=""
                    onChange={handleCity}
                  >
                    {cityArr.map((c) => (
                      <Option value={c.value} key={c.value}>
                        {c.label}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
                <Form.Item
                  name="area"
                  label="İlçe"
                  rules={[{ message: "İlçe Seçiniz.", required: true }]}
                >
                  <Select style={{ width: "100%" }} placeholder="">
                    {cityLabel &&
                      areaArr[cityLabel].map((c) => (
                        <Option value={c.value} key={c.value}>
                          {c.label}
                        </Option>
                      ))}
                  </Select>
                </Form.Item>
                <Form.Item name="disabelDays" label="Kapalı Günler">
                  <Select
                    style={{ width: "100%" }}
                    placeholder=""
                    mode="multiple"
                  >
                    {daysArr.map((c) => (
                      <Option value={c.value} key={c.value}>
                        {c.label}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
                <Form.Item
                  name="grossDiscountRate"
                  label="Brüt İndirim"
                  rules={[
                    {
                      message: "Brüt İndirim Oranı Giriniz.",
                      required: true,
                    },
                  ]}
                >
                  <Input
                    type="number"
                    onScroll={cancelScroll}
                    onWheel={cancelScroll}
                  />
                </Form.Item>
                <Form.Item
                  name="netDiscountRate"
                  label="Net İndirim"
                  rules={[
                    {
                      message: "Net İndirim Oranı Giriniz.",
                      required: true,
                    },
                  ]}
                >
                  <Input
                    type="number"
                    onScroll={cancelScroll}
                    onWheel={cancelScroll}
                  />
                </Form.Item>

                <div className="cord-container">
                  <Form.Item
                    name="latitude"
                    label="Enlem"
                    className="cord-input"
                  >
                    <Input />
                  </Form.Item>

                  <Form.Item
                    name="longitude"
                    label="Boylam"
                    className="cord-input"
                  >
                    <Input />
                  </Form.Item>
                </div>
                <Form.Item>
                  <Button
                    className="btn-add-q"
                    htmlType="submit"
                    type="primary"
                    size="large"
                    disabled={loading}
                  >
                    {loading ? "Yükleniyor..." : "Kaydet"}
                  </Button>
                </Form.Item>
              </Form>
            </div>
          </div>
        </>
      )}
    </PagerContainer>
  );
};

export default AddNewDiscount;

/// title , description , sellerId , city , area ,netDiscountRate,grossDiscountRate,address,image,backgroundImage,category, phone
