import React, { useCallback, useEffect } from "react";
import { useHistory } from "react-router-dom";

// @Components
import PageContainer from "../../layouts/PageContainer/PageContainer";
import SingleSlider from "../../components/SingleSilder/SingleSilder";
// Icons
import { LoadingOutlined } from "@ant-design/icons";
import FeatherIconsReact from "feather-icons-react";

// @Antd - Componets
import { Spin, Button } from "antd";

// @~Redux - Hooks
import { useSelector, useDispatch } from "react-redux";
import { fetchCities, fetchSliders } from "../../redux/actions/auth";

const antIcon = <LoadingOutlined style={{ fontSize: 128 }} spin />;

const Categories = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const loading = useSelector((state) => state.auth.loading);
  const sliders = useSelector((state) => state.auth.sliders);

  const onLink = async () => {
    await dispatch(fetchCities());
    history.push("/add-slider");
  };

  const fetchSlidersOnFirstLoad = useCallback(async () => {
    await dispatch(fetchSliders());
  }, [dispatch]);

  useEffect(() => {
    fetchSlidersOnFirstLoad();
  }, [fetchSlidersOnFirstLoad]);
  return (
    <PageContainer>
      {loading ? (
        <Spin indicator={antIcon} className="spinner" />
      ) : (
        <>
          <div className="cities-container__header">
            <h1>Slider</h1>
            <div>
              <Button
                type="primary"
                className="discount-title-link"
                onClick={onLink}
              >
                <FeatherIconsReact icon="plus" width="1.5rem" />
                <span>Yeni slider Ekle</span>
              </Button>
            </div>
          </div>

          <div className="cities-container__body">
            {sliders.length !== 0 ? (
              <>
                {sliders.map((c) => (
                  <SingleSlider key={c._id} {...c} />
                ))}
              </>
            ) : (
              <h1>Bu e-postayla hiçbir Alıcı bulunamadı </h1>
            )}
          </div>
        </>
      )}
    </PageContainer>
  );
};

export default Categories;
