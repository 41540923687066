import React, { useEffect, useState } from "react";

// Antd - Componets

import { Table, Tag } from "antd";

// @utils
import moment from "moment";
import "moment/locale/tr";
moment.locale("tr");

const AccountStatementTabs = ({ data }) => {
  const [tableData, setTabelData] = useState([]);
  const columns = [
    {
      title: "Hesap dokumu Adı",
      dataIndex: "processName",
      key: "processName",
    },
    {
      title: "Hesap dokumu Açıklama",
      dataIndex: "processDescription",
      key: "processDescription",
    },
    {
      title: "Miktar",
      dataIndex: "amount",
      key: "amount",
    },
    {
      title: "Hesap dokumu Tarihi",
      dataIndex: "date",
      key: "date",
    },
  ];

  useEffect(() => {
    const dataList = [];
    data.forEach((b) => {
      dataList.push({
        key: b._id,
        processName: (
          <Tag color={getColor(b.processName)}>{getName(b.processName)}</Tag>
        ),
        processDescription: <>{b.processDescription}</>,
        amount: (
          <Tag color={getColor(b.processName)}>
            {" "}
            {`${getSign(b.processName)} ${b.amount.toFixed(2)}`}₺
          </Tag>
        ),
        date: <>{moment(b.createdDate).format("Do MMMM YYYY - HH:mm")}</>,
      });
    });
    setTabelData(dataList);
    // eslint-disable-next-line
  }, [data]);

  return (
    <Table
      columns={columns}
      dataSource={tableData}
      pagination={false}
      className="tableContainer"
      scroll={{ x: 1200, y: "80%" }}
    />
  );
};

function getColor(data) {
  switch (data) {
    case "BonusRateEarned":
    case "AddMoneyRequestIban":
    case "AddMoneyRequestCreditCard":
      return "success";
    case "SaleMoney":
    case "SaleBonus":
    case "SaleCreditCard":
    case "SaleMix":
    case "WithDrawalRequest":
      return "error";
    default:
      return "warning";
  }
}

function getSign(data) {
  switch (data) {
    case "BonusRateEarned":
    case "AddMoneyRequestIban":
    case "AddMoneyRequestCreditCard":
      return "+";
    case "SaleMoney":
    case "SaleBonus":
    case "SaleCreditCard":
    case "SaleMix":
    case "WithDrawalRequest":
      return "-";
    default:
      return "+";
  }
}

function getName(data) {
  switch (data) {
    case "BonusRateEarned":
      return "Bonus Kazanımı";
    case "SaleMoney":
      return "Bakiye ile Ödeme";
    case "SaleBonus":
      return "Bonus ile Ödeme";
    case "SaleCreditCard":
      return "Kredi Kartı ile Ödeme";
    case "SaleMix":
      return "Karışık Ödeme";
    case "AddMoneyRequestIban":
      return "IBAN ile Para Yatırma";
    case "AddMoneyRequestCreditCard":
      return "Kredi Kartı ile Para Yatırma";
    case "WithDrawalRequest":
      return "Para Çekme İşlemi";

    default:
      return "";
  }
}

export default AccountStatementTabs;
