import React from "react";

// Container
import PagerContainer from "../../layouts/PageContainer/PageContainer";

// Antd - Componets
import { Button, Form, Input, Spin } from "antd";

// Icons
import { LoadingOutlined } from "@ant-design/icons";

// Hooks

import { useDispatch, useSelector } from "react-redux";

// Redux
import { createUserNotfication, createLogs } from "../../redux/actions/auth";

const antIcon = <LoadingOutlined style={{ fontSize: 128 }} spin />;

const { TextArea } = Input;

const Notfications = () => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const loading = useSelector((state) => state.auth.loading);
  const adminName = useSelector((state) => state.auth.user.displayName);

  const handleSubmit = (value) => {
    const usersTokens = value.userToken.split(" ");

    const data = {
      title: value.title,
      body: value.body,
      usersTokens,
    };

    console.log(data);

    dispatch(createUserNotfication(data));

    let log = {
      ProcessName: "Send User Notification",
      ProcessDescription: `(${adminName}) ${data.title} bildirim gönderildi. `,
      SellerId: null,
      UserId: null,
      Request: JSON.stringify(data),
    };
    dispatch(createLogs(log));

    form.resetFields();
  };

  return (
    <PagerContainer>
      {loading ? (
        <Spin indicator={antIcon} className="spinner" />
      ) : (
        <>
          <div className="add-discount-container">
            <div className="discount-title">
              <h1>Yeni Bildirim Gönder</h1>
            </div>

            <div className="form-container">
              <Form
                layout="vertical"
                onFinish={handleSubmit}
                className="discount-add-form"
                form={form}
                name="addDiscount"
              >
                <Form.Item
                  name="title"
                  label="Bildirim Başlığı"
                  rules={[
                    {
                      message: "Bilirim Başlığı Giriniz.",
                      required: true,
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  name="body"
                  label="Bildirim Açıklama"
                  rules={[{ message: "Açıklama Giriniz.", required: true }]}
                >
                  <TextArea rows={2} placeholder="" />
                </Form.Item>

                <Form.Item
                  name="userToken"
                  label="Bildirim Gönderilecek Kullanıcılar"
                  rules={[
                    {
                      message: "Gönderilecek Kullanıcılar Giriniz.",
                      required: true,
                    },
                  ]}
                >
                  <TextArea rows={2} placeholder="" />
                </Form.Item>

                <Form.Item>
                  <Button
                    className="btn-add-q"
                    htmlType="submit"
                    type="primary"
                    size="large"
                    disabled={loading}
                  >
                    {loading ? "Yükleniyor..." : "Gönder"}
                  </Button>
                </Form.Item>
              </Form>
            </div>
          </div>
        </>
      )}
    </PagerContainer>
  );
};

export default Notfications;
