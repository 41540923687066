import React, { useEffect, useState } from "react";

// Antd - Componets
import { Button, Table, Tag } from "antd";

// Icons
import { CloudDownloadOutlined } from "@ant-design/icons";

// Hook
import { useSelector } from "react-redux";

import UserImage from "../../layouts/UserImage/UserImage";

// @utils

import ExportJsonExcel from "js-export-excel";
import moment from "moment";
import "moment/locale/tr";

moment.locale("tr");

const Sales = ({ salesData }) => {
  const sale = useSelector((state) => state.auth.sale);
  const listLoading = useSelector((state) => state.auth.listLoading);
  const buttonLoading = useSelector((state) => state.auth.buttonLoading);
  const [tabelData, setTabelData] = useState([]);

  const columns = [
    {
      title: "Müşteri Adı",
      dataIndex: "customer",
      key: "customer",
      width: 300,
    },
    {
      title: "Kampanya Adı",
      dataIndex: "title",
      key: "title",
    },
    {
      title: "Satcı Adı",
      dataIndex: "seller",
      key: "seller",
    },

    {
      title: "Tarihi",
      dataIndex: "date",
      key: "date",
    },

    {
      title: "Normal Tutar",
      dataIndex: "salesAmountWithOutDiscount",
      key: "salesAmountWithOutDiscount",
    },
    {
      title: "İndirimli Tutar",
      dataIndex: "salesAmountWithDiscount",
      key: "salesAmountWithDiscount",
    },
    {
      title: "Jetd Komisyonu",
      dataIndex: "jetdProfitAmount",
      key: "jetdProfitAmount",
    },
    {
      title: "Net",
      dataIndex: "netDiscountRate",
      key: "netDiscountRate",
    },

    {
      title: "Ödeme Yöntemi",
      dataIndex: "paymentType",
      key: "paymentType",
    },
    {
      title: "Kazanılan Bonus",
      dataIndex: "settingDetailRateEarned",
      key: "settingDetailRateEarned",
    },
  ];

  useEffect(() => {
    const dataList = [];
    salesData.forEach((b) => {
      dataList.push({
        key: b._id,
        customer: (
          <div className="sale-customer">
            <UserImage
              photoURL={
                b.LocalDiscount ? b.LocalDiscount?.image : b.user.photoURL
              }
            />
            <div>{b.user?.displayName}</div>
          </div>
        ),
        //   image: <UserImage photoURL={b.LocalDiscount?.image} />,

        title: (
          <>
            {b.LocalDiscount?.title > 30
              ? b.LocalDiscount?.title.substring(0, 20) + "..."
              : b.LocalDiscount?.title}
          </>
        ),
        seller: <>{b.seller?.companyName}</>,

        date: <>{moment(b.payedAt).format("Do MMMM YYYY HH:mm")}</>,
        salesAmountWithOutDiscount: (
          <>{b.salesAmountWithOutDiscount.toFixed(2)}₺</>
        ),
        salesAmountWithDiscount: <>{b.salesAmountWithDiscount.toFixed(2)}₺</>,
        jetdProfitAmount: <>{b.jetdProfitAmount.toFixed(2)}₺</>,
        netDiscountRate: <>{b.netDiscountRate}%</>,
        grossDiscountRate: <>{b.grossDiscountRate}%</>,
        paymentType: (
          <Tag
            className="mr-0"
            color={b.paymentType === "Kredi Kartı" ? "blue" : "geekblue"}
          >
            {b.paymentType}
          </Tag>
        ),

        settingDetailRateEarned: (
          <>
            {b.settingDetailRateEarned
              ? b.settingDetailRateEarned.toFixed(2)
              : 0}
            ₺
          </>
        ),
      });
    });
    setTabelData(dataList);
    // eslint-disable-next-line
  }, [sale]);

  const onButtonSeClick = async () => {
    const dataList = [];
    salesData.forEach((b) => {
      dataList.push({
        displayName: b.user.displayName,
        discountName: b.LocalDiscount.title,
        net: b.netDiscountRate,
        paymentType: b.paymentType,
        plaka: b.plates ? b.plates.plate : "",
        salesAmountWithOutDiscount: b.salesAmountWithOutDiscount.toFixed(2),
        salesAmountWithDiscount: b.salesAmountWithDiscount.toFixed(2),
        jetdProfitAmount: b.jetdProfitAmount,
        date: moment(b.payedAt).format("Do MMMM YYYY HH:mm"),
      });
    });
    const option = {
      fileName: `Satış Raporu ${moment().format("DD-MM-YYYY HH:mm")}`,
      datas: [
        {
          sheetData: dataList,
          sheetName: `Satış Raporu ${moment().format("DD-MM-YYYY")}`,
          sheetFilter: [
            "displayName",
            "discountName",
            "net",
            "paymentType",
            "plaka",
            "salesAmountWithOutDiscount",
            "salesAmountWithDiscount",
            "jetdProfitAmount",
            "date",
          ],
          sheetHeader: [
            "Müşteri Adı",
            "Kampanya Adı",
            "Net İndirim",
            "Ödeme Yöntemi",
            "Plaka",
            "Normal Tutar",
            "Ödenen Tutar",
            "Jetd Komisyonu",
            "Tarih",
          ],
        },
      ],
    };
    const toExcel = new ExportJsonExcel(option);
    toExcel.saveExcel();
  };

  return (
    <>
      <div className="discount-title">
        <h1>the biggest amount of transaction (for this month) </h1>
      </div>

      <>
        <>
          <Button
            onClick={onButtonSeClick}
            icon={<CloudDownloadOutlined />}
            loading={buttonLoading}
          >
            Raporu İndir
          </Button>
        </>

        {salesData.length !== 0 ? (
          <>
            <Table
              columns={columns}
              dataSource={tabelData}
              pagination={false}
              className="tableContainer"
              loading={listLoading}
              scroll={{ x: 1200 }}
              bordered={false}
            />
          </>
        ) : (
          <h1>Satış bulunamadı</h1>
        )}
      </>
    </>
  );
};

export default Sales;
