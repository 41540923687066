import React, { useEffect, useState } from "react";

// Antd - Componets

import { Table, Tag } from "antd";

// @utils
import moment from "moment";
import "moment/locale/tr";
moment.locale("tr");

const MoneyTabs = ({ data }) => {
  const [tableData, setTabelData] = useState([]);
  const columns = [
    {
      title: "Tarih",
      dataIndex: "date",
      key: "date",
    },
    {
      title: "Miktar",
      dataIndex: "amount",
      key: "amount",
    },

    {
      title: "Onay Tarihi",
      dataIndex: "ApprovedDate",
      key: "ApprovedDate",
    },
    {
      title: "Durum",
      dataIndex: "status",
      key: "status",
    },
    {
      title: "türü",
      dataIndex: "type",
      key: "type",
    },
    {
      title: "ödeme türü",
      dataIndex: "paymentType",
      key: "paymentType",
    },
  ];

  useEffect(() => {
    const dataList = [];
    data.forEach((b) => {
      dataList.push({
        key: b._id,
        date: <>{moment(b.createdAt).format("Do MMMM YYYY - HH:mm")}</>,

        ApprovedDate: (
          <>
            {b.status === "Approved"
              ? moment(b.ApprovedDate).format("Do MMMM YYYY - HH:mm")
              : ""}
          </>
        ),
        status: <Tag color={getColor(b.status)}>{getText(b.status)}</Tag>,
        amount: <>{b.amount.toFixed(2)}₺</>,
        type: (
          <Tag color={b.isBonus ? "blue" : "green"}>
            {b.isBonus ? "Bonus" : "Bakiye"}
          </Tag>
        ),
        paymentType: (
          <Tag color={b.type === "iban" ? "purple" : "gold"}>
            {b.type === "iban" ? "iban" : "kredi kartı"}
          </Tag>
        ),
      });
    });
    setTabelData(dataList);
    // eslint-disable-next-line
  }, [data]);

  return (
    <Table
      columns={columns}
      dataSource={tableData}
      pagination={false}
      className="tableContainer"
      scroll={{ x: 1200, y: "80%" }}
    />
  );
};

function getColor(data) {
  switch (data) {
    case "Approved":
      return "success";
    case "Rejected":
      return "error";
    default:
      return "warning";
  }
}

function getText(data) {
  switch (data) {
    case "Approved":
      return "Onaylandı";
    case "Rejected":
      return "Reddedildi";
    default:
      return "Onay Bekliyor";
  }
}

export default MoneyTabs;
