import React, { useEffect, useCallback } from "react";

// Container
import PagerContainer from "../../layouts/PageContainer/PageContainer";

// Antd - Componets

import { Spin } from "antd";

// Icons
import { LoadingOutlined } from "@ant-design/icons";

// Hooks

import { useDispatch, useSelector } from "react-redux";

// @ Component
import SingleAddMonerReq from "../../components/SingleAddMonerReq/SingleAddMonerReq";

// @Redxu
import { fetchAddMoneyReq } from "../../redux/actions/auth";

const antIcon = <LoadingOutlined style={{ fontSize: 128 }} spin />;

const AddMoneyReq = () => {
  const dispatch = useDispatch();

  const loading = useSelector((state) => state.auth.loading);
  const AddMoneyRequest = useSelector((state) => state.auth.AddMoneyRequest);

  const getData = useCallback(async () => {
    dispatch(fetchAddMoneyReq());
  }, [dispatch]);

  useEffect(() => {
    getData();
  }, [getData]);

  return (
    <PagerContainer>
      {loading ? (
        <Spin indicator={antIcon} className="spinner" />
      ) : (
        <>
          <div className="seller-title">
            <h1>Bakiye / Bonus Talepleri</h1>
          </div>

          <div className="seller-container">
            {AddMoneyRequest.length !== 0 ? (
              <>
                {AddMoneyRequest.map((b) => (
                  <SingleAddMonerReq key={b._id} {...b} />
                ))}
              </>
            ) : (
              <h1>Veri bulunamadı </h1>
            )}
          </div>
        </>
      )}
    </PagerContainer>
  );
};

export default AddMoneyReq;
