import React from "react";

// @styles
import "./StatusLayouts.scss";

const StatusLayouts = ({ status }) => {
  return (
    <div
      className={`tag-container ${status === "Approved" ? "tagGreen" : null}`}
    >
      {status === "Pending" ? "Onay Bekliyor" : "Onaylandı"}
    </div>
  );
};

export default StatusLayouts;
