import { combineReducers } from "redux";

// Reducers
import navbarPanel from "./reducers/navbarPanel";
import auth from "./reducers/auth";
import alerts from "./reducers/alerts";

export default combineReducers({
  navbarPanel,
  auth,
  alerts,
});
