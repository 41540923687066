import React from "react";

// Styles
import "./LoginForm.scss";

// Antd - Components
import { Form, Input, Button } from "antd";

// Redux
import { useSelector, useDispatch } from "react-redux";

// Redux - actions
import { login } from "../../redux/actions/auth";

const LoginForm = ({ styles }) => {
  const [form] = Form.useForm();

  const { loading } = useSelector((state) => state.auth);

  const dispatch = useDispatch();

  const handleSubmit = (value) => {
    dispatch(login(value));
    // console.log("Hellow 2");
  };

  return (
    <section className={styles}>
      <div className="form-center">
        <Form
          name="login"
          form={form}
          layout="vertical"
          onFinish={handleSubmit}
        >
          <Form.Item
            rules={[
              { message: "Lütfen email adresinizi girin!", required: true },
            ]}
            name="email"
            label="E-Posta Adresi"
            height="10rem"
            className="login-input-group"
          >
            <Input placeholder="E-Posta" size="large" className="login-input" />
          </Form.Item>
          <Form.Item
            rules={[{ message: "Lütfen parolanızı girin!", required: true }]}
            name="password"
            label="Parola"
            className="login-input-group"
          >
            <Input.Password
              placeholder="Parola"
              size="large"
              className="login-input"
            />
          </Form.Item>
          <div className="auth-form-action"></div>

          <Form.Item>
            <Button
              className="btn-login"
              htmlType="submit"
              type="primary"
              size="large"
              disabled={loading}
            >
              {loading ? "Yükleniyor..." : "Giriş Yap"}
            </Button>
          </Form.Item>
        </Form>
      </div>
    </section>
  );
};

export default LoginForm;
