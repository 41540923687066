import { SET_NAV_PANLE, SET_URL_PATH } from '../types';

const initialState = {
  isNavbarPanelOpen: true,
  urlPath: '',
};

function navbarPanel(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case SET_NAV_PANLE:
      return {
        ...state,
        isNavbarPanelOpen: payload,
      };
    case SET_URL_PATH:
      return {
        ...state,
        urlPath: payload,
      };
    default:
      return state;
  }
}

export default navbarPanel;
