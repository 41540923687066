import React from "react";

// @Styles
import "./SingleAddMonerReq.scss";
// @ antd
import { Button, Popconfirm } from "antd";

// @Redux
import { useDispatch, useSelector } from "react-redux";

import {
  acceptAddMoneyReq,
  rejectAddMoneyReq,
  createLogs,
} from "../../redux/actions/auth";

// @utils
import moment from "moment";
import "moment/locale/tr";
moment.locale("tr");

const SingleAddMonerReq = ({ user, isBonus, amount, createdAt, _id }) => {
  const { displayName, walletId, money, bonus } = user;
  const adminName = useSelector((state) => state.auth.user.displayName);

  let data = {
    user: user,
    isBonus: isBonus,
    amount: amount,
    _id: _id,
  };

  const dispatch = useDispatch();

  const handleAccept = async () => {
    await dispatch(acceptAddMoneyReq(_id));

    let log = {
      ProcessName: "CreateAddMoneyRequest",
      ProcessDescription: ` (${adminName}) Admin ,   ${displayName} isimli kullanıcının ${amount.toFixed(
        2
      )} tutarındaki bakiye talebi ONAYLANDI.`,
      SellerId: null,
      UserId: user._id,
      Request: JSON.stringify(data),
    };
    await dispatch(createLogs(log));
  };

  const handleReject = async () => {
    await dispatch(rejectAddMoneyReq(_id));

    let log = {
      ProcessName: "CreateAddMoneyRequest",
      ProcessDescription: `(${adminName}) Admin ,${displayName} isimli kullanıcının ${amount.toFixed(
        2
      )} tutarındaki bakiye talebi REDDEDİLDİ.  `,
      SellerId: null,
      UserId: user._id,
      Request: JSON.stringify(data),
    };
    await dispatch(createLogs(log));
  };

  return (
    <div className="withdraw-main">
      <div className="withdraw-main__column">
        <h1>
          Müşteri adı: <span> {displayName}</span>
        </h1>
        <h1>
          Cüzdan :<span> {walletId}</span>
        </h1>
        <h1>
          Bakiye :<span> {money}</span>
        </h1>
        <h1>
          Bonus :<span> {bonus}</span>
        </h1>
      </div>
      <div className="withdraw-main__column">
        <div className={`tag-container ${isBonus ? "tagGreen" : null}`}>
          {isBonus ? "Bonus" : "Bakiye"}
        </div>
      </div>
      <div className="withdraw-main__column">
        <h1>
          Tarih: <span>{moment(createdAt).format("Do MMMM YYYY - HH:mm")}</span>
        </h1>
        <h1>
          Miktar:
          <span
            className={`important-text ${
              +amount > 2500 ? "more-than-warning" : null
            } `}
          >
            {amount} ₺
          </span>
        </h1>
      </div>
      <div className="withdraw-main__column">
        <Popconfirm
          okText="Evet"
          cancelText="Vazgeç"
          title="Onaylıyor musunuz?"
          onConfirm={handleAccept}
        >
          <Button
            type="primary"
            size="large"
            // danger={true}
            style={{ margintop: "10px" }}
          >
            <span>Onayla</span>
          </Button>
        </Popconfirm>
        <Popconfirm
          okText="Evet"
          cancelText="Vazgeç"
          title="Emin Misin"
          onConfirm={handleReject}
        >
          <Button
            type="primary"
            size="large"
            danger={true}
            style={{ margintop: "10px" }}
          >
            <span>Reddet</span>
          </Button>
        </Popconfirm>
      </div>
    </div>
  );
};

export default SingleAddMonerReq;
