import React from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

// Container
import PagerContainer from "../../layouts/PageContainer/PageContainer";

// Antd - Componets
import { Button, Form, Input, Spin } from "antd";

// Icons
import { LoadingOutlined } from "@ant-design/icons";
import FeatherIconsReact from "feather-icons-react";

// @redux
import { updateSeller, createLogs } from "../../redux/actions/auth";

const antIcon = <LoadingOutlined style={{ fontSize: 128 }} spin />;

const EditSellerMoney = () => {
  const history = useHistory();
  const loading = useSelector((state) => state.auth.loading);
  const singleSeller = useSelector((state) => state.auth.singleSeller);
  const adminName = useSelector((state) => state.auth.user.displayName);
  const dispatch = useDispatch();

  const [form] = Form.useForm();

  const onLink = () => {
    history.push("/seller");
  };

  const handleSubmit = async (value) => {
    await dispatch(updateSeller(value, singleSeller._id));
    let log = {
      ProcessName: "Change seller email",
      ProcessDescription: `(${adminName})Admin , ${singleSeller.companyName} satıcı Cari Tutar  değişti`,
      SellerId: singleSeller._id,
      UserId: null,
      Request: JSON.stringify(value),
    };
    dispatch(createLogs(log));
    history.push("/seller");
  };

  const cancelScroll = (e) => {
    e.target.blur();
  };

  return (
    <PagerContainer>
      {loading ? (
        <Spin indicator={antIcon} className="spinner" />
      ) : (
        <>
          <div className="add-discount-container">
            <div className="discount-title">
              <h1>Cari Tutar Düzenle</h1>
              <div>
                <Button
                  type="primary"
                  className="discount-title-link"
                  onClick={onLink}
                >
                  <FeatherIconsReact icon="plus" width="1.5rem" />
                  <span>Geri Dön</span>
                </Button>
              </div>
            </div>
            <div className="form-container">
              <Form
                layout="vertical"
                onFinish={handleSubmit}
                className="discount-add-form"
                form={form}
                name="editSellerAmount"
              >
                <Form.Item
                  name="currentSalesAmount"
                  label="Cari Tutar: "
                  initialValue={singleSeller.currentSalesAmount}
                  rules={[
                    {
                      message: "Cari Tutar Giriniz.",
                      required: true,
                    },
                  ]}
                >
                  <Input
                    type="number"
                    onScroll={cancelScroll}
                    onWheel={cancelScroll}
                  />
                </Form.Item>

                <Form.Item>
                  <Button
                    className="btn-add-q"
                    htmlType="submit"
                    type="primary"
                    size="large"
                    disabled={loading}
                  >
                    {loading ? "Yükleniyor..." : "Kaydet"}
                  </Button>
                </Form.Item>
              </Form>
            </div>
          </div>
        </>
      )}
    </PagerContainer>
  );
};

export default EditSellerMoney;
