import React from "react";

// @Styles
import "./SingleWithdraw.scss";

// @ antd
import { Button, Popconfirm } from "antd";

// @Redux
import { useDispatch, useSelector } from "react-redux";

import {
  acceptWirhdraw,
  rejectWirhdraw,
  createLogs,
} from "../../redux/actions/auth";

// @utils
import moment from "moment";
import "moment/locale/tr";
moment.locale("tr");

const SingleWithdraw = ({
  BankAccounts,
  BuyerID,
  SellerID,
  BrokerID,
  createdAt,
  RequestAmount,
  _id,
}) => {
  const { CustomerName, IBAN, BankName, AccountName } = BankAccounts;
  const adminName = useSelector((state) => state.auth.user.displayName);
  const dispatch = useDispatch();

  let data = {
    CustomerName: CustomerName,
    IBAN: IBAN,
    RequestAmount: RequestAmount,
    BuyerID: BuyerID,
    SellerID: SellerID,
    BrokerID: BrokerID,
    BankName: BankName,
    _id: _id,
  };

  const handleAccept = async () => {
    await dispatch(acceptWirhdraw(_id));

    let log = {
      ProcessName: "CreateWithdrawRequest",
      ProcessDescription: ` (${adminName}) Admin , ${CustomerName} isimli müşterinin ${RequestAmount.toFixed(
        2
      )} tutarındaki para çekme talebi ONAYLANDI.`,
      SellerId: SellerID,
      UserId: BuyerID,
      BrokerId: BrokerID,
      Request: JSON.stringify(data),
    };
    await dispatch(createLogs(log));
  };

  const handleReject = async () => {
    await dispatch(rejectWirhdraw(_id));

    let log = {
      ProcessName: "CreateWithdrawRequest",
      ProcessDescription: ` (${adminName}) Admin , ${CustomerName} isimli müşterinin ${RequestAmount.toFixed(
        2
      )} tutarındaki para çekme talebi REDDEDİLDİ.`,
      SellerId: SellerID,
      UserId: BuyerID,
      BrokerId: BrokerID,
      Request: JSON.stringify(data),
    };
    await dispatch(createLogs(log));
  };

  return (
    <div className="withdraw-main">
      <div className="withdraw-main__column">
        <h1>
          Müşteri adı: <span> {CustomerName}</span>
        </h1>
        <h1>
          IBAN:<span> {IBAN}</span>
        </h1>

        <h1>
          Banka adı: <span>{BankName}</span>
        </h1>
      </div>
      <div className="withdraw-main__column">
        <div
          className={`tag-container 
        ${BuyerID !== "" ? "tagGreen" : null}`}
        >
          {BuyerID ? "Alıcı" : SellerID ? "Satıcı" : BrokerID ? "Broker" : ""}
        </div>
        <h1>
          <h1>
            Hesap adı: <span>{AccountName}</span>
          </h1>
        </h1>
      </div>
      <div className="withdraw-main__column">
        <h1>
          Tarih: <span>{moment(createdAt).format("Do MMMM YYYY - HH:mm")}</span>
        </h1>
        <h1>
          Miktar:{" "}
          <span className="important-text">{RequestAmount.toFixed(2)} ₺</span>
        </h1>
      </div>
      <div className="withdraw-main__column">
        <Popconfirm
          okText="Evet"
          cancelText="Vazgeç"
          title="Onaylıyor musunuz?"
          onConfirm={handleAccept}
        >
          <Button
            type="primary"
            size="large"
            // danger={true}
            style={{ margintop: "10px" }}
          >
            <span>Onayla</span>
          </Button>
        </Popconfirm>
        <Popconfirm
          okText="Evet"
          cancelText="Vazgeç"
          title="Emin Misin"
          onConfirm={handleReject}
        >
          <Button
            type="primary"
            size="large"
            danger={true}
            style={{ margintop: "10px" }}
          >
            <span>Reddet</span>
          </Button>
        </Popconfirm>
      </div>
    </div>
  );
};

export default SingleWithdraw;
