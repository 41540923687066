import React, { useCallback, useEffect } from "react";

// Antd - components
import { Row, Col, Spin } from "antd";

// Hooks
import { useDispatch, useSelector } from "react-redux";

// Components
import PageContainer from "../../layouts/PageContainer/PageContainer";
import { Doughnut } from "react-chartjs-2";

// Icons
import { LoadingOutlined } from "@ant-design/icons";

// Redux -  Actions
import { fetchSalesStatistics } from "../../redux/actions/auth";

// Utils
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
} from "chart.js";

// // Styles
// import "./UsersStatistics.scss";

const antIcon = <LoadingOutlined style={{ fontSize: 128 }} spin />;

ChartJS.register(
  ArcElement,
  Tooltip,
  Legend,
  BarElement,
  Title,
  CategoryScale,
  LinearScale
);

const SalesStatistics = () => {
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.auth.loading);

  const fetchData = useCallback(() => {
    dispatch(fetchSalesStatistics());
  }, [dispatch]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const {
    totalBonus,
    totalDiscount,
    totalJetdProfit,
    totalMoney,
    totalSalesAmount,
    totalSalesByBonusCount,
    totalSalesByCreditCardCount,
    totalSalesByMixCount,
    totalSalesByMoneyCount,
    totalSalesCount,
    totalUserBonusEarned,
  } = useSelector((state) => state.auth);

  const firstChar = {
    labels: ["Bonus Satış", "Bakiye Satış", "Kredi Kartı Satış", "Mix Satış"],
    datasets: [
      {
        label: "# of Votes",
        data: [
          totalSalesByBonusCount,
          totalSalesByMoneyCount,
          totalSalesByCreditCardCount,
          totalSalesByMixCount,
        ],
        backgroundColor: [
          "rgba(255, 99, 132, 0.2)",
          "rgba(54, 162, 235, 0.2)",
          "rgba(255, 206, 86, 0.2)",
          "rgba(75, 192, 192, 0.2)",
        ],
        borderColor: [
          "rgba(255, 99, 132, 1)",
          "rgba(54, 162, 235, 1)",
          "rgba(255, 206, 86, 1)",
          "rgba(75, 192, 192, 1)",
        ],
        borderWidth: 0.5,
      },
    ],
  };

  return (
    <PageContainer>
      {loading ? (
        <Spin indicator={antIcon} className="spinner" />
      ) : (
        <>
          <h1 className="report-title">Satış İstatistikleri</h1>
          <Row gutter={[0, 10]}>
            <Col xs={24} sm={24} md={12} lg={12} xl={8} xxl={6}>
              <div
                className="user-info-card"
                style={{ width: "30rem", gap: "2rem" }}
              >
                <h1>Toplam İndirimli Satış Tutarı </h1>
                <h2 style={{ color: "#5f63f2", fontSize: "2.4rem" }}>
                  {totalSalesAmount.toFixed(2)}₺
                </h2>
              </div>
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={8} xxl={6}>
              <div
                className="user-info-card"
                style={{ width: "30rem", gap: "2rem" }}
              >
                <h1>Toplam İndirim Tutarı</h1>
                <h2 style={{ color: "#5f63f2", fontSize: "2.4rem" }}>
                  {totalDiscount.toFixed(2)}₺
                </h2>
              </div>
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={8} xxl={6}>
              <div
                className="user-info-card"
                style={{ width: "30rem", gap: "2rem" }}
              >
                <h1>Toplam Jetd Kazancı </h1>
                <h2 style={{ color: "#5f63f2", fontSize: "2.4rem" }}>
                  {totalJetdProfit.toFixed(2)}₺
                </h2>
              </div>
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={8} xxl={6}>
              <div
                className="user-info-card"
                style={{ width: "30rem", gap: "2rem" }}
              >
                <h1>Toplam Bakiye Tutarı </h1>
                <h2 style={{ color: "#5f63f2", fontSize: "2.4rem" }}>
                  {totalMoney.toFixed(2)}₺
                </h2>
              </div>
            </Col>

            <Col xs={24} sm={24} md={12} lg={12} xl={8} xxl={6}>
              <div
                className="user-info-card"
                style={{ width: "30rem", gap: "2rem" }}
              >
                <h1>Toplam Bonus Tutarı </h1>
                <h2 style={{ color: "#5f63f2", fontSize: "2.4rem" }}>
                  {totalBonus.toFixed(2)}₺
                </h2>
              </div>
            </Col>

            <Col xs={24} sm={24} md={12} lg={12} xl={8} xxl={6}>
              <div
                className="user-info-card"
                style={{ width: "30rem", gap: "2rem" }}
              >
                <h1>Kazanılan Bonus </h1>
                <h2 style={{ color: "#5f63f2", fontSize: "2.4rem" }}>
                  {totalUserBonusEarned.toFixed(2)}₺
                </h2>
              </div>
            </Col>

            <Col xs={24} sm={24} md={12} lg={12} xl={8} xxl={6}>
              <div
                className="user-info-card"
                style={{ width: "30rem", gap: "2rem" }}
              >
                <h1>Toplam Satış Sayısı </h1>
                <h2 style={{ color: "#5f63f2", fontSize: "2.4rem" }}>
                  {totalSalesCount}
                </h2>
              </div>
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={8} xxl={6}>
              <div
                className="user-info-card"
                style={{ width: "30rem", gap: "2rem" }}
              >
                <h1>Toplam Satış Sayısı (Kredi Kartı) </h1>
                <h2 style={{ color: "#5f63f2", fontSize: "2.4rem" }}>
                  {totalSalesByCreditCardCount}
                </h2>
              </div>
            </Col>

            <Col xs={24} sm={24} md={12} lg={12} xl={8} xxl={6}>
              <div
                className="user-info-card"
                style={{ width: "30rem", gap: "2rem" }}
              >
                <h1>Toplam Satış Sayısı (Bakiye) </h1>
                <h2 style={{ color: "#5f63f2", fontSize: "2.4rem" }}>
                  {totalSalesByMoneyCount}
                </h2>
              </div>
            </Col>

            <Col xs={24} sm={24} md={12} lg={12} xl={8} xxl={6}>
              <div
                className="user-info-card"
                style={{ width: "30rem", gap: "2rem" }}
              >
                <h1>Toplam Satış Sayısı (Bonus) </h1>
                <h2 style={{ color: "#5f63f2", fontSize: "2.4rem" }}>
                  {totalSalesByBonusCount}
                </h2>
              </div>
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={8} xxl={6}>
              <div
                className="user-info-card"
                style={{ width: "30rem", gap: "2rem" }}
              >
                <h1>Toplam Satış Sayısı (Karışık) </h1>
                <h2 style={{ color: "#5f63f2", fontSize: "2.4rem" }}>
                  {totalSalesByMixCount}
                </h2>
              </div>
            </Col>
          </Row>
          <Row gutter={[24, 24]}>
            <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
              <div className="char-container">
                <h1>Satış çizelge</h1>
                <Doughnut data={firstChar} />
              </div>
            </Col>
          </Row>
        </>
      )}
    </PageContainer>
  );
};

export default SalesStatistics;
