import React, { useEffect, useCallback } from "react";

// Styles
import "./AsideLink.scss";

// Icons
import FeatherIcon from "feather-icons-react";

// hooks
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

// Redux - Actions
import { setUrlPath } from "../../redux/actions/navbarPanel";

const AsideLink = ({ icon, title, isIcon, path }) => {
  const urlPath = useSelector((state) => state.navbarPanel.urlPath);
  const dispatch = useDispatch();
  const history = useHistory();

  const setUrlPathOnFirstLoad = useCallback(() => {
    if (urlPath !== "") return;
    dispatch(setUrlPath(history.location.pathname.replace("/", "")));
  }, [urlPath, dispatch, history.location.pathname]);

  useEffect(() => {
    setUrlPathOnFirstLoad();
  }, [setUrlPathOnFirstLoad]);

  const asideLinkHandler = (e) => {
    e.preventDefault();
    dispatch(setUrlPath(path));
    history.push(`/${path}`);
  };
  return (
    <div>
      <a
        className={`asideLink  ${path === urlPath ? "active" : null}`}
        onClick={asideLinkHandler}
        href={`/${path}`}
      >
        <FeatherIcon
          icon={icon}
          width="1.5rem"
          className={`asideLink__icon ${isIcon ? "visible" : null}`}
        />
        <span>{title}</span>
      </a>
    </div>
  );
};

export default AsideLink;
