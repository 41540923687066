import React from "react";
import { useHistory } from "react-router-dom";

// Container
import PagerContainer from "../../layouts/PageContainer/PageContainer";

// Antd - Componets
import { Button, Form, Input, Select, Spin } from "antd";

// Icons
import { LoadingOutlined } from "@ant-design/icons";
import FeatherIconsReact from "feather-icons-react";

// Hooks
import { useDispatch, useSelector } from "react-redux";

// @Redux Actions
import { createAdmin } from "../../redux/actions/auth";

const antIcon = <LoadingOutlined style={{ fontSize: 128 }} spin />;

const { Option } = Select;

const AddAdmin = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const loading = useSelector((state) => state.auth.loading);

  const onLink = () => {
    history.push(`/admins`);
  };

  const handleSubmit = async (value) => {
    dispatch(createAdmin(value));
    form.resetFields();
  };

  const cancelScroll = (e) => {
    e.target.blur();
  };

  return (
    <PagerContainer>
      {loading ? (
        <Spin indicator={antIcon} className="spinner" />
      ) : (
        <>
          <div className="add-discount-container">
            <div className="discount-title">
              <h1>Yeni Admin Ekle</h1>
              <div>
                <Button
                  type="primary"
                  className="discount-title-link"
                  onClick={onLink}
                >
                  <FeatherIconsReact icon="plus" width="1.5rem" />
                  <span>Geri Dön</span>
                </Button>
              </div>
            </div>

            <div className="form-container">
              <Form
                layout="vertical"
                onFinish={handleSubmit}
                className="discount-add-form"
                form={form}
                name="addAdmin"
              >
                <Form.Item
                  name="displayName"
                  label="Admin Adı"
                  rules={[
                    {
                      message: "Admin Adı Giriniz.",
                      required: true,
                    },
                  ]}
                >
                  <Input />
                </Form.Item>

                <Form.Item
                  name="email"
                  label="Admin Email"
                  rules={[
                    {
                      message: "Admin Email Giriniz.",
                      required: true,
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  name="phone"
                  label="Telefon"
                  rules={[
                    {
                      message: "Telefon Giriniz.",
                      required: true,
                    },
                  ]}
                >
                  <Input
                    type="number"
                    onScroll={cancelScroll}
                    onWheel={cancelScroll}
                  />
                </Form.Item>
                <Form.Item
                  rules={[
                    {
                      message: "Lütfen Yeni Şifrenizi giriniz!",
                      required: true,
                    },
                  ]}
                  name="password"
                  label="Şifre"
                >
                  <Input.Password />
                </Form.Item>

                <Form.Item
                  dependencies={["password"]}
                  rules={[
                    {
                      required: true,
                      message: "Lütfen Şifre Tekrarı giriniz!",
                    },
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (!value || getFieldValue("password") === value) {
                          return Promise.resolve();
                        }
                        return Promise.reject(
                          new Error("Girdiğiniz şifreler eşleşmiyor!")
                        );
                      },
                    }),
                  ]}
                  name="confirmPassword"
                  label="Şifre Tekrar"
                >
                  <Input.Password />
                </Form.Item>
                <Form.Item
                  name="adminRule"
                  label="Role"
                  rules={[{ message: "Role Seçiniz.", required: true }]}
                >
                  <Select style={{ width: "100%" }} placeholder="">
                    <Option value="admin">Admin</Option>
                    <Option value="user">Kullanıcı</Option>
                    <Option value="superAdmin">SuperAdmin</Option>
                  </Select>
                </Form.Item>
                <Form.Item>
                  <Button
                    className="btn-add-q"
                    htmlType="submit"
                    type="primary"
                    size="large"
                    disabled={loading}
                  >
                    {loading ? "Yükleniyor..." : "Kaydet"}
                  </Button>
                </Form.Item>
              </Form>
            </div>
          </div>
        </>
      )}
    </PagerContainer>
  );
};

export default AddAdmin;
