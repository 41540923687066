import React, { useState } from "react";

// Antd - Componets
import { Button, Form, Input, DatePicker } from "antd";

// Icons
import { CloudDownloadOutlined } from "@ant-design/icons";

// Utils
import ExportJsonExcel from "js-export-excel";
import axios from "axios";
import moment from "moment";
import "moment/locale/tr";
import locale from "antd/es/date-picker/locale/tr_TR";
moment.locale("tr");

const { RangePicker } = DatePicker;
const FirstSaleReport = () => {
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (values) => {
    setLoading(true);
    //console.log(values);
    const startDate = moment(values.date[0]).format("YYYY-MM-DD");
    const endDate = moment(values.date[1]).format("YYYY-MM-DD");
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const body = JSON.stringify({
      startDate,
      endDate,
      sellerId: values.sellerId,
    });
    try {
      const res = await axios.put(
        "https://jetd-backend.herokuapp.com/api/v1/sales/firstSaleReport",
        body,
        config
      );

      const data = res.data.data;
      const option = {
        fileName: `ilk satış`,
        datas: [
          {
            sheetData: data,
            sheetName: `ilk satış`,
            sheetFilter: [
              "Müşteri_Adı",
              "email",
              "Telefon",
              "toplam_satış_tutarı",
              "indirimsiz_ilk_satış_tutarı",
              "indirimli_ilk_satış_tutarı",
              "ilk_satış_indirim_tutar",
              "ilk_satış_jetd_Komisyonu",
              "ilk_satış_Brüt",
              "ilk_satış_Net",
              "kayıt_Tarihi",
              "İndirimsiz_Toplam_Satış_Tutarı",
              "toplam_Satış_Tutarı",
              "toplam_JETDKomisyon",
              "toplam_Kazanılan_Bonus",
              "Bakiy",
              "Bonus",
              "provider",
              "İl",
              "İlçe",
              "adres",
              "Kullanıcı_tipi",
              "Cinsiyet",
              "sektör",
              "bildirimId",
            ],
            sheetHeader: [
              "Müşteri_Adı",
              "email",
              "Telefon",
              "toplam_satış_tutarı",
              "indirimsiz_ilk_satış_tutarı",
              "indirimli_ilk_satış_tutarı",
              "ilk_satış_indirim_tutar",
              "ilk_satış_jetd_Komisyonu",
              "ilk_satış_Brüt",
              "ilk_satış_Net",
              "kayıt_Tarihi",
              "İndirimsiz_Toplam_Satış_Tutarı",
              "toplam_Satış_Tutarı",
              "toplam_JETDKomisyon",
              "toplam_Kazanılan_Bonus",
              "Bakiy",
              "Bonus",
              "provider",
              "İl",
              "İlçe",
              "adres",
              "Kullanıcı_tipi",
              "Cinsiyet",
              "sektör",
              "bildirimId",
            ],
          },
        ],
      };
      const toExcel = new ExportJsonExcel(option);
      toExcel.saveExcel();
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const [form] = Form.useForm();

  return (
    <Form
      layout="inline"
      onFinish={handleSubmit}
      form={form}
      name="firstSaleReport"
      style={{ gap: "10rem", marginTop: "2rem" }}
    >
      <Form.Item
        name="sellerId"
        label="Satıcı ID"
        rules={[
          {
            message: "Satıcı ID Giriniz.",
            required: true,
          },
        ]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        name="date"
        label="Başlangıç / Bitiş Tarihi"
        rules={[
          {
            message: "Başlangıç / Bitiş Tarihi Giriniz.",
            required: true,
          },
        ]}
      >
        <RangePicker
          placeholder={["Başlangıç Tarihi", "Bitiş Tarihi"]}
          locale={locale}
          // onChange={handleChange}
        />
      </Form.Item>

      <Form.Item>
        <Button
          className="btn-add-q"
          htmlType="submit"
          icon={<CloudDownloadOutlined />}
          disabled={loading}
        >
          {loading ? "Yükleniyor..." : "Raporu İndir"}
        </Button>
      </Form.Item>
    </Form>
  );
};

export default FirstSaleReport;

// {loading ? "Yükleniyor..." : "Kaydet"}
