import React from "react";

// Styles
import "./SPanle.scss";

// Component
import SPanleButton from "../SPanleButton/SPanleButton";
import AsideLink from "../AsideLink/AsideLink";

const users = () => (
  <div className="userPanleContainer">
    <AsideLink icon="home" title="Alıcılar" isIcon={false} path="buyer" />
    <AsideLink icon="home" title="Satıcılar" isIcon={false} path="seller" />
  </div>
);

const localDiscount = () => (
  <div className="contentContainer">
    <h1>Kampanyalar</h1>
  </div>
);

const sales = () => (
  <div className="contentContainer">
    <h1>Tüm Satışlar</h1>
  </div>
);

const withdraw = () => (
  <div className="contentContainer">
    <h1>Para Çekme Talepleri</h1>
  </div>
);

const addMoney = () => (
  <div className="userPanleContainer">
    <AsideLink
      icon="dollar-sign"
      title="Yüklenen Bakiyeler"
      isIcon={false}
      path="acceptAddMoneyRequest"
    />
  </div>
);

const SPanle = () => {
  return (
    <section className="SPanleContainer">
      <SPanleButton icon="users" content={users} path="buyer" />
      <SPanleButton icon="gift" content={localDiscount} path="local-discount" />
      <SPanleButton icon="dollar-sign" content={sales} path="sales" />
      <SPanleButton icon="send" content={withdraw} path="withdraws-completed" />
      <SPanleButton
        icon="briefcase"
        content={addMoney}
        path="acceptAddMoneyRequest"
      />
    </section>
  );
};

export default SPanle;
