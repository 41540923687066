import React, { useCallback, useEffect } from "react";

// Antd - components
import { Row, Col, Spin } from "antd";

// Hooks
import { useDispatch, useSelector } from "react-redux";

// Components
import PageContainer from "../../layouts/PageContainer/PageContainer";
import { Doughnut, Bar } from "react-chartjs-2";

// Icons
import { LoadingOutlined } from "@ant-design/icons";

// Redux -  Actions
import { fetchUsersStatistics } from "../../redux/actions/auth";

// Utils
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
} from "chart.js";

// Styles
import "./UsersStatistics.scss";

const antIcon = <LoadingOutlined style={{ fontSize: 128 }} spin />;

ChartJS.register(
  ArcElement,
  Tooltip,
  Legend,
  BarElement,
  Title,
  CategoryScale,
  LinearScale
);

const UsersStatistics = () => {
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.auth.loading);

  const fetchData = useCallback(() => {
    dispatch(fetchUsersStatistics());
  }, [dispatch]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const {
    totalBuyer,
    totalCommercialUserCount,
    totalComplatedAccount,
    totalLocalDiscountCount,
    totalPersonalUserCount,
    totalPhoneVerifyUser,
    totalSellerCount,
    totaldeactiveAccount,
  } = useSelector((state) => state.auth);

  const firstChar = {
    labels: ["Kurumsal Alıcı", "Bireysel Alıcı"],
    datasets: [
      {
        label: "# of Votes",
        data: [totalCommercialUserCount, totalPersonalUserCount],
        backgroundColor: ["rgba(255, 99, 132, 0.2)", "rgba(54, 162, 235, 0.2)"],
        borderColor: ["rgba(255, 99, 132, 1)", "rgba(54, 162, 235, 1)"],
        borderWidth: 0.5,
      },
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: true,
        text: " ",
      },
    },
  };

  const labels = [
    "Telefon Doğrulama Kullanıcısı",
    "Tamamlanan Hesap",
    "Pasif Değil Hesap",
  ];

  const data = {
    labels,
    datasets: [
      {
        label: "Alıcı Hesap  Durumu",
        data: [
          totalPhoneVerifyUser,
          totalPersonalUserCount,
          totaldeactiveAccount,
        ],
        backgroundColor: "rgba(255, 99, 132, 0.5)",
      },
      {
        label: "Toplam Alıcı Hesap",
        data: [totalBuyer, totalBuyer, totalBuyer],
        backgroundColor: "rgba(53, 162, 235, 0.5)",
      },
    ],
  };

  return (
    <PageContainer>
      {loading ? (
        <Spin indicator={antIcon} className="spinner" />
      ) : (
        <>
          <h1 className="report-title">Alıcı / Satıcı İstatistikleri</h1>
          <Row gutter={[0, 10]}>
            <Col xs={24} sm={24} md={12} lg={12} xl={8} xxl={6}>
              <div
                className="user-info-card"
                style={{ width: "30rem", gap: "2rem" }}
              >
                <h1>Toplam Alıcı Sayısı</h1>
                <h2 style={{ color: "#5f63f2", fontSize: "2.4rem" }}>
                  {totalBuyer}
                </h2>
              </div>
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={8} xxl={6}>
              <div
                className="user-info-card"
                style={{ width: "30rem", gap: "2rem" }}
              >
                <h1>Toplam Bireysel Alıcı Sayısı</h1>
                <h2 style={{ color: "#5f63f2", fontSize: "2.4rem" }}>
                  {totalPersonalUserCount}
                </h2>
              </div>
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={8} xxl={6}>
              <div
                className="user-info-card"
                style={{ width: "30rem", gap: "2rem" }}
              >
                <h1>Toplam Kurumsal Alıcı Sayısı </h1>
                <h2 style={{ color: "#5f63f2", fontSize: "2.4rem" }}>
                  {totalCommercialUserCount}
                </h2>
              </div>
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={8} xxl={6}>
              <div
                className="user-info-card"
                style={{ width: "30rem", gap: "2rem" }}
              >
                <h1>Toplam Telefon Doğrulama Kullanıcısı Sayısı </h1>
                <h2 style={{ color: "#5f63f2", fontSize: "2.4rem" }}>
                  {totalPhoneVerifyUser}
                </h2>
              </div>
            </Col>

            <Col xs={24} sm={24} md={12} lg={12} xl={8} xxl={6}>
              <div
                className="user-info-card"
                style={{ width: "30rem", gap: "2rem" }}
              >
                <h1>Toplam Tamamlanan Hesap Sayısı </h1>
                <h2 style={{ color: "#5f63f2", fontSize: "2.4rem" }}>
                  {totalComplatedAccount}
                </h2>
              </div>
            </Col>

            <Col xs={24} sm={24} md={12} lg={12} xl={8} xxl={6}>
              <div
                className="user-info-card"
                style={{ width: "30rem", gap: "2rem" }}
              >
                <h1>Toplam Aktif değil Hesap Sayısı </h1>
                <h2 style={{ color: "#5f63f2", fontSize: "2.4rem" }}>
                  {totaldeactiveAccount}
                </h2>
              </div>
            </Col>

            <Col xs={24} sm={24} md={12} lg={12} xl={8} xxl={6}>
              <div
                className="user-info-card"
                style={{ width: "30rem", gap: "2rem" }}
              >
                <h1>Toplam Satıcı Sayısı </h1>
                <h2 style={{ color: "#5f63f2", fontSize: "2.4rem" }}>
                  {totalSellerCount}
                </h2>
              </div>
            </Col>

            <Col xs={24} sm={24} md={12} lg={12} xl={8} xxl={6}>
              <div
                className="user-info-card"
                style={{ width: "30rem", gap: "2rem" }}
              >
                <h1>Toplam Kampanya Sayıs </h1>
                <h2 style={{ color: "#5f63f2", fontSize: "2.4rem" }}>
                  {totalLocalDiscountCount}
                </h2>
              </div>
            </Col>
          </Row>
          <Row gutter={[24, 24]}>
            <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
              <div className="char-container">
                <h1>Kurumsal Bireysel çizelge</h1>
                <Doughnut data={firstChar} />
              </div>
            </Col>
            <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
              <div className="char-container">
                <h1>Alıcı çizelge</h1>
                <Bar options={options} data={data} />
              </div>
            </Col>
          </Row>
        </>
      )}
    </PageContainer>
  );
};

export default UsersStatistics;
