import React from 'react';

// Styles
import './PageContainer.scss';

// Redux + Hooks
import { useSelector } from 'react-redux';

const PageContainer = ({ children }) => {
  const isNavbarPanelOpen = useSelector(
    (state) => state.navbarPanel.isNavbarPanelOpen
  );
  return (
    <section
      className={` pageContainer ${
        isNavbarPanelOpen ? 'pageContainerOpen' : 'pageContainerClosed'
      }`}
    >
      {children}
    </section>
  );
};

export default PageContainer;
