import { SET_NAV_PANLE, SET_URL_PATH } from '../types';

export const setNav = (isNav) => (dispatch) => {
  dispatch({
    type: SET_NAV_PANLE,
    payload: isNav,
  });
};

export const setUrlPath = (urlPath) => (dispatch) => {
  dispatch({
    type: SET_URL_PATH,
    payload: urlPath,
  });
};
