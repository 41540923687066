import React, { useCallback, useEffect, createRef } from "react";

import html2canvas from "html2canvas";

// Antd - components
import { Row, Col, Spin, Button } from "antd";

// Hooks
import { useDispatch, useSelector } from "react-redux";

// Components
import PageContainer from "../../layouts/PageContainer/PageContainer";
import { Doughnut, Bar } from "react-chartjs-2";

// Icons
import { LoadingOutlined } from "@ant-design/icons";

// Redux -  Actions
import { fetchMonthlyStatistics } from "../../redux/actions/auth";

// Utils
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
} from "chart.js";

// // Styles
// import "./UsersStatistics.scss";

import BestSellerMonth from "../../components/BestSellerForThisMonth/BestSellerForThisMonth";
import BestBuyerForThisMonth from "../../components/BestBuyerForThisMonth/BestBuyerForThisMonth";
import BestJetdSalesForThisMonth from "../../components/BestJetdSalesForThisMonth/BestJetdSalesForThisMonth";
const antIcon = <LoadingOutlined style={{ fontSize: 128 }} spin />;

ChartJS.register(
  ArcElement,
  Tooltip,
  Legend,
  BarElement,
  Title,
  CategoryScale,
  LinearScale
);

const MonthlyStatistics = () => {
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.auth.loading);

  const ref = createRef(null);

  const handleClickTakeScreenShot = () => {
    const { cropPositionTop, cropPositionLeft, cropWidth, cropHeigth } = {
      cropPositionTop: 0,
      cropPositionLeft: 0,
      cropWidth: 5000,
      cropHeigth: 20000,
    };

    html2canvas(ref.current).then((canvas) => {
      let croppedCanvas = document.createElement("canvas");
      let croppedCanvasContext = croppedCanvas.getContext("2d");

      croppedCanvas.width = cropWidth;
      croppedCanvas.height = cropHeigth;

      croppedCanvasContext.drawImage(canvas, cropPositionLeft, cropPositionTop);

      const a = document.createElement("a");
      a.href = croppedCanvas.toDataURL();
      a.download = "screenShot.png";
      a.click();
    });
  };

  const fetchData = useCallback(() => {
    dispatch(fetchMonthlyStatistics());
  }, [dispatch]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const {
    sellersWithMostSalesAmount,
    sellersWithMostSalesCount,
    usersWithMostSalesCount,
    usersWithMostSalesAmount,
    bsetJetdProfitForThisMonth,
    totalJetdProfitForLastMonth,
    totalJetdProfitForThisMonth,
    totalNewBuyerForThisMonth,
    totalNewBuyerLastMonth,
    totalNewDiscountForThisMonth,
    totalNewDiscountLastMonth,
    totalNewSellerForThisMonth,
    totalNewSellerLastMonth,
    totalSalesAmountBounsForLastMonth,
    totalSalesAmountBounsForThisMonth,
    totalSalesAmountCreditCardForLastMonth,
    totalSalesAmountCreditCardForThisMonth,
    totalSalesAmountForLastMonth,
    totalSalesAmountForThisMonth,
    totalSalesAmountMixForLastMonth,
    totalSalesAmountMixForThisMonth,
    totalSalesAmountMoneyForLastMonth,
    totalSalesAmountMoneyForThisMonth,
    totalSalesCountBounsForLastMonth,
    totalSalesCountBounsForThisMonth,
    totalSalesCountCreditCardForLastMonth,
    totalSalesCountCreditCardForThisMonth,
    totalSalesCountForLastMonth,
    totalSalesCountForThisMonth,
    totalSalesCountMixForLastMonth,
    totalSalesCountMixForThisMonth,
    totalSalesCountMoneyForLastMonth,
    totalSalesCountMoneyForThisMonth,
  } = useSelector((state) => state.auth);

  const firstChar = {
    labels: [
      "Bonus Satış Bu Ay",
      "Bakiye Satış Bu Ay",
      "Kredi Kartı Satış Bu Ay",
      "Mix Satış Bu Ay",
    ],
    datasets: [
      {
        label: "# of Votes",
        data: [
          totalSalesCountBounsForThisMonth,
          totalSalesCountMoneyForThisMonth,
          totalSalesCountCreditCardForThisMonth,
          totalSalesCountMixForThisMonth,
        ],
        backgroundColor: [
          "rgba(255, 99, 132, 0.2)",
          "rgba(54, 162, 235, 0.2)",
          "rgba(255, 206, 86, 0.2)",
          "rgba(75, 192, 192, 0.2)",
        ],
        borderColor: [
          "rgba(255, 99, 132, 1)",
          "rgba(54, 162, 235, 1)",
          "rgba(255, 206, 86, 1)",
          "rgba(75, 192, 192, 1)",
        ],
        borderWidth: 0.5,
      },
    ],
  };

  const optionsOne = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: false,
        text: " ",
      },
    },
  };

  const labelsOne = [
    "Toplam  Satış Tutarı ",
    "Toplam Satış tutarı (Kredi Kartı) ",
    "Toplam Satış tutarı (Bakiye) ",
    "Toplam Satış tutarı (Bonus) ",
    "Toplam Satış tutarı (Mix) ",
  ];

  const labelsTwo = [
    "Toplam  Satış Sayısı ",
    "Toplam Satış Sayısı (Kredi Kartı) ",
    "Toplam Satış Sayısı (Bakiye) ",
    "Toplam Satış Sayısı (Bonus) ",
    "Toplam Satış Sayısı (Mix) ",
  ];

  const labelsThree = [
    "Toplam  Yeni Alıcı",
    "Toplam Yeni Satıcı",
    "Toplam Yeni Kampanya ",
  ];

  const dataOne = {
    labels: labelsOne,
    datasets: [
      {
        label: "Satış tutarı Bu Ay",
        data: [
          totalSalesAmountForThisMonth,
          totalSalesAmountCreditCardForThisMonth,
          totalSalesAmountMoneyForThisMonth,
          totalSalesAmountBounsForThisMonth,
          totalSalesAmountMixForThisMonth,
        ],
        backgroundColor: "rgba(255, 99, 132, 0.5)",
      },
      {
        label: "Satış tutarı Geçen Ay",
        data: [
          totalSalesAmountForLastMonth,
          totalSalesAmountCreditCardForLastMonth,
          totalSalesAmountMoneyForLastMonth,
          totalSalesAmountBounsForLastMonth,
          totalSalesAmountMixForLastMonth,
        ],
        backgroundColor: "rgba(53, 162, 235, 0.5)",
      },
    ],
  };

  const dataThree = {
    labels: labelsThree,
    datasets: [
      {
        label: "Bu Ay",
        data: [
          totalNewBuyerForThisMonth,
          totalNewSellerForThisMonth,
          totalNewDiscountForThisMonth,
        ],
        backgroundColor: "rgba(255, 99, 132, 0.5)",
      },
      {
        label: "Geçen Ay",
        data: [
          totalNewBuyerLastMonth,
          totalNewSellerLastMonth,
          totalNewDiscountLastMonth,
        ],
        backgroundColor: "rgba(53, 162, 235, 0.5)",
      },
    ],
  };

  const dataTwo = {
    labels: labelsTwo,
    datasets: [
      {
        label: "Satış Sayısı Bu Ay",
        data: [
          totalSalesCountForThisMonth,
          totalSalesCountCreditCardForThisMonth,
          totalSalesCountMoneyForThisMonth,
          totalSalesCountBounsForThisMonth,
          totalSalesCountMixForThisMonth,
        ],
        backgroundColor: "rgba(255, 99, 132, 0.5)",
      },
      {
        label: "Satış Sayısı Geçen Ay",
        data: [
          totalSalesCountForLastMonth,
          totalSalesCountCreditCardForLastMonth,
          totalSalesCountMoneyForLastMonth,
          totalSalesCountBounsForLastMonth,
          totalSalesCountMixForLastMonth,
        ],
        backgroundColor: "rgba(53, 162, 235, 0.5)",
      },
    ],
  };

  return (
    <PageContainer>
      {loading ? (
        <Spin indicator={antIcon} className="spinner" />
      ) : (
        <div
          style={{
            width: "100%",
            justifyContent: "center",
            alignItems: "center",
            display: "flex",
            flexDirection: "column",
          }}
          ref={ref}
        >
          <h1 className="report-title">Aylık İstatistikleri</h1>

          <Button
            onClick={handleClickTakeScreenShot}
            style={{ marginBottom: "2rem" }}
          >
            {" "}
            Screen Shot
          </Button>
          <Row gutter={[0, 10]}>
            <Col xs={24} sm={24} md={12} lg={12} xl={8} xxl={6}>
              <div
                className="user-info-card"
                style={{ width: "30rem", gap: "2rem" }}
              >
                <h1>Toplam İndirimli Satış Tutarı Bu Ay </h1>
                <h2 style={{ color: "#5f63f2", fontSize: "2.4rem" }}>
                  {totalSalesAmountForThisMonth.toFixed(2)}₺
                </h2>
              </div>
            </Col>

            <Col xs={24} sm={24} md={12} lg={12} xl={8} xxl={6}>
              <div
                className="user-info-card"
                style={{ width: "30rem", gap: "2rem" }}
              >
                <h1>Toplam Jetd Kazancı Bu Ay </h1>
                <h2 style={{ color: "#5f63f2", fontSize: "2.4rem" }}>
                  {totalJetdProfitForThisMonth.toFixed(2)}₺
                </h2>
              </div>
            </Col>

            <Col xs={24} sm={24} md={12} lg={12} xl={8} xxl={6}>
              <div
                className="user-info-card"
                style={{ width: "30rem", gap: "2rem" }}
              >
                <h1>Toplam Jetd Kazancı Geçen ay </h1>
                <h2 style={{ color: "#5f63f2", fontSize: "2.4rem" }}>
                  {totalJetdProfitForLastMonth.toFixed(2)}₺
                </h2>
              </div>
            </Col>

            <Col xs={24} sm={24} md={12} lg={12} xl={8} xxl={6}>
              <div
                className="user-info-card"
                style={{ width: "30rem", gap: "2rem" }}
              >
                <h1>Toplam Satış Sayısı Bu Ay </h1>
                <h2 style={{ color: "#5f63f2", fontSize: "2.4rem" }}>
                  {totalSalesCountForThisMonth}
                </h2>
              </div>
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={8} xxl={6}>
              <div
                className="user-info-card"
                style={{ width: "30rem", gap: "2rem" }}
              >
                <h1>Toplam Satış tutarı (Kredi Kartı) Bu Ay </h1>
                <h2 style={{ color: "#5f63f2", fontSize: "2.4rem" }}>
                  {totalSalesAmountCreditCardForThisMonth.toFixed(2)}₺
                </h2>
              </div>
            </Col>

            <Col xs={24} sm={24} md={12} lg={12} xl={8} xxl={6}>
              <div
                className="user-info-card"
                style={{ width: "30rem", gap: "2rem" }}
              >
                <h1>Toplam Satış tutarı (Bakiye) Bu Ay </h1>
                <h2 style={{ color: "#5f63f2", fontSize: "2.4rem" }}>
                  {totalSalesAmountMoneyForThisMonth.toFixed(2)}₺
                </h2>
              </div>
            </Col>

            <Col xs={24} sm={24} md={12} lg={12} xl={8} xxl={6}>
              <div
                className="user-info-card"
                style={{ width: "30rem", gap: "2rem" }}
              >
                <h1>Toplam Satış tutarı (Bonus) Bu Ay </h1>
                <h2 style={{ color: "#5f63f2", fontSize: "2.4rem" }}>
                  {totalSalesAmountBounsForThisMonth.toFixed(2)}₺
                </h2>
              </div>
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={8} xxl={6}>
              <div
                className="user-info-card"
                style={{ width: "30rem", gap: "2rem" }}
              >
                <h1>Toplam Satış tutarı (Karışık) </h1>
                <h2 style={{ color: "#5f63f2", fontSize: "2.4rem" }}>
                  {totalSalesAmountMixForThisMonth.toFixed(2)}₺
                </h2>
              </div>
            </Col>

            <Col xs={24} sm={24} md={12} lg={12} xl={8} xxl={6}>
              <div
                className="user-info-card"
                style={{ width: "30rem", gap: "2rem" }}
              >
                <h1>Toplam Satış Sayısı (Kredi Kartı) Bu Ay </h1>
                <h2 style={{ color: "#5f63f2", fontSize: "2.4rem" }}>
                  {totalSalesCountCreditCardForThisMonth}
                </h2>
              </div>
            </Col>

            <Col xs={24} sm={24} md={12} lg={12} xl={8} xxl={6}>
              <div
                className="user-info-card"
                style={{ width: "30rem", gap: "2rem" }}
              >
                <h1>Toplam Satış Sayısı (Bakiye) Bu Ay </h1>
                <h2 style={{ color: "#5f63f2", fontSize: "2.4rem" }}>
                  {totalSalesCountMoneyForThisMonth}
                </h2>
              </div>
            </Col>

            <Col xs={24} sm={24} md={12} lg={12} xl={8} xxl={6}>
              <div
                className="user-info-card"
                style={{ width: "30rem", gap: "2rem" }}
              >
                <h1>Toplam Satış Sayısı (Bonus) Bu Ay </h1>
                <h2 style={{ color: "#5f63f2", fontSize: "2.4rem" }}>
                  {totalSalesCountBounsForThisMonth}
                </h2>
              </div>
            </Col>

            <Col xs={24} sm={24} md={12} lg={12} xl={8} xxl={6}>
              <div
                className="user-info-card"
                style={{ width: "30rem", gap: "2rem" }}
              >
                <h1>Toplam Satış Sayısı (Mix) Bu Ay </h1>
                <h2 style={{ color: "#5f63f2", fontSize: "2.4rem" }}>
                  {totalSalesCountMixForThisMonth}
                </h2>
              </div>
            </Col>

            <Col xs={24} sm={24} md={12} lg={12} xl={8} xxl={6}>
              <div
                className="user-info-card"
                style={{ width: "30rem", gap: "2rem" }}
              >
                <h1>Toplam yeni yeni alıcı Bu Ay </h1>
                <h2 style={{ color: "#5f63f2", fontSize: "2.4rem" }}>
                  {totalNewBuyerForThisMonth}
                </h2>
              </div>
            </Col>

            <Col xs={24} sm={24} md={12} lg={12} xl={8} xxl={6}>
              <div
                className="user-info-card"
                style={{ width: "30rem", gap: "2rem" }}
              >
                <h1>Toplam yeni satıcı Sayıs Bu Ay </h1>
                <h2 style={{ color: "#5f63f2", fontSize: "2.4rem" }}>
                  {totalNewSellerForThisMonth}
                </h2>
              </div>
            </Col>

            <Col xs={24} sm={24} md={12} lg={12} xl={8} xxl={6}>
              <div
                className="user-info-card"
                style={{ width: "30rem", gap: "2rem" }}
              >
                <h1>Toplam Kampanya Sayıs Bu Ay </h1>
                <h2 style={{ color: "#5f63f2", fontSize: "2.4rem" }}>
                  {totalNewDiscountForThisMonth}
                </h2>
              </div>
            </Col>
          </Row>
          <Row gutter={[24, 24]}>
            <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
              <div className="char-container">
                <h1>Satış çizelge</h1>
                <Doughnut data={firstChar} />
              </div>
            </Col>
            <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
              <div className="char-container">
                <h1>Satış tutarı çizelge</h1>
                <Bar options={optionsOne} data={dataOne} />
              </div>
            </Col>

            <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
              <div className="char-container">
                <h1>Satış Sayıs çizelge</h1>
                <Bar options={optionsOne} data={dataTwo} />
              </div>
            </Col>

            <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
              <div className="char-container">
                <h1>kullanıcılar çizelge</h1>
                <Bar options={optionsOne} data={dataThree} />
              </div>
            </Col>
          </Row>
          <div
            style={{
              marginBottom: "2rem",
              maxWidth: "100%",
            }}
          >
            <BestSellerMonth
              sellerData={sellersWithMostSalesCount}
              header="most seller sales (Count)"
            />
          </div>

          <div
            style={{
              marginBottom: "2rem",
              maxWidth: "100%",
            }}
          >
            <BestSellerMonth
              sellerData={sellersWithMostSalesAmount}
              header="most seller sales (Amount)"
            />
          </div>

          <div
            style={{
              marginBottom: "2rem",
              maxWidth: "100%",
            }}
          >
            <BestBuyerForThisMonth
              buyerData={usersWithMostSalesCount}
              header="most users sales (count)"
            />
          </div>
          <div
            style={{
              marginBottom: "2rem",
              maxWidth: "100%",
            }}
          >
            <BestBuyerForThisMonth
              buyerData={usersWithMostSalesAmount}
              header="most users sales (Amount)"
            />
          </div>
          <div
            style={{
              marginBottom: "2rem",
              maxWidth: "100%",
            }}
          >
            <BestJetdSalesForThisMonth salesData={bsetJetdProfitForThisMonth} />
          </div>
        </div>
      )}
    </PageContainer>
  );
};

export default MonthlyStatistics;
