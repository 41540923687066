import { React } from "react";

import { useDispatch, useSelector } from "react-redux";

// @styles
import "./SingleInvoices.scss";

// @ antd
import { Button } from "antd";

// @Redux
import { sendInvoice, createLogs } from "../../redux/actions/auth";

// @utils
import moment from "moment";
import "moment/locale/tr";
moment.locale("tr");

const SingleInvoices = (props) => {
  const { seller, jetdProfitAmount } = props;

  const adminName = useSelector((state) => state.auth.user.displayName);

  const dispatch = useDispatch();

  const send = async () => {
    if (window.confirm("Seçili satırdaki veriler için fatura kesilsin mi?")) {
      let data = { seller, jetdProfitAmount };
      await dispatch(sendInvoice(data));

      let log = {
        ProcessName: "CreateInvoice",
        ProcessDescription: ` (${adminName}) Admin ,  ${
          seller.companyName
        } isimli şirket için ${jetdProfitAmount.toFixed(
          2
        )} TRY fatura kesildi `,
        SellerId: null,
        UserId: null,
        Request: JSON.stringify(data),
      };

      await dispatch(createLogs(log));
    }
  };

  return (
    <div className="discount-main">
      <div className="discount-info">
        <h4>{seller?.companyName}</h4>
        <h4>{seller?.companyEmail}</h4>
        <h4>{seller?.companyType}</h4>
      </div>
      <div className="discount-info">
        <h4>{seller?.authPerson}</h4>
        <h4>{seller?.companyPhone}</h4>
      </div>
      <div className="discount-info">
        <h4>{seller?.taxOffice}</h4>
        <h4>{seller?.taxNumber}</h4>
      </div>
      <div className="discount-info">
        <h4>{seller?.invoiceAdress}</h4>
        <h4>{seller?.city}</h4>
        <h4>{seller?.area}</h4>
      </div>
      <div className="discount-info">
        <h1>{jetdProfitAmount.toFixed(2)} TRY</h1>
        <div className="discount-info">
          <Button
            type="primary"
            className="questions-title-link"
            onClick={send}
            size="small"
          >
            <span>Fatura Kes</span>
          </Button>
        </div>
      </div>
    </div>
  );
};

export default SingleInvoices;
