import React from "react";

const SellerInfoTab = ({
  totalSalesAmount,
  lastSales,
  totalWithDraw,
  totalJetdCommission,
}) => {
  return (
    <div className="user-info-tab-container">
      <div className="user-info-card">
        <h1>Toplam Saışt Tutarı</h1>
        <h2>{totalSalesAmount.toFixed(2)}₺</h2>
      </div>
      <div className="user-info-card">
        <h1>Toplam Çekilen Tutar</h1>
        <h2>{totalWithDraw.toFixed(2)}₺</h2>
      </div>
      <div className="user-info-card">
        <h1>Son İşlem Tarihi</h1>
        <h2>{lastSales}</h2>
      </div>
      <div className="user-info-card">
        <h1>Toplam JETD Kazancı </h1>
        <h2>{totalJetdCommission.toFixed(2)}₺</h2>
      </div>
    </div>
  );
};

export default SellerInfoTab;
