import React, { useCallback, useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

// Container
import PagerContainer from "../../layouts/PageContainer/PageContainer";

// Antd - Componets
import { Button, Form, Input, Select, Spin } from "antd";

// Icons
import { LoadingOutlined } from "@ant-design/icons";
import FeatherIconsReact from "feather-icons-react";

// @redux
import {
  FETCH_DROPDWON_INFO_START,
  FETCH_DROPDWON_INFO_SUCCESS,
} from "../../redux/types";

import { updateBuyer, createLogs } from "../../redux/actions/auth";

// @Utils
import axios from "axios";

// @ Styles

const antIcon = <LoadingOutlined style={{ fontSize: 128 }} spin />;

const { Option } = Select;

const BuyerInfo = () => {
  const history = useHistory();
  const loading = useSelector((state) => state.auth.loading);
  const singleBuyer = useSelector((state) => state.auth.singleBuyer);
  const [areaArr, setAreaArr] = useState({});
  const [cityLabel, setCityLabel] = useState();
  const [sectorArr, setSectorArr] = useState([]);
  const adminName = useSelector((state) => state.auth.user.displayName);

  const dispatch = useDispatch();

  // city will come from backend in future
  const cityArr = [
    { label: "Adana", value: "Adana" },
    //{ label: "Ankara", value: "Ankara" },
    //{ label: "Hakkari", value: "Hakkari" },
    //{ label: "Kocaeli", value: "Kocaeli" },
    //{ label: "Mersin", value: "Mersin" },
    //{ label: "Van", value: "Van" },
    { label: "İstanbul", value: "İstanbul" },
    //{ label: "İzmir", value: "İzmir" },
  ];

  const [form] = Form.useForm();

  const onLink = () => {
    history.push("/buyer");
  };

  const handleSubmit = async (value) => {
    if (value.phone === singleBuyer.phone) {
      delete value.phone;
    }
    await dispatch(updateBuyer(value, singleBuyer._id));
    let log = {
      ProcessName: "Change buyer info",
      ProcessDescription: `(${adminName})Admin , ${singleBuyer.displayName} alıcı bilgilerini değiştirdi`,
      SellerId: null,
      UserId: singleBuyer._id,
      Request: JSON.stringify(value),
    };
    dispatch(createLogs(log));
    history.push("/buyer");
  };

  const cancelScroll = (e) => {
    e.target.blur();
  };

  const handleCity = (c) => {
    setCityLabel(c);
  };

  const getLabel = useCallback(async () => {
    dispatch({
      type: FETCH_DROPDWON_INFO_START,
    });

    try {
      const areasJson = await axios.get(
        "https://jetd-backend.herokuapp.com/area.json"
      );

      const sectorJson = await axios.get(
        "https://jetd-backend.herokuapp.com/sector.json"
      );

      setAreaArr(areasJson.data);

      setSectorArr([...sectorJson.data]);

      dispatch({
        type: FETCH_DROPDWON_INFO_SUCCESS,
      });
    } catch (error) {
      console.log(error);
    }
  }, [dispatch]);

  useEffect(() => {
    getLabel();
  }, [getLabel]);

  return (
    <PagerContainer>
      {loading ? (
        <Spin indicator={antIcon} className="spinner" />
      ) : (
        <>
          <div className="add-discount-container">
            <div className="discount-title">
              <h1>Alıcı Düzenle</h1>
              <div>
                <Button
                  type="primary"
                  className="discount-title-link"
                  onClick={onLink}
                >
                  <FeatherIconsReact icon="plus" width="1.5rem" />
                  <span>Geri Dön</span>
                </Button>
              </div>
            </div>
            <div className="form-container">
              <Form
                layout="vertical"
                onFinish={handleSubmit}
                className="discount-add-form"
                form={form}
                name="addDiscount"
              >
                <Form.Item
                  name="displayName"
                  label="Profil Adı"
                  initialValue={singleBuyer.displayName}
                  rules={[
                    {
                      message: "Profil Adı Giriniz.",
                      required: true,
                    },
                  ]}
                >
                  <Input />
                </Form.Item>

                <Form.Item
                  name="phone"
                  label=" Telefon"
                  initialValue={singleBuyer.phone}
                  rules={[
                    {
                      message: "Telefon Giriniz.",
                      required: true,
                    },
                  ]}
                >
                  <Input
                    type="number"
                    onScroll={cancelScroll}
                    onWheel={cancelScroll}
                  />
                </Form.Item>

                <Form.Item
                  name="city"
                  label="Şehir"
                  initialValue={singleBuyer.city}
                  rules={[{ message: "Şehir Seçiniz.", required: true }]}
                >
                  <Select
                    style={{ width: "100%" }}
                    placeholder=""
                    onChange={handleCity}
                  >
                    {cityArr.map((c) => (
                      <Option value={c.value} key={c.value}>
                        {c.label}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>

                <Form.Item
                  name="area"
                  label="İlçe"
                  initialValue={singleBuyer.area}
                  rules={[{ message: "İlçe Seçiniz.", required: true }]}
                >
                  <Select style={{ width: "100%" }} placeholder="">
                    {cityLabel &&
                      areaArr[cityLabel].map((c) => (
                        <Option value={c.value} key={c.value}>
                          {c.label}
                        </Option>
                      ))}
                  </Select>
                </Form.Item>

                <Form.Item
                  name="adress"
                  label="Adresi"
                  initialValue={singleBuyer.adress}
                  rules={[
                    {
                      message: "Adresi Giriniz.",
                      required: true,
                    },
                  ]}
                >
                  <Input />
                </Form.Item>

                <Form.Item
                  name="sector"
                  label="Sektör"
                  initialValue={singleBuyer.sector}
                  rules={[{ message: "Sektör Seçiniz.", required: true }]}
                >
                  <Select style={{ width: "100%" }} placeholder="">
                    {sectorArr.map((c) => (
                      <Option value={c.value} key={c.value}>
                        {c.label}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>

                <Form.Item>
                  <Button
                    className="btn-add-q"
                    htmlType="submit"
                    type="primary"
                    size="large"
                    disabled={loading}
                  >
                    {loading ? "Yükleniyor..." : "Kaydet"}
                  </Button>
                </Form.Item>
              </Form>
            </div>
          </div>
        </>
      )}
    </PagerContainer>
  );
};

export default BuyerInfo;
