import React, { useEffect, useCallback, useState } from "react";
import { useHistory } from "react-router-dom";

// Container
import PagerContainer from "../../layouts/PageContainer/PageContainer";

// Components

import Infinite from "react-infinite-scroll-component";
import { CopyToClipboard } from "react-copy-to-clipboard";

// Antd - Componets

import { Spin, Input, Table, Button } from "antd";

// Icons
import { LoadingOutlined } from "@ant-design/icons";
import FeatherIconsReact from "feather-icons-react";

// Hooks
import { useDispatch, useSelector } from "react-redux";

// Styles
import "./Localdiscount.scss";

// @Redxu
import {
  fetchDiscount,
  fetchNextDiscount,
  searchDiscount,
  getSingleDiscount,
} from "../../redux/actions/auth";
import { setAlert } from "../../redux/actions/alerts";
//import SingleLocaldiscount from "../../components/SingleLocaldiscount/SingleLocaldiscount";

// @Layouts
import UserImage from "../../layouts/UserImage/UserImage";
import StatusLayouts from "../../layouts/StatusLayouts/StatusLayouts";

// @utils
import moment from "moment";
import "moment/locale/tr";
moment.locale("tr");

const antIcon = <LoadingOutlined style={{ fontSize: 128 }} spin />;
const antIcon2 = <LoadingOutlined style={{ fontSize: 64 }} spin />;
const { Search } = Input;

const Localdiscount = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [searchText, setSearchText] = useState("");
  const [tabelData, setTabelData] = useState([]);
  const loading = useSelector((state) => state.auth.loading);
  const localdiscount = useSelector((state) => state.auth.localdiscount);
  const listLoading = useSelector((state) => state.auth.listLoading);

  const columns = [
    {
      title: "Resim",
      dataIndex: "image",
      key: "image",
      width: 200,
    },
    {
      title: "Başlık",
      dataIndex: "title",
      key: "title",
      width: 300,
    },
    {
      title: "Kategori",
      dataIndex: "category",
      key: "category",
      width: 150,
    },
    {
      title: "Şehir",
      dataIndex: "city",
      key: "city",
      width: 150,
    },
    {
      title: "İlçe",
      dataIndex: "area",
      key: "area",
      width: 150,
    },
    {
      title: "Net",
      dataIndex: "netDiscountRate",
      key: "netDiscountRate",
      width: 150,
    },

    {
      title: "Brüt",
      dataIndex: "grossDiscountRate",
      key: "grossDiscountRate",
      width: 150,
    },

    {
      title: "Statü",
      dataIndex: "status",
      key: "status",
      width: 200,
    },

    {
      title: "ID",
      dataIndex: "id",
      key: "id",
      width: 400,
    },
    {
      title: "Tarihi",
      dataIndex: "date",
      key: "date",
      width: 200,
    },

    {
      title: "İşlemler",
      key: "operation",
      dataIndex: "operation",
      
      width: 100,
    },
  ];

  const getBuyer = useCallback(async () => {
    dispatch(fetchDiscount());
  }, [dispatch]);

  useEffect(() => {
    getBuyer();
  }, [getBuyer]);

  useEffect(() => {
    const dataList = [];
    localdiscount.forEach((b) => {
      dataList.push({
        key: b.id,
        image: <UserImage photoURL={b.image} />,
        id: (
          <>
            <CopyToClipboard text={b._id}>
              <Button onClick={onCopy}>{b._id}</Button>
            </CopyToClipboard>
          </>
        ),
        title: (
          <>
            {" "}
            {b.title}
          </>
        ),
        category: (
          <>
            {b.category.length > 20
              ? b.category.substring(0, 10) + "..."
              : b.category}
          </>
        ),
        city: <>{b.city}</>,
        area: <>{b.area}</>,
        netDiscountRate: <>{b.netDiscountRate}%</>,
        grossDiscountRate: <>{b.grossDiscountRate}%</>,
        date: <>{moment(b.createdDate).format("Do MMMM YYYY")}</>,
        status: <StatusLayouts status={b.status} />,
        operation: (
          <div className="buyer-operation">
            <Button
              type="primary"
              className="questions-title-link"
              onClick={() => {
                onEdit(b._id);
              }}
              size="small"
            >
              <span>Düzenle</span>
            </Button>
          </div>
        ),
      });
    });
    setTabelData(dataList);
    // eslint-disable-next-line
  }, [localdiscount]);

  const cahngTextHandler = (e) => {
    setSearchText(e.target.value);
  };

  const onCopy = () => {
    dispatch(setAlert("", "Kampanya Id kopyalandı", "success"));
  };

  const onEdit = async (id) => {
    await dispatch(getSingleDiscount(id));
    history.push(`/update-localDiscount`);
  };

  const searchHandler = () => {
    if (searchText === "") {
      dispatch(fetchDiscount());
    } else {
      dispatch(searchDiscount(searchText.trim()));
    }
  };

  const onLink = () => {
    history.push(`/add-new-discount`);
  };

  return (
    <PagerContainer>
      {loading ? (
        <Spin indicator={antIcon} className="spinner" />
      ) : (
        <>
          <div className="discount-title">
            <h1>Kampanyalar</h1>
            <div>
              <Button
                type="primary"
                className="discount-title-link"
                onClick={onLink}
              >
                <FeatherIconsReact icon="plus" width="1.5rem" />
                <span>Yeni Kampanya Ekle</span>
              </Button>
            </div>
          </div>
          <div className="searchContainer">
            <Search
              enterButton
              size="large"
              placeholder="Kampanya Ara"
              onSearch={searchHandler}
              onChange={cahngTextHandler}
              value={searchText}
            />
          </div>
          <Infinite
            endMessage={
              <p style={{ textAlign: "center" }}>
                <b>Yay! You have seen it all</b>
              </p>
            }
            loader={
              listLoading && <Spin indicator={antIcon2} className="spinner" />
            }
            hasMore={true}
            next={() => {
              dispatch(fetchNextDiscount());
            }}
            dataLength={localdiscount.length}
            scrollThreshold={2}
            style={{ overflow: "hidden" }}
          >
            <div className="discount-container">
              {localdiscount.length !== 0 ? (
                <>
                  <>
                    <Table
                      columns={columns}
                      dataSource={tabelData}
                      pagination={false}
                      className="tableContainer"
                      loading={listLoading}
                      scroll={{ x: "100%", y: "80%" }}
                    />
                    <Button
                      type="primary"
                      onClick={() => {
                        dispatch(fetchNextDiscount());
                      }}
                    >
                      Daha Fazla Yükle
                    </Button>
                  </>
                </>
              ) : (
                <h1>Kampanya bulunamadı</h1>
              )}
            </div>
          </Infinite>
        </>
      )}
    </PagerContainer>
  );
};

export default Localdiscount;
