// @intial-styles
import "./ant.less";
import "./App.scss";

// @ Layouts
import AppContainer from "./layouts/AppContainer/AppContainer";

// @Redux
import { Provider } from "react-redux";
import store from "./redux/store";

function App() {
  return (
    <Provider store={store}>
      <AppContainer />
    </Provider>
  );
}

export default App;
