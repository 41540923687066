import React, { useCallback, useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

// Container
import PagerContainer from "../../layouts/PageContainer/PageContainer";

// Antd - Componets
import { Button, Form, Input, Select, Spin, Switch } from "antd";

// Icons
import { LoadingOutlined } from "@ant-design/icons";
import FeatherIconsReact from "feather-icons-react";

// @redux
import {
  FETCH_DROPDWON_INFO_START,
  FETCH_DROPDWON_INFO_SUCCESS,
} from "../../redux/types";

import { updateSeller, createLogs } from "../../redux/actions/auth";

// @Utils
import axios from "axios";

// @ Styles
import "./EditSeller.scss";

const antIcon = <LoadingOutlined style={{ fontSize: 128 }} spin />;

const { Option } = Select;

const EditSeller = () => {
  const history = useHistory();
  const loading = useSelector((state) => state.auth.loading);
  const singleSeller = useSelector((state) => state.auth.singleSeller);
  const [areaArr, setAreaArr] = useState({});
  const [cityLabel, setCityLabel] = useState();
  const [sectorArr, setSectorArr] = useState([]);
  const adminName = useSelector((state) => state.auth.user.displayName);

  const dispatch = useDispatch();

  // city will come from backend in future
  const cityArr = [
    { label: "Adana", value: "Adana" },
    //{ label: "Ankara", value: "Ankara" },
    //{ label: "Hakkari", value: "Hakkari" },
    //{ label: "Kocaeli", value: "Kocaeli" },
    //{ label: "Mersin", value: "Mersin" },
    //{ label: "Van", value: "Van" },
    { label: "İstanbul", value: "İstanbul" },
    //{ label: "İzmir", value: "İzmir" },
  ];

  const [form] = Form.useForm();

  const onLink = () => {
    history.push("/seller");
  };

  const handleSubmit = async (value) => {
    await dispatch(updateSeller(value, singleSeller._id));

    let log = {
      ProcessName: "UpdateSeller",
      ProcessDescription: `(${adminName}) Admin , ${value.companyName} isimli satıcı güncellendi. , ID: ${singleSeller._id} `,
      SellerId: singleSeller._id,
      UserId: null,
      Request: JSON.stringify(value),
    };

    await dispatch(createLogs(log));

    history.push("/seller");

    console.log(value);
  };

  const cancelScroll = (e) => {
    e.target.blur();
  };

  const handleCity = (c) => {
    setCityLabel(c);
  };

  const getLabel = useCallback(async () => {
    dispatch({
      type: FETCH_DROPDWON_INFO_START,
    });

    try {
      const areasJson = await axios.get(
        "https://jetd-backend.herokuapp.com/area.json"
      );

      const sectorJson = await axios.get(
        "https://jetd-backend.herokuapp.com/sector.json"
      );

      setAreaArr(areasJson.data);

      setSectorArr([...sectorJson.data]);

      dispatch({
        type: FETCH_DROPDWON_INFO_SUCCESS,
      });
    } catch (error) {
      console.log(error);
    }
  }, [dispatch]);

  useEffect(() => {
    getLabel();
  }, [getLabel]);

  return (
    <PagerContainer>
      {loading ? (
        <Spin indicator={antIcon} className="spinner" />
      ) : (
        <>
          <div className="add-discount-container">
            <div className="discount-title">
              <h1>Satıcı Düzenle</h1>
              <div>
                <Button
                  type="primary"
                  className="discount-title-link"
                  onClick={onLink}
                >
                  <FeatherIconsReact icon="plus" width="1.5rem" />
                  <span>Geri Dön</span>
                </Button>
              </div>
            </div>
            <div className="form-container">
              <Form
                layout="vertical"
                onFinish={handleSubmit}
                className="discount-add-form"
                form={form}
                name="addDiscount"
              >
                <Form.Item
                  name="companyName"
                  label="Şirket Adı"
                  initialValue={singleSeller.companyName}
                  rules={[
                    {
                      message: "Şirket Adı Giriniz.",
                      required: true,
                    },
                  ]}
                >
                  <Input />
                </Form.Item>

                <Form.Item
                  name="companyPhone"
                  label="Şirket Telefon"
                  initialValue={singleSeller.companyPhone}
                  rules={[
                    {
                      message: "Şirket Telefon Giriniz.",
                      required: true,
                    },
                  ]}
                >
                  <Input
                    type="number"
                    onScroll={cancelScroll}
                    onWheel={cancelScroll}
                  />
                </Form.Item>

                <Form.Item
                  name="adminPassword"
                  label="Admin Şifresi"
                  initialValue={singleSeller.adminPassword}
                  rules={[
                    {
                      message: "Admin Şifresi Giriniz.",
                      required: true,
                    },
                  ]}
                >
                  <Input />
                </Form.Item>

                <Form.Item
                  name="city"
                  label="Şehir"
                  initialValue={singleSeller.city}
                  rules={[{ message: "Şehir Seçiniz.", required: true }]}
                >
                  <Select
                    style={{ width: "100%" }}
                    placeholder=""
                    onChange={handleCity}
                  >
                    {cityArr.map((c) => (
                      <Option value={c.value} key={c.value}>
                        {c.label}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>

                <Form.Item
                  name="area"
                  label="İlçe"
                  initialValue={singleSeller.area}
                  rules={[{ message: "İlçe Seçiniz.", required: true }]}
                >
                  <Select style={{ width: "100%" }} placeholder="">
                    {cityLabel &&
                      areaArr[cityLabel].map((c) => (
                        <Option value={c.value} key={c.value}>
                          {c.label}
                        </Option>
                      ))}
                  </Select>
                </Form.Item>

                <Form.Item
                  name="authPerson"
                  label="Yetkili Kişi"
                  initialValue={singleSeller.authPerson}
                  rules={[
                    {
                      message: "Yetkili Kişi Giriniz.",
                      required: true,
                    },
                  ]}
                >
                  <Input />
                </Form.Item>

                <Form.Item
                  name="companyType"
                  label="Firma Türü"
                  initialValue={singleSeller.companyType}
                  rules={[{ message: "Kategori Seçiniz.", required: true }]}
                >
                  <Select style={{ width: "100%" }} placeholder="">
                    <Option value="Şahıs">Şahıs</Option>
                    <Option value="Tüzel">Tüzel</Option>
                  </Select>
                </Form.Item>

                <Form.Item
                  name="invoiceAdress"
                  label="Fatura Adresi"
                  initialValue={singleSeller.invoiceAdress}
                  rules={[
                    {
                      message: "Fatura Adresi Giriniz.",
                      required: true,
                    },
                  ]}
                >
                  <Input />
                </Form.Item>

                <Form.Item
                  name="companyAdress"
                  label="Satıcı Adresi"
                  initialValue={singleSeller.companyAdress}
                  rules={[
                    {
                      message: "Satıcı Adresi Giriniz.",
                      required: true,
                    },
                  ]}
                >
                  <Input />
                </Form.Item>

                <Form.Item
                  name="sector"
                  label="Sektör"
                  initialValue={singleSeller.sector}
                  rules={[{ message: "Sektör Seçiniz.", required: true }]}
                >
                  <Select style={{ width: "100%" }} placeholder="">
                    {sectorArr.map((c) => (
                      <Option value={c.value} key={c.value}>
                        {c.label}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>

                <Form.Item
                  name="taxOffice"
                  label="Vergi Dairesi"
                  initialValue={singleSeller.taxOffice}
                  rules={[
                    {
                      message: "Vergi Dairesi Giriniz.",
                      required: true,
                    },
                  ]}
                >
                  <Input />
                </Form.Item>

                <Form.Item
                  name="taxNumber"
                  label="Vergi Numarası"
                  initialValue={singleSeller.taxNumber}
                  rules={[
                    {
                      message: "Vergi Numarası Giriniz.",
                      required: true,
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  label="Hesap Aktif mi?"
                  name="isActive"
                  initialValue={singleSeller.isActive}
                >
                  <Switch defaultChecked={singleSeller.isActive} />
                </Form.Item>
                <Form.Item>
                  <Button
                    className="btn-add-q"
                    htmlType="submit"
                    type="primary"
                    size="large"
                    disabled={loading}
                  >
                    {loading ? "Yükleniyor..." : "Kaydet"}
                  </Button>
                </Form.Item>
              </Form>
            </div>
          </div>
        </>
      )}
    </PagerContainer>
  );
};

export default EditSeller;
