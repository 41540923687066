import React from "react";

// @Hooks
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

// Antd - Componets
import { Button, Popconfirm } from "antd";

// @Redux
import {
  fetchSlider,
  deleteSlider,
  createLogs,
} from "../../redux/actions/auth";

// @styles
import "./SingleSilder.scss";

const SingleCategory = ({ title, _id, image, city }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const adminName = useSelector((state) => state.auth.user.displayName);
  const onLink = async () => {
    await dispatch(fetchSlider(_id));
    history.push(`/update-slider`);
  };

  const onDelete = async () => {
    await dispatch(deleteSlider(_id));

    let log = {
      ProcessName: "DeleteSlider",
      ProcessDescription: `(${adminName}) Admin , ${_id} ID değerine sahip Slider silinmiştir.`,
      SellerId: null,
      UserId: null,
      Request: JSON.stringify(_id),
    };
    await dispatch(createLogs(log));
  };
  return (
    <div className="single-category-container">
      <h1>{city}</h1>
      <img src={image} alt="category Icon" className="slider-image" />
      <h1>{title}</h1>

      <div className="button_container">
        <Button type="primary" className="discount-title-link" onClick={onLink}>
          <span>Düzenle</span>
        </Button>
        <Popconfirm
          okText="Evet"
          cancelText="Vazgeç"
          title="Onaylıyor musunuz?"
          onConfirm={onDelete}
        >
          <Button type="danger" className="discount-title-link">
            <span>Sil</span>
          </Button>
        </Popconfirm>
      </div>
    </div>
  );
};

export default SingleCategory;
