import React from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

// Container
import PagerContainer from "../../layouts/PageContainer/PageContainer";

// Antd - Componets
import { Button, Form, Input, Spin, Switch } from "antd";

// Icons
import { LoadingOutlined } from "@ant-design/icons";
import FeatherIconsReact from "feather-icons-react";

// @redux
import { updateBuyer, createLogs } from "../../redux/actions/auth";

// Utils
import axios from "axios";

const antIcon = <LoadingOutlined style={{ fontSize: 128 }} spin />;

const EditSeller = () => {
  const history = useHistory();
  const loading = useSelector((state) => state.auth.loading);
  const singleBuyer = useSelector((state) => state.auth.singleBuyer);
  const adminName = useSelector((state) => state.auth.user.displayName);
  const dispatch = useDispatch();

  const [form] = Form.useForm();

  const onLink = () => {
    history.push("/buyer");
  };

  const handleSubmit = async (value) => {
    await dispatch(updateBuyer(value, singleBuyer._id));

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    try {
      if (+singleBuyer.money < +value.money) {
        const data = {
          processName: "AddMoneyRequestIban",
          processDescription: `${
            +value.money - +singleBuyer.money
          } bakiye yüklendi.`,
          amount: +value.money - +singleBuyer.money,
          userId: singleBuyer._id,
        };

        const body = JSON.stringify(data);
        await axios.post(
          "https://jetd-backend.herokuapp.com/api/v1/processLogs",
          body,
          config
        );
      }
      if (+singleBuyer.money > +value.money) {
        const data = {
          processName: "SaleMoney",
          processDescription: `${
            +singleBuyer.money - +value.money
          } bakiye düşüldü.`,
          amount: +singleBuyer.money - +value.money,
          userId: singleBuyer._id,
        };
        const body = JSON.stringify(data);
        await axios.post(
          "https://jetd-backend.herokuapp.com/api/v1/processLogs",
          body,
          config
        );
      }
      if (+singleBuyer.bonus < +value.bonus) {
        const data = {
          processName: "AddMoneyRequestIban",
          processDescription: `${
            +value.bonus - +singleBuyer.bonus
          } bonus yüklendi.`,
          amount: +value.bonus - +singleBuyer.bonus,
          userId: singleBuyer._id,
        };

        const body = JSON.stringify(data);
        await axios.post(
          "https://jetd-backend.herokuapp.com/api/v1/processLogs",
          body,
          config
        );
      }

      if (+singleBuyer.bonus > +value.bonus) {
        const data = {
          processName: "SaleBonus",
          processDescription: `${
            +singleBuyer.bonus - +value.bonus
          } bakiye düşüldü.`,
          amount: +singleBuyer.bonus - +value.bonus,
          userId: singleBuyer._id,
        };
        const body = JSON.stringify(data);
        await axios.post(
          "https://jetd-backend.herokuapp.com/api/v1/processLogs",
          body,
          config
        );
      }
    } catch (error) {
      console.log(error);
    }

    console.log(value);
    let log = {
      ProcessName: "Change buyer money or bouns",
      ProcessDescription: `(${adminName})Admin , ${singleBuyer.displayName} alıcı Bakiye / Bonus değiştirdi`,
      SellerId: null,
      UserId: singleBuyer._id,
      Request: JSON.stringify(value),
    };
    dispatch(createLogs(log));
    history.push("/buyer");
  };

  const cancelScroll = (e) => {
    e.target.blur();
  };

  return (
    <PagerContainer>
      {loading ? (
        <Spin indicator={antIcon} className="spinner" />
      ) : (
        <>
          <div className="add-discount-container">
            <div className="discount-title">
              <h1>Bakiye / Bonus Düzenle</h1>
              <div>
                <Button
                  type="primary"
                  className="discount-title-link"
                  onClick={onLink}
                >
                  <FeatherIconsReact icon="plus" width="1.5rem" />
                  <span>Geri Dön</span>
                </Button>
              </div>
            </div>
            <div className="form-container">
              <Form
                layout="vertical"
                onFinish={handleSubmit}
                className="discount-add-form"
                form={form}
                name="editBuyer"
              >
                <Form.Item
                  name="bonus"
                  label="Bonus"
                  initialValue={singleBuyer.bonus}
                  rules={[
                    {
                      message: "Vergi Numarası Giriniz.",
                      required: true,
                    },
                  ]}
                >
                  <Input
                    type="number"
                    onScroll={cancelScroll}
                    onWheel={cancelScroll}
                  />
                </Form.Item>

                <Form.Item
                  name="money"
                  label="Bakiye"
                  initialValue={singleBuyer.money}
                  rules={[
                    {
                      message: "Vergi Numarası Giriniz.",
                      required: true,
                    },
                  ]}
                >
                  <Input
                    type="number"
                    onScroll={cancelScroll}
                    onWheel={cancelScroll}
                  />
                </Form.Item>
                <Form.Item
                  label="Hesap Aktif mi?"
                  name="isActive"
                  initialValue={singleBuyer.isActive}
                >
                  <Switch defaultChecked={singleBuyer.isActive} />
                </Form.Item>
                <Form.Item>
                  <Button
                    className="btn-add-q"
                    htmlType="submit"
                    type="primary"
                    size="large"
                    disabled={loading}
                  >
                    {loading ? "Yükleniyor..." : "Kaydet"}
                  </Button>
                </Form.Item>
              </Form>
            </div>
          </div>
        </>
      )}
    </PagerContainer>
  );
};

export default EditSeller;
