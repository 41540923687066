// Utils
import axios from "axios";
import setAuthToken from "../../utils/setAuthToken";

// Types
import {
  LOGIN_START,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT,
  LOAD_USER_START,
  USER_LOADED,
  AUTH_ERROR,
  CHANGE_PASSWORD_START,
  CHANGE_PASSWORD_SUCCESS,
  CHANGE_PASSWORD_FAIL,
  FETCH_BUYER_START,
  FETCH_BUYER_SUCCESS,
  FETCH_BUYER_FAIL,
  GET_BUYER_START,
  GET_BUYER_SUCCESS,
  GET_BUYER_FAIL,
  SEARCH_BUYER_START,
  SEARCH_BUYER_SUCCESS,
  SEARCH_BUYER_FAIL,
  FETCH_SELLER_START,
  FETCH_SELLER_SUCCESS,
  FETCH_SELLER_FAIL,
  GET_SELLER_START,
  GET_SELLER_SUCCESS,
  GET_SELLER_FAIL,
  SEARCH_SELLER_START,
  SEARCH_SELLER_SUCCESS,
  SEARCH_SELLER_FAIL,
  FETCH_ADMINS_START,
  FETCH_ADMINS_SUCCESS,
  FETCH_ADMINS_FAIL,
  FETCH_DISCOUNT_START,
  FETCH_SALE_START,
  FETCH_INVOICES_START,
  FETCH_DISCOUNT_SUCCESS,
  FETCH_SALE_SUCCESS,
  FETCH_INVOICES_SUCCESS,
  FETCH_DISCOUNT_FAIL,
  FETCH_SALE_FAIL,
  FETCH_INVOICES_FAIL,
  GET_DISCOUNT_START,
  GET_SALE_START,
  GET_DISCOUNT_SUCCESS,
  GET_SALE_SUCCESS,
  GET_DISCOUNT_FAIL,
  GET_SALE_FAIL,
  SEARCH_DISCOUNT_START,
  SEARCH_DISCOUNT_SUCCESS,
  SEARCH_DISCOUNT_FAIL,
  SEARCH_SALE_START,
  SEARCH_SALE_SUCCESS,
  SEARCH_SALE_FAIL,
  CREATE_DISCOUNT_START,
  CREATE_DISCOUNT_SUCCESS,
  CREATE_DISCOUNT_FAIL,
  CREATE_LOGS_START,
  CREATE_LOGS_SUCCESS,
  CREATE_LOGS_FAIL,
  FETCH_SINGLE_DISCOUNT_FAIL,
  FETCH_SINGLE_DISCOUNT_START,
  FETCH_SINGLE_DISCOUNT_SUCCESS,
  UPDATE_DISCOUNT_START,
  UPDATE_DISCOUNT_SUCCESS,
  UPDATE_DISCOUNT_FAIL,
  GET_SINGLE_SELLER_START,
  GET_SINGLE_SELLER_SUCCESS,
  GET_SINGLE_SELLER_FAIL,
  UPDATE_SELLER_START,
  UPDATE_SELLER_SUCCESS,
  UPDATE_SELLER_FAIL,
  CREATE_CITY_START,
  CREATE_CITY_SUCCESS,
  CREATE_CITY_FAIL,
  FETCH_CITIES_START,
  FETCH_CITIES_SUCCESS,
  FETCH_CITIES_FAIL,
  FETCH_STATISTICS_START,
  FETCH_STATISTICS_SUCCESS,
  FETCH_STATISTICS_FAIL,
  FETCH_CITY_START,
  FETCH_CITY_SUCCESS,
  FETCH_CITY_FAIL,
  UPDATE_CITY_START,
  UPDATE_CITY_SUCCESS,
  UPDATE_CITY_FAIL,
  CREATE_CATEGORY_START,
  CREATE_CATEGORY_SUCCESS,
  CREATE_CATEGORY_FAIL,
  CREATE_INVOICE_START,
  CREATE_INVOICE_SUCCESS,
  CREATE_INVOICE_FAIL,
  FETCH_CATEGORIES_START,
  FETCH_CATEGORIES_SUCCESS,
  FETCH_CATEGORIES_FAIL,
  FETCH_CATEGORY_START,
  FETCH_CATEGORY_SUCCESS,
  FETCH_CATEGORY_FAIL,
  UPDATE_CATEGORY_START,
  UPDATE_CATEGORY_SUCCESS,
  UPDATE_CATEGORY_FAIL,
  FETCH_SLIDERS_START,
  FETCH_SLIDERS_SUCCESS,
  FETCH_SLIDERS_FAIL,
  CREATE_SLIDER_START,
  CREATE_SLIDER_SUCCESS,
  CREATE_SLIDER_FAIL,
  FETCH_SLIDER_START,
  FETCH_SLIDER_SUCCESS,
  FETCH_SLIDER_FAIL,
  UPDATE_SLIDER_START,
  UPDATE_SLIDER_SUCCESS,
  UPDATE_SLIDER_FAIL,
  FETCH_COMPLETE_ADD_MONEY_REQUEST_START,
  FETCH_COMPLETE_ADD_MONEY_REQUEST_SUCCESS,
  FETCH_COMPLETE_ADD_MONEY_REQUEST_FAIL,
  GET_COMPLETE_ADD_MONEY_REQUEST_START,
  GET_COMPLETE_ADD_MONEY_REQUEST_SUCCESS,
  GET_COMPLETE_ADD_MONEY_REQUEST_FAIL,
  FETCH_WITHDRAW_START,
  FETCH_WITHDRAW_SUCCESS,
  FETCH_WITHDRAW_FAIL,
  ACCEPT_WITHDRAW_START,
  ACCEPT_WITHDRAW_SUCCESS,
  ACCEPT_WITHDRAW_FAIL,
  REJECT_WITHDRAW_START,
  REJECT_WITHDRAW_SUCCESS,
  REJECT_WITHDRAW_FAIL,
  FETCH_COMPLETED_WITHDRAW_START,
  FETCH_COMPLETED_WITHDRAW_SUCCESS,
  FETCH_COMPLETED_WITHDRAW_FAIL,
  FETCH_ADDMONEY_REQ_START,
  FETCH_ADDMONEY_REQ_SUCCESS,
  FETCH_ADDMONEY_REQ_FAIL,
  ACCEPT_ADD_MONEY_REQUEST_START,
  ACCEPT_ADD_MONEY_REQUEST_SUCCESS,
  ACCEPT_ADD_MONEY_REQUEST_FAIL,
  REJECT_ADD_MONEY_REQUEST_START,
  REJECT_ADD_MONEY_REQUEST_SUCCESS,
  REJECT_ADD_MONEY_REQUEST_FAIL,
  CREATE_NOTFICATION_START,
  CREATE_NOTFICATION_SUCCESS,
  CREATE_NOTFICATION_FAIL,
  DELETE_SLIDER_START,
  DELETE_SLIDER_SUCCESS,
  DELETE_SLIDER_FAIL,
  CREATE_ADMIN_START,
  CREATE_ADMIN_SUCCESS,
  CREATE_ADMIN_FAIL,
  GET_SINGLE_BUYER_START,
  GET_SINGLE_BUYER_SUCCESS,
  GET_SINGLE_BUYER_FAIL,
  UPDATE_BUYER_START,
  UPDATE_BUYER_SUCCESS,
  UPDATE_BUYER_FAIL,
  FILTER_BUYER_START,
  FILTER_BUYER_SUCCESS,
  FILTER_BUYER_FAIL,
  SET_BUYER_SECTOR,
  SET_BUYER_CITY,
  SET_ORDER_BUYER,
  GET_BUYER_INFO_START,
  GET_BUYER_INFO_SUCCESS,
  GET_BUYER_INFO_FAIL,
  CHANGE_BUYER_EMAIL_START,
  CHANGE_BUYER_EMAIL_SUCCESS,
  CHANGE_BUYER_EMAIL_FAIL,
  CHANGE_BUYER_PASSWORD_START,
  CHANGE_BUYER_PASSWORD_SUCCESS,
  CHANGE_BUYER_PASSWORD_FAIL,
  GET_SELLER_INFO_START,
  GET_SELLER_INFO_SUCCESS,
  GET_SELLER_INFO_FAIL,
  SET_ADVANCED_BUYER,
  SEARCH_ADVANCED_BUYER_START,
  SEARCH_ADVANCED_BUYER_SUCCESS,
  SEARCH_ADVANCED_BUYER_FAIL,
  FETCH_USERS_STATISTICS_START,
  FETCH_USERS_STATISTICS_SUCCESS,
  FETCH_USERS_STATISTICS_FAIL,
  FETCH_SALES_STATISTICS_START,
  FETCH_SALES_STATISTICS_SUCCESS,
  FETCH_SALES_STATISTICS_FAIL,
  FETCH_MONTHLY_STATISTICS_START,
  FETCH_MONTHLY_STATISTICS_SUCCESS,
  FETCH_MONTHLY_STATISTICS_FAIL,
} from "../types";

import { setAlert } from "./alerts";

export const login =
  ({ email, password }) =>
  async (dispatch) => {
    try {
      dispatch({
        type: LOGIN_START,
      });

      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      const body = JSON.stringify({
        email,
        password,
      });
      const res = await axios.post(
        "https://jetd-backend.herokuapp.com/api/v1/admin/login",
        body,
        config
      );

      dispatch({
        type: LOGIN_SUCCESS,
        payload: res.data,
      });
      dispatch(loadUser());
    } catch (err) {
      dispatch({
        type: LOGIN_FAIL,
      });
      dispatch(
        setAlert("Başarısız", "Yanlış E-posta veya Şifre Girdiniz", "fail")
      );
      console.log(err);
    }
  };

export const loadUser = () => async (dispatch) => {
  if (localStorage.token) {
    setAuthToken(localStorage.token);
  }
  dispatch({ type: LOAD_USER_START });
  try {
    const res = await axios.get(
      "https://jetd-backend.herokuapp.com/api/v1/admin/me"
    );
    dispatch({
      type: USER_LOADED,
      payload: res.data.data,
    });
    dispatch(setAlert("Hoş Geldiniz", "Giriş başarılı", "success"));
  } catch (err) {
    dispatch({ type: AUTH_ERROR });
  }
};

export const logout = () => async (dispatch) => {
  dispatch({ type: LOGOUT });
  dispatch(setAlert("Başarılı", "Sistemden çıkış yapılmılştır.", "success"));
};

export const changePassword = (value) => async (dispatch, getState) => {
  const { newPassword, oldPassword } = value;

  dispatch({
    type: CHANGE_PASSWORD_START,
  });

  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const body = JSON.stringify({
      currentPassword: oldPassword,
      newPassword,
    });
    // eslint-disable-next-line
    const res = await axios.post(
      "https://jetd-backend.herokuapp.com/api/v1/admin/updatePassword",
      body,
      config
    );
    dispatch({
      type: CHANGE_PASSWORD_SUCCESS,
    });
    dispatch(setAlert("Başarılı", "Şifre Değiştirilmiştir.", "success"));
  } catch (error) {
    dispatch({
      type: CHANGE_PASSWORD_FAIL,
    });
    dispatch(setAlert("Başarısız", "Yanlış Şifre girdiniz.", "fail"));
    console.log(error);
  }
};

export const fetchBuyer = () => async (dispatch) => {
  dispatch({
    type: FETCH_BUYER_START,
  });

  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    // `https://jetd-backend.herokuapp.com/api/v1/auth/?skip=0`
    const body = JSON.stringify({});
    const res = await axios.post(
      `https://jetd-backend.herokuapp.com/api/v1/auth/?skip=0`,
      body,
      config
    );
    dispatch({
      type: FETCH_BUYER_SUCCESS,
      payload: res.data.data,
    });
  } catch (error) {
    dispatch({
      type: FETCH_BUYER_FAIL,
    });
  }
};

export const fetchNextBuyer = () => async (dispatch, getState) => {
  const buyer = getState().auth.buyer;
  const listLoading = getState().auth.listLoading;
  if (listLoading) return;

  const buyerSector = getState().auth.buyerSector;
  const buyerCity = getState().auth.buyerCity;
  const orderBuyer = getState().auth.orderBuyer;
  const advancedOptions = getState().auth.advancedOptions;

  if (listLoading) return;

  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  dispatch({
    type: GET_BUYER_START,
  });
  try {
    const data = { advancedOptions };
    if (buyerSector.length !== 0) {
      data.sector = buyerSector;
    }
    if (buyerCity.length !== 0) {
      data.city = buyerCity;
    }
    const isOrder = Object.keys(orderBuyer).length !== 0;
    if (isOrder) {
      const order = orderBuyer[Object.keys(orderBuyer)[0]];
      const value = Object.keys(orderBuyer)[0];
      const orderType = order === "ascend" ? "-" : "+";
      data.sort = `${orderType}${value}`;
    }
    const body = JSON.stringify(data);
    const res = await axios.post(
      `https://jetd-backend.herokuapp.com/api/v1/auth/?skip=${buyer.length}`,
      body,
      config
    );
    let isDone = false;
    res.data.data.forEach((b) => {
      buyer.findIndex((x) => x._id === b._id) !== -1
        ? (isDone = true)
        : (isDone = false);
    });
    if (isDone) {
      dispatch({
        type: GET_BUYER_SUCCESS,
        payload: [],
      });
    } else {
      dispatch({
        type: GET_BUYER_SUCCESS,
        payload: res.data.data,
      });
    }
  } catch (error) {
    dispatch({
      type: GET_BUYER_FAIL,
    });
  }
};

export const searchBuyer = (data) => async (dispatch) => {
  dispatch({
    type: SEARCH_BUYER_START,
  });

  try {
    const res = await axios.get(
      `https://jetd-backend.herokuapp.com/api/v1/auth/search/${data}`
    );
    dispatch({
      type: SEARCH_BUYER_SUCCESS,
      payload: res.data.data,
    });
  } catch (error) {
    dispatch({
      type: SEARCH_BUYER_FAIL,
    });
  }
};

export const fetchSeller = () => async (dispatch) => {
  dispatch({
    type: FETCH_SELLER_START,
  });

  try {
    const res = await axios.get(
      `https://jetd-backend.herokuapp.com/api/v1/seller/?skip=0`
    );

    dispatch({
      type: FETCH_SELLER_SUCCESS,
      payload: res.data.data,
    });
  } catch (error) {
    dispatch({
      type: FETCH_SELLER_FAIL,
    });
  }
};

export const fetchNextSeller = () => async (dispatch, getState) => {
  const seller = getState().auth.seller;
  const listLoading = getState().auth.listLoading;
  if (listLoading) return;

  dispatch({
    type: GET_SELLER_START,
  });
  try {
    const res = await axios.get(
      `https://jetd-backend.herokuapp.com/api/v1/seller/?skip=${seller.length}`
    );
    dispatch({
      type: GET_SELLER_SUCCESS,
      payload: res.data.data,
    });
  } catch (error) {
    dispatch({
      type: GET_SELLER_FAIL,
    });
  }
};

export const searchSeller = (data) => async (dispatch) => {
  dispatch({
    type: SEARCH_SELLER_START,
  });
  //https://jetd-backend.herokuapp.com/
  try {
    const res = await axios.get(
      `https://jetd-backend.herokuapp.com/api/v1/seller/search/${data}`
    );
    dispatch({
      type: SEARCH_SELLER_SUCCESS,
      payload: res.data.data,
    });
  } catch (error) {
    dispatch({
      type: SEARCH_SELLER_FAIL,
    });
  }
};

export const fetchAdmins = () => async (dispatch) => {
  dispatch({
    type: FETCH_ADMINS_START,
  });

  try {
    const res = await axios.get(
      `https://jetd-backend.herokuapp.com/api/v1/admin/`
    );

    dispatch({
      type: FETCH_ADMINS_SUCCESS,
      payload: res.data.data,
    });
  } catch (error) {
    dispatch({
      type: FETCH_ADMINS_FAIL,
    });
  }
};

export const fetchDiscount = () => async (dispatch) => {
  dispatch({
    type: FETCH_DISCOUNT_START,
  });

  try {
    const res = await axios.get(
      `https://jetd-backend.herokuapp.com/api/v1/localdiscount/panel?skip=0`
    );

    dispatch({
      type: FETCH_DISCOUNT_SUCCESS,
      payload: res.data.data,
    });
  } catch (error) {
    dispatch({
      type: FETCH_DISCOUNT_FAIL,
    });
  }
};

export const fetchNextDiscount = () => async (dispatch, getState) => {
  const localdiscount = getState().auth.localdiscount;

  const listLoading = getState().auth.listLoading;
  if (listLoading) return;

  dispatch({
    type: GET_DISCOUNT_START,
  });

  try {
    const res = await axios.get(
      `https://jetd-backend.herokuapp.com/api/v1/localdiscount/panel?skip=${localdiscount.length}`
    );
    dispatch({
      type: GET_DISCOUNT_SUCCESS,
      payload: res.data.data,
    });
  } catch (error) {
    dispatch({
      type: GET_DISCOUNT_FAIL,
    });
  }
};

export const searchDiscount = (data) => async (dispatch) => {
  dispatch({
    type: SEARCH_DISCOUNT_START,
  });

  try {
    const res = await axios.get(
      `https://jetd-backend.herokuapp.com/api/v1/localdiscount/search/${data}`
    );
    dispatch({
      type: SEARCH_DISCOUNT_SUCCESS,
      payload: res.data.data,
    });
  } catch (error) {
    dispatch({
      type: SEARCH_DISCOUNT_FAIL,
    });
  }
};

export const createDiscount = (data) => async (dispatch) => {
  dispatch({ type: CREATE_DISCOUNT_START });
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    console.log(data);

    const body = JSON.stringify(data);

    console.log("", body);

    // eslint-disable-next-line
    const res = await axios.post(
      "https://jetd-backend.herokuapp.com/api/v1/localDiscount",
      body,
      config
    );

    dispatch({
      type: CREATE_DISCOUNT_SUCCESS,
    });
    dispatch(
      setAlert("Başarılı", "Kampanya başarılı bir şekilde eklendi", "success")
    );
  } catch (err) {
    dispatch({ type: CREATE_DISCOUNT_FAIL });
    dispatch(
      setAlert(
        "Başarısız",
        "Bir şeyler yanlış oldu. Lütfen sonra tekrar deneyiniz",
        "fail"
      )
    );
    console.log(err);
  }
};

export const getSingleDiscount = (id) => async (dispatch) => {
  dispatch({
    type: FETCH_SINGLE_DISCOUNT_START,
  });

  try {
    const res = await axios.get(
      `https://jetd-backend.herokuapp.com/api/v1/localdiscount/${id}`
    );

    dispatch({
      type: FETCH_SINGLE_DISCOUNT_SUCCESS,
      payload: res.data.data,
    });
  } catch (error) {
    dispatch({
      type: FETCH_SINGLE_DISCOUNT_FAIL,
    });
  }
};

export const updateDiscount = (data, id) => async (dispatch) => {
  dispatch({ type: UPDATE_DISCOUNT_START });
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const body = JSON.stringify(data);

    // eslint-disable-next-line
    const res = await axios.post(
      `https://jetd-backend.herokuapp.com/api/v1/localDiscount/update/${id}`,
      body,
      config
    );

    dispatch({
      type: UPDATE_DISCOUNT_SUCCESS,
    });
    dispatch(setAlert("Başarılı", "Kampanya Güncellendi.", "success"));
  } catch (err) {
    dispatch({ type: UPDATE_DISCOUNT_FAIL });
    dispatch(
      setAlert(
        "Başarısız",
        "Bir şeyler yanlış oldu. Lütfen sonra tekrar deneyiniz",
        "fail"
      )
    );
    console.log(err);
  }
};

export const getSingleSeller = (id) => async (dispatch) => {
  dispatch({
    type: GET_SINGLE_SELLER_START,
  });

  try {
    const res = await axios.get(
      `https://jetd-backend.herokuapp.com/api/v1/seller/${id}`
    );

    dispatch({
      type: GET_SINGLE_SELLER_SUCCESS,
      payload: res.data.data,
    });
  } catch (error) {
    dispatch({
      type: GET_SINGLE_SELLER_FAIL,
    });
  }
};

export const updateSeller = (seller, id) => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  dispatch({ type: UPDATE_SELLER_START });

  try {
    const body = JSON.stringify(seller);
    // eslint-disable-next-line
    const res = await axios.post(
      `https://jetd-backend.herokuapp.com/api/v1/seller/editSeller/${id}`,
      body,
      config
    );
    dispatch({
      type: UPDATE_SELLER_SUCCESS,
    });
    dispatch(setAlert("Başarılı", "Satıcı Güncellendi", "success"));
  } catch (error) {
    console.log(error);
    dispatch({
      type: UPDATE_SELLER_FAIL,
    });
    dispatch(
      setAlert(
        "Başarısız",
        "Bir şeyler yanlış oldu. Lütfen sonra tekrar deneyiniz",
        "fail"
      )
    );
  }
};

export const createLogs = (data) => async (dispatch, getState) => {
  dispatch({ type: CREATE_LOGS_START });
  try {
    const PanelUserId = getState().auth.user._id;
    const newData = { ...data, PanelUserId };

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const body = JSON.stringify(newData);
    // eslint-disable-next-line
    const res = await axios.post(
      "https://jetd-backend.herokuapp.com/api/v1/Logs",
      body,
      config
    );

    dispatch({
      type: CREATE_LOGS_SUCCESS,
    });
    dispatch(
      setAlert("Başarılı", "Log başarılı bir şekilde eklendi", "success")
    );
  } catch (err) {
    dispatch({ type: CREATE_LOGS_FAIL });
    dispatch(
      setAlert(
        "Başarısız",
        "Bir şeyler yanlış oldu. Lütfen sonra tekrar deneyiniz",
        "fail"
      )
    );
    console.log(err);
  }
};

export const createCity = (data) => async (dispatch) => {
  dispatch({ type: CREATE_CITY_START });
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const body = JSON.stringify(data);
    // eslint-disable-next-line
    const res = await axios.post(
      "https://jetd-backend.herokuapp.com/api/v1/city",
      body,
      config
    );

    dispatch({
      type: CREATE_CITY_SUCCESS,
    });
    dispatch(setAlert("Başarılı", "Şehir Oluşturuldu.", "success"));
  } catch (err) {
    dispatch({ type: CREATE_CITY_FAIL });
    dispatch(
      setAlert(
        "Başarısız",
        "Bir şeyler yanlış oldu. Lütfen sonra tekrar deneyiniz",
        "fail"
      )
    );
    console.log(err);
  }
};

export const fetchCities = () => async (dispatch) => {
  dispatch({
    type: FETCH_CITIES_START,
  });

  try {
    const res = await axios.get(
      `https://jetd-backend.herokuapp.com/api/v1/city`
    );

    dispatch({
      type: FETCH_CITIES_SUCCESS,
      payload: res.data.data,
    });
  } catch (error) {
    dispatch({
      type: FETCH_CITIES_FAIL,
    });
  }
};

export const fetchStatistics = () => async (dispatch) => {
  dispatch({
    type: FETCH_STATISTICS_START,
  });
  try {
    const res = await axios.get(
      `https://jetd-backend.herokuapp.com/api/v1/controlPanel`
    );

    dispatch({
      type: FETCH_STATISTICS_SUCCESS,
      payload: res.data.data,
    });
  } catch (error) {
    dispatch({
      type: FETCH_STATISTICS_FAIL,
    });
  }
};

export const fetchCity = (id) => async (dispatch) => {
  dispatch({
    type: FETCH_CITY_START,
  });

  try {
    const res = await axios.get(
      `https://jetd-backend.herokuapp.com/api/v1/city/${id}`
    );

    dispatch({
      type: FETCH_CITY_SUCCESS,
      payload: res.data.data,
    });
  } catch (error) {
    dispatch({
      type: FETCH_CITY_FAIL,
    });
  }
};

export const updateCity = (data, id) => async (dispatch) => {
  dispatch({ type: UPDATE_CITY_START });
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const body = JSON.stringify(data);
    // eslint-disable-next-line
    const res = await axios.post(
      `https://jetd-backend.herokuapp.com/api/v1/city/${id}`,
      body,
      config
    );

    dispatch({
      type: UPDATE_CITY_SUCCESS,
    });
    dispatch(setAlert("Başarılı", "Şehir Güncellendi.", "success"));
  } catch (err) {
    dispatch({ type: UPDATE_CITY_FAIL });
    dispatch(
      setAlert(
        "Başarısız",
        "Bir şeyler yanlış oldu. Lütfen sonra tekrar deneyiniz",
        "fail"
      )
    );
    console.log(err);
  }
};

export const createCategory = (data) => async (dispatch) => {
  dispatch({ type: CREATE_CATEGORY_START });
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const body = JSON.stringify(data);
    // eslint-disable-next-line
    const res = await axios.post(
      "https://jetd-backend.herokuapp.com/api/v1/categories",
      body,
      config
    );

    dispatch({
      type: CREATE_CATEGORY_SUCCESS,
    });
    dispatch(setAlert("Başarılı", "Kategori Eklenmiştir.", "success"));
  } catch (err) {
    dispatch({ type: CREATE_CATEGORY_FAIL });
    dispatch(
      setAlert(
        "Başarısız",
        "Bir şeyler yanlış oldu. Lütfen sonra tekrar deneyiniz",
        "fail"
      )
    );
    console.log(err);
  }
};

export const fetchCategories = () => async (dispatch) => {
  dispatch({
    type: FETCH_CATEGORIES_START,
  });

  try {
    const res = await axios.get(
      `https://jetd-backend.herokuapp.com/api/v1/categories`
    );

    dispatch({
      type: FETCH_CATEGORIES_SUCCESS,
      payload: res.data.data,
    });
  } catch (error) {
    dispatch({
      type: FETCH_CATEGORIES_FAIL,
    });
  }
};

export const fetchCategory = (id) => async (dispatch) => {
  dispatch({
    type: FETCH_CATEGORY_START,
  });

  try {
    const res = await axios.get(
      `https://jetd-backend.herokuapp.com/api/v1/categories/${id}`
    );

    dispatch({
      type: FETCH_CATEGORY_SUCCESS,
      payload: res.data.data,
    });
  } catch (error) {
    dispatch({
      type: FETCH_CATEGORY_FAIL,
    });
  }
};

export const updateCategory = (data, id) => async (dispatch) => {
  dispatch({ type: UPDATE_CATEGORY_START });
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const body = JSON.stringify(data);
    // eslint-disable-next-line
    const res = await axios.post(
      `https://jetd-backend.herokuapp.com/api/v1/categories/${id}`,
      body,
      config
    );

    dispatch({
      type: UPDATE_CATEGORY_SUCCESS,
    });
    dispatch(setAlert("Başarılı", "Kategori Güncellendi.", "success"));
  } catch (err) {
    dispatch({ type: UPDATE_CATEGORY_FAIL });
    dispatch(
      setAlert(
        "Başarısız",
        "Bir şeyler yanlış oldu. Lütfen sonra tekrar deneyiniz",
        "fail"
      )
    );
    console.log(err);
  }
};

export const createSlider = (data) => async (dispatch) => {
  dispatch({ type: CREATE_SLIDER_START });
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const body = JSON.stringify(data);
    // eslint-disable-next-line
    const res = await axios.post(
      "https://jetd-backend.herokuapp.com/api/v1/sliders",
      body,
      config
    );

    dispatch({
      type: CREATE_SLIDER_SUCCESS,
    });
    dispatch(setAlert("Başarılı", "Slider Eklendi.", "success"));
  } catch (err) {
    dispatch({ type: CREATE_SLIDER_FAIL });
    dispatch(
      setAlert(
        "Başarısız",
        "Bir şeyler yanlış oldu. Lütfen sonra tekrar deneyiniz",
        "fail"
      )
    );
    console.log(err);
  }
};

export const fetchSliders = () => async (dispatch) => {
  dispatch({
    type: FETCH_SLIDERS_START,
  });

  try {
    const res = await axios.get(
      `https://jetd-backend.herokuapp.com/api/v1/sliders`
    );

    dispatch({
      type: FETCH_SLIDERS_SUCCESS,
      payload: res.data.data,
    });
  } catch (error) {
    dispatch({
      type: FETCH_SLIDERS_FAIL,
    });
  }
};

export const fetchSlider = (id) => async (dispatch) => {
  dispatch({
    type: FETCH_SLIDER_START,
  });

  try {
    const res = await axios.get(
      `https://jetd-backend.herokuapp.com/api/v1/sliders/${id}`
    );

    dispatch({
      type: FETCH_SLIDER_SUCCESS,
      payload: res.data.data,
    });
  } catch (error) {
    dispatch({
      type: FETCH_SLIDER_FAIL,
    });
  }
};

export const updateSlider = (data, id) => async (dispatch) => {
  dispatch({ type: UPDATE_SLIDER_START });
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const body = JSON.stringify(data);
    // eslint-disable-next-line
    const res = await axios.post(
      `https://jetd-backend.herokuapp.com/api/v1/sliders/${id}`,
      body,
      config
    );

    dispatch({
      type: UPDATE_SLIDER_SUCCESS,
    });
    dispatch(setAlert("Başarılı", " Silder Güncellendi.", "success"));
  } catch (err) {
    dispatch({ type: UPDATE_SLIDER_FAIL });
    dispatch(
      setAlert(
        "Başarısız",
        "Bir şeyler yanlış oldu. Lütfen sonra tekrar deneyiniz",
        "fail"
      )
    );
    console.log(err);
  }
};

export const deleteSlider = (id) => async (dispatch) => {
  dispatch({
    type: DELETE_SLIDER_START,
  });

  try {
    // eslint-disable-next-line
    const res = await axios.delete(
      `https://jetd-backend.herokuapp.com/api/v1/sliders/${id}`
    );

    dispatch({
      type: DELETE_SLIDER_SUCCESS,
    });
    dispatch(setAlert("Başarılı", "Slider silinmiştir.", "success"));
    dispatch(fetchSliders());
  } catch (error) {
    dispatch({
      type: DELETE_SLIDER_FAIL,
    });
    dispatch(
      setAlert(
        "Başarısız",
        "Bir şeyler yanlış oldu. Lütfen sonra tekrar deneyiniz",
        "fail"
      )
    );
  }
};

export const fetchSale = () => async (dispatch) => {
  dispatch({
    type: FETCH_SALE_START,
  });

  try {
    const res = await axios.get(
      `https://jetd-backend.herokuapp.com/api/v1/sales/panel/getSaleForPanel?skip=0`
    );

    dispatch({
      type: FETCH_SALE_SUCCESS,
      payload: res.data.data,
    });
  } catch (error) {
    dispatch({
      type: FETCH_SALE_FAIL,
    });
  }
};

export const fetchInvoices = (date) => async (dispatch) => {
  dispatch({
    type: FETCH_INVOICES_START,
  });

  try {
    const { startDate, endDate } = date;

    var result = `https://jetd-backend.herokuapp.com/api/v1/sales/panel/getInvoiceDetailsForPanel?s=${startDate}&e=${endDate}`;
    console.log("result", result);

    const res = await axios.get(
      `https://jetd-backend.herokuapp.com/api/v1/sales/panel/getInvoiceDetailsForPanel?s=${startDate}&e=${endDate}`
    );
    console.log(res.data.data);
    dispatch({
      type: FETCH_INVOICES_SUCCESS,
      payload: res.data.data,
    });
  } catch (error) {
    dispatch({
      type: FETCH_INVOICES_FAIL,
    });
  }
};

export const fetchNextSale = () => async (dispatch, getState) => {
  const sale = getState().auth.sale;

  const listLoading = getState().auth.listLoading;
  if (listLoading) return;

  dispatch({
    type: GET_SALE_START,
  });

  try {
    const res = await axios.get(
      `https://jetd-backend.herokuapp.com/api/v1/sales/panel/getSaleForPanel?skip=${sale.length}`
    );
    dispatch({
      type: GET_SALE_SUCCESS,
      payload: res.data.data,
    });
  } catch (error) {
    dispatch({
      type: GET_SALE_FAIL,
    });
  }
};

export const fetchCompletedAddMoneyRequest = () => async (dispatch) => {
  dispatch({
    type: FETCH_COMPLETE_ADD_MONEY_REQUEST_START,
  });

  try {
    const res = await axios.get(
      `https://jetd-backend.herokuapp.com/api/v1/addMoneyRequest/panel/getCompletedMoneyRequestForPanel?skip=0`
    );

    dispatch({
      type: FETCH_COMPLETE_ADD_MONEY_REQUEST_SUCCESS,
      payload: res.data.data,
    });
  } catch (error) {
    dispatch({
      type: FETCH_COMPLETE_ADD_MONEY_REQUEST_FAIL,
    });
  }
};

export const fetchNextCompletedAddMoneyRequest =
  () => async (dispatch, getState) => {
    const CompletedAddMoneyRequest = getState().auth.CompletedAddMoneyRequest;

    const listLoading = getState().auth.listLoading;
    if (listLoading) return;

    dispatch({
      type: GET_COMPLETE_ADD_MONEY_REQUEST_START,
    });

    try {
      const res = await axios.get(
        `https://jetd-backend.herokuapp.com/api/v1/addMoneyRequest/getCompletedMoneyRequestForPanel?skip=${CompletedAddMoneyRequest.length}`
      );
      dispatch({
        type: GET_COMPLETE_ADD_MONEY_REQUEST_SUCCESS,
        payload: res.data.data,
      });
    } catch (error) {
      dispatch({
        type: GET_COMPLETE_ADD_MONEY_REQUEST_FAIL,
      });
    }
  };

export const searchSale = (data) => async (dispatch) => {
  dispatch({
    type: SEARCH_SALE_START,
  });

  try {
    const res = await axios.get(
      `https://jetd.herokuapp.com/api/v1/sales/search/${data}`
    );
    dispatch({
      type: SEARCH_SALE_SUCCESS,
      payload: res.data.data,
    });
  } catch (error) {
    dispatch({
      type: SEARCH_SALE_FAIL,
    });
  }
};

export const sendInvoice = (data) => async (dispatch) => {
  dispatch({
    type: CREATE_INVOICE_START,
  });
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const body = JSON.stringify({
      seller: data.seller,
      jetdProfitAmount: data.jetdProfitAmount,
    });

    const res = await axios.post(
      "https://jetd-backend.herokuapp.com/api/v1/sales/sendInvoice",
      body,
      config
    );
    console.log(res);
    dispatch({
      type: CREATE_INVOICE_SUCCESS,
    });

    dispatch(setAlert("Başarılı", "Fatura Kesilmiştir.", "success"));
  } catch (error) {
    dispatch({
      type: CREATE_INVOICE_FAIL,
    });
  }
};

export const fetchWithdraws = () => async (dispatch) => {
  dispatch({
    type: FETCH_WITHDRAW_START,
  });

  try {
    const res = await axios.get(
      `https://jetd-backend.herokuapp.com/api/v1/withDrawalRequest/panel/pending`
    );

    dispatch({
      type: FETCH_WITHDRAW_SUCCESS,
      payload: res.data.data,
    });
  } catch (error) {
    dispatch({
      type: FETCH_WITHDRAW_FAIL,
    });
  }
};

export const acceptWirhdraw = (id) => async (dispatch) => {
  dispatch({
    type: ACCEPT_WITHDRAW_START,
  });

  try {
    // eslint-disable-next-line
    const res = await axios.post(
      `https://jetd-backend.herokuapp.com/api/v1/withDrawalRequest/accept/${id}`
    );

    dispatch({
      type: ACCEPT_WITHDRAW_SUCCESS,
    });
    dispatch(setAlert("Başarılı", "İşlem Onaylandı.", "success"));
    dispatch(fetchWithdraws());
  } catch (error) {
    dispatch({
      type: ACCEPT_WITHDRAW_FAIL,
    });
    dispatch(setAlert("Hata", "Hata.", "fail"));
  }
};

export const rejectWirhdraw = (id) => async (dispatch) => {
  dispatch({
    type: REJECT_WITHDRAW_START,
  });

  try {
    // eslint-disable-next-line
    const res = await axios.post(
      `https://jetd-backend.herokuapp.com/api/v1/withDrawalRequest/denied/${id}`
    );

    dispatch({
      type: REJECT_WITHDRAW_SUCCESS,
    });
    dispatch(setAlert("Başarılı", "İşlem Reddedildi..", "success"));
    dispatch(fetchWithdraws());
  } catch (error) {
    dispatch({
      type: REJECT_WITHDRAW_FAIL,
    });
    dispatch(setAlert("Hata", "Hata.", "fail"));
  }
};

export const fetchCompletedWithdraws = () => async (dispatch) => {
  dispatch({
    type: FETCH_COMPLETED_WITHDRAW_START,
  });

  try {
    const res = await axios.get(
      `https://jetd-backend.herokuapp.com/api/v1/withDrawalRequest/panel/`
    );

    dispatch({
      type: FETCH_COMPLETED_WITHDRAW_SUCCESS,
      payload: res.data.data,
    });
  } catch (error) {
    dispatch({
      type: FETCH_COMPLETED_WITHDRAW_FAIL,
    });
  }
};

export const fetchAddMoneyReq = () => async (dispatch) => {
  dispatch({
    type: FETCH_ADDMONEY_REQ_START,
  });

  try {
    const res = await axios.get(
      `https://jetd-backend.herokuapp.com/api/v1/addMoneyRequest/panel/pending`
    );

    dispatch({
      type: FETCH_ADDMONEY_REQ_SUCCESS,
      payload: res.data.data,
    });
  } catch (error) {
    dispatch({
      type: FETCH_ADDMONEY_REQ_FAIL,
    });
  }
};

export const acceptAddMoneyReq = (id) => async (dispatch) => {
  dispatch({
    type: ACCEPT_ADD_MONEY_REQUEST_START,
  });

  try {
    // eslint-disable-next-line
    const res = await axios.post(
      `https://jetd-backend.herokuapp.com/api/v1/addMoneyRequest/accept/${id}`
    );

    dispatch({
      type: ACCEPT_ADD_MONEY_REQUEST_SUCCESS,
    });
    dispatch(setAlert("Başarılı", "İşlem Onaylandı.", "success"));
    dispatch(fetchAddMoneyReq());
  } catch (error) {
    dispatch({
      type: ACCEPT_ADD_MONEY_REQUEST_FAIL,
    });
    dispatch(setAlert("Hata", "Hata.", "fail"));
  }
};

export const rejectAddMoneyReq = (id) => async (dispatch) => {
  dispatch({
    type: REJECT_ADD_MONEY_REQUEST_START,
  });

  try {
    // eslint-disable-next-line
    const res = await axios.post(
      `https://jetd-backend.herokuapp.com/api/v1/addMoneyRequest/reject/${id}`
    );

    dispatch({
      type: REJECT_ADD_MONEY_REQUEST_SUCCESS,
    });
    dispatch(
      setAlert("Başarılı", "İşlem başarılı bir şekilde reddedildi.", "success")
    );
    dispatch(fetchAddMoneyReq());
  } catch (error) {
    dispatch({
      type: REJECT_ADD_MONEY_REQUEST_FAIL,
    });
    dispatch(setAlert("Hata", "Hata.", "fail"));
  }
};

export const createNotfication = (data) => async (dispatch) => {
  dispatch({
    type: CREATE_NOTFICATION_START,
  });

  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const body = JSON.stringify(data);
    // eslint-disable-next-line
    const res = await axios.post(
      `https://jetd-backend.herokuapp.com/api/v1/notification`,

      body,
      config
    );

    dispatch({
      type: CREATE_NOTFICATION_SUCCESS,
    });

    dispatch(setAlert("Başarılı", "Bildirim Oluşturuldu.", "success"));
  } catch (error) {
    dispatch({
      type: CREATE_NOTFICATION_FAIL,
    });
  }
};

export const createAdmin = (data) => async (dispatch) => {
  dispatch({ type: CREATE_ADMIN_START });
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const body = JSON.stringify(data);

    // eslint-disable-next-line
    const res = await axios.post(
      "https://jetd-backend.herokuapp.com/api/v1/admin",
      body,
      config
    );

    dispatch({
      type: CREATE_ADMIN_SUCCESS,
    });
    dispatch(
      setAlert("Başarılı", "Admin başarılı bir şekilde eklendi", "success")
    );
  } catch (err) {
    dispatch({ type: CREATE_ADMIN_FAIL });
    dispatch(
      setAlert(
        "Başarısız",
        "Bir şeyler yanlış oldu. Lütfen sonra tekrar deneyiniz",
        "fail"
      )
    );
    console.log(err);
  }
};

export const getSingleBuyer = (id) => async (dispatch) => {
  dispatch({
    type: GET_SINGLE_BUYER_START,
  });

  try {
    const res = await axios.get(
      `https://jetd-backend.herokuapp.com/api/v1/auth/user/${id}`
    );

    dispatch({
      type: GET_SINGLE_BUYER_SUCCESS,
      payload: res.data.data,
    });
  } catch (error) {
    dispatch({
      type: GET_SINGLE_BUYER_FAIL,
    });
  }
};

export const updateBuyer = (buyer, id) => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  dispatch({ type: UPDATE_BUYER_START });

  try {
    const body = JSON.stringify(buyer);
    // eslint-disable-next-line
    const res = await axios.post(
      `https://jetd-backend.herokuapp.com/api/v1/auth/user/${id}`,
      body,
      config
    );
    dispatch({
      type: UPDATE_BUYER_SUCCESS,
    });
    dispatch(setAlert("Başarılı", "Alıcı Güncellendi", "success"));
  } catch (error) {
    console.log(error);
    dispatch({
      type: UPDATE_BUYER_FAIL,
    });
    dispatch(
      setAlert(
        "Başarısız",
        "Bir şeyler yanlış oldu. Lütfen sonra tekrar deneyiniz",
        "fail"
      )
    );
  }
};

export const setBuyerSector = (data) => async (dispatch) => {
  dispatch({ type: SET_BUYER_SECTOR, payload: data });
  dispatch(fetchFilterBuyer());
};

export const setBuyerCity = (data) => async (dispatch) => {
  dispatch({ type: SET_BUYER_CITY, payload: data });
  dispatch(fetchFilterBuyer());
};

export const setBuyerOrder = (data) => async (dispatch) => {
  dispatch({ type: SET_ORDER_BUYER, payload: data });
  dispatch(fetchFilterBuyer());
};

export const setAdvanced = (data) => async (dispatch) => {
  dispatch({ type: SET_ADVANCED_BUYER, payload: data });
  dispatch(fetchAdvanced());
};

export const fetchFilterBuyer = () => async (dispatch, getState) => {
  const listLoading = getState().auth.listLoading;
  const buyerSector = getState().auth.buyerSector;
  const buyerCity = getState().auth.buyerCity;
  const orderBuyer = getState().auth.orderBuyer;

  if (listLoading) return;

  const advancedOptions = getState().auth.advancedOptions;

  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  dispatch({
    type: FILTER_BUYER_START,
  });
  try {
    const data = { advancedOptions };
    const isOrder = Object.keys(orderBuyer).length !== 0;
    if (isOrder) {
      const order = orderBuyer[Object.keys(orderBuyer)[0]];
      const value = Object.keys(orderBuyer)[0];
      const orderType = order === "ascend" ? "-" : "+";
      data.sort = `${orderType}${value}`;
    }

    if (buyerSector.length !== 0) {
      data.sector = buyerSector;
    }
    if (buyerCity.length !== 0) {
      data.city = buyerCity;
    }
    const body = JSON.stringify(data);
    const res = await axios.post(
      `https://jetd-backend.herokuapp.com/api/v1/auth/?skip=0`,
      body,
      config
    );
    dispatch({
      type: FILTER_BUYER_SUCCESS,
      payload: res.data.data,
    });
  } catch (error) {
    dispatch({
      type: FILTER_BUYER_FAIL,
    });
  }
};

export const getBuyerInfo = (id) => async (dispatch) => {
  dispatch({
    type: GET_BUYER_INFO_START,
  });

  try {
    const res = await axios.get(
      `https://jetd-backend.herokuapp.com/api/v1/auth/userInfo/${id}`
    );

    dispatch({
      type: GET_BUYER_INFO_SUCCESS,
      payload: res.data.data,
    });
  } catch (error) {
    dispatch({
      type: GET_BUYER_INFO_FAIL,
    });
  }
};

export const updateBuyerEmail = (data, id) => async (dispatch, getState) => {
  const oldEmail = getState().auth.singleBuyer.email;

  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  dispatch({ type: CHANGE_BUYER_EMAIL_START });

  try {
    data.oldEmail = oldEmail;
    const body = JSON.stringify(data);
    // eslint-disable-next-line
    const res = await axios.post(
      `https://jetd-backend.herokuapp.com/api/v1/auth/userEmail/${id}`,
      body,
      config
    );
    dispatch({
      type: CHANGE_BUYER_EMAIL_SUCCESS,
    });
    dispatch(setAlert("Başarılı", "Alıcı Email Güncellendi", "success"));
  } catch (error) {
    console.log(error);
    dispatch({
      type: CHANGE_BUYER_EMAIL_FAIL,
    });
    dispatch(
      setAlert(
        "Başarısız",
        "Bir şeyler yanlış oldu. Lütfen sonra tekrar deneyiniz",
        "fail"
      )
    );
  }
};

export const updateBuyerPassword = (data, id) => async (dispatch, getState) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  dispatch({ type: CHANGE_BUYER_PASSWORD_START });

  try {
    const body = JSON.stringify(data);
    // eslint-disable-next-line
    const res = await axios.post(
      `https://jetd-backend.herokuapp.com/api/v1/auth/userPassword/${id}`,
      body,
      config
    );
    dispatch({
      type: CHANGE_BUYER_PASSWORD_SUCCESS,
    });
    dispatch(setAlert("Başarılı", "Alıcı Password Güncellendi", "success"));
  } catch (error) {
    console.log(error);
    dispatch({
      type: CHANGE_BUYER_PASSWORD_FAIL,
    });
    dispatch(
      setAlert(
        "Başarısız",
        "Bir şeyler yanlış oldu. Lütfen sonra tekrar deneyiniz",
        "fail"
      )
    );
  }
};

export const getSellerInfo = (id) => async (dispatch) => {
  dispatch({
    type: GET_SELLER_INFO_START,
  });

  try {
    const res = await axios.get(
      `https://jetd-backend.herokuapp.com/api/v1/seller/sellerInfo/${id}`
    );

    dispatch({
      type: GET_SELLER_INFO_SUCCESS,
      payload: res.data.data,
    });
  } catch (error) {
    dispatch({
      type: GET_SELLER_INFO_FAIL,
    });
  }
};

export const updateSellerPassword =
  (data, id) => async (dispatch, getState) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    dispatch({ type: CHANGE_BUYER_PASSWORD_START });

    try {
      const body = JSON.stringify(data);
      // eslint-disable-next-line
      const res = await axios.post(
        `https://jetd-backend.herokuapp.com/api/v1/seller/userPassword/${id}`,
        body,
        config
      );
      dispatch({
        type: CHANGE_BUYER_PASSWORD_SUCCESS,
      });
      dispatch(setAlert("Başarılı", "Alıcı Password Güncellendi", "success"));
    } catch (error) {
      console.log(error);
      dispatch({
        type: CHANGE_BUYER_PASSWORD_FAIL,
      });
      dispatch(
        setAlert(
          "Başarısız",
          "Bir şeyler yanlış oldu. Lütfen sonra tekrar deneyiniz",
          "fail"
        )
      );
    }
  };

export const updateSellerEmail = (data, id) => async (dispatch, getState) => {
  const oldEmail = getState().auth.singleSeller.companyEmail;

  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  dispatch({ type: CHANGE_BUYER_EMAIL_START });

  try {
    data.oldEmail = oldEmail;
    const body = JSON.stringify(data);
    // eslint-disable-next-line
    const res = await axios.post(
      `https://jetd-backend.herokuapp.com/api/v1/seller/sellerEmail/${id}`,
      body,
      config
    );
    dispatch({
      type: CHANGE_BUYER_EMAIL_SUCCESS,
    });
    dispatch(setAlert("Başarılı", "Alıcı Email Güncellendi", "success"));
  } catch (error) {
    console.log(error);
    dispatch({
      type: CHANGE_BUYER_EMAIL_FAIL,
    });
    dispatch(
      setAlert(
        "Başarısız",
        "Bir şeyler yanlış oldu. Lütfen sonra tekrar deneyiniz",
        "fail"
      )
    );
  }
};

export const fetchAdvanced = () => async (dispatch, getState) => {
  dispatch({
    type: SEARCH_ADVANCED_BUYER_START,
  });

  const advancedOptions = getState().auth.advancedOptions;

  const data = { advancedOptions };

  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    // `https://jetd-backend.herokuapp.com/api/v1/auth/?skip=0`
    const body = JSON.stringify(data);
    const res = await axios.post(
      `https://jetd-backend.herokuapp.com/api/v1/auth/?skip=0`,
      body,
      config
    );
    dispatch({
      type: SEARCH_ADVANCED_BUYER_SUCCESS,
      payload: res.data.data,
    });
  } catch (error) {
    dispatch({
      type: SEARCH_ADVANCED_BUYER_FAIL,
    });
  }
};

export const createUserNotfication = (data) => async (dispatch) => {
  dispatch({
    type: CREATE_NOTFICATION_START,
  });

  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const body = JSON.stringify(data);
    // eslint-disable-next-line
    const res = await axios.post(
      `https://jetd-backend.herokuapp.com/api/v1/notification/users`,
      body,
      config
    );

    dispatch({
      type: CREATE_NOTFICATION_SUCCESS,
    });

    dispatch(setAlert("Başarılı", "Bildirim Oluşturuldu.", "success"));
  } catch (error) {
    dispatch({
      type: CREATE_NOTFICATION_FAIL,
    });
  }
};

export const fetchUsersStatistics = () => async (dispatch) => {
  dispatch({
    type: FETCH_USERS_STATISTICS_START,
  });
  try {
    const res = await axios.get(
      `https://jetd-backend.herokuapp.com/api/v1/controlPanel/usersStatistics`
    );

    dispatch({
      type: FETCH_USERS_STATISTICS_SUCCESS,
      payload: res.data.data,
    });
  } catch (error) {
    dispatch({
      type: FETCH_USERS_STATISTICS_FAIL,
    });
  }
};

export const fetchSalesStatistics = () => async (dispatch) => {
  dispatch({
    type: FETCH_SALES_STATISTICS_START,
  });
  try {
    const res = await axios.get(
      `https://jetd-backend.herokuapp.com/api/v1/controlPanel/salesStatistics`
    );

    dispatch({
      type: FETCH_SALES_STATISTICS_SUCCESS,
      payload: res.data.data,
    });
  } catch (error) {
    dispatch({
      type: FETCH_SALES_STATISTICS_FAIL,
    });
  }
};

export const fetchMonthlyStatistics = () => async (dispatch) => {
  dispatch({
    type: FETCH_MONTHLY_STATISTICS_START,
  });
  try {
    const res = await axios.get(
      `https://jetd.herokuapp.com/api/v1/controlPanel/monthlyStatistics`
    );

    dispatch({
      type: FETCH_MONTHLY_STATISTICS_SUCCESS,
      payload: res.data.data,
    });
  } catch (error) {
    dispatch({
      type: FETCH_MONTHLY_STATISTICS_FAIL,
    });
  }
};

export const fetchWeeklyStatistics = () => async (dispatch) => {
  dispatch({
    type: FETCH_MONTHLY_STATISTICS_START,
  });
  try {
    const res = await axios.get(
      `https://jetd-backend.herokuapp.com/api/v1/controlPanel/weeklyStatistics`
    );

    dispatch({
      type: FETCH_MONTHLY_STATISTICS_SUCCESS,
      payload: res.data.data,
    });
  } catch (error) {
    dispatch({
      type: FETCH_MONTHLY_STATISTICS_FAIL,
    });
  }
};

export const fetchDailyStatistics = () => async (dispatch) => {
  dispatch({
    type: FETCH_MONTHLY_STATISTICS_START,
  });
  try {
    const res = await axios.get(
      `https://jetd-backend.herokuapp.com/api/v1/controlPanel/dailyStatistics`
    );

    dispatch({
      type: FETCH_MONTHLY_STATISTICS_SUCCESS,
      payload: res.data.data,
    });
  } catch (error) {
    dispatch({
      type: FETCH_MONTHLY_STATISTICS_FAIL,
    });
  }
};

export const fetchMonthStatistics = (date) => async (dispatch) => {
  dispatch({
    type: FETCH_MONTHLY_STATISTICS_START,
  });
  try {
    const res = await axios.get(
      `https://jetd.herokuapp.com/api/v1/controlPanel/monthStatistics/${date}`
    );

    dispatch({
      type: FETCH_MONTHLY_STATISTICS_SUCCESS,
      payload: res.data.data,
    });
  } catch (error) {
    dispatch({
      type: FETCH_MONTHLY_STATISTICS_FAIL,
    });
  }
};
