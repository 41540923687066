import React from 'react';

// antd - components
import { AlignRightOutlined, AlignLeftOutlined } from '@ant-design/icons';

// Redux
import { useSelector, useDispatch } from 'react-redux';

// Redux - actions
import { setNav } from '../../redux/actions/navbarPanel';

const NavIcon = ({ styles }) => {
  const dispatch = useDispatch();

  const isNavbarPanelOpen = useSelector(
    (state) => state.navbarPanel.isNavbarPanelOpen
  );

  const navIconHandler = () => {
    dispatch(setNav(!isNavbarPanelOpen));
  };
  return (
    <div onClick={navIconHandler}>
      <>
        {isNavbarPanelOpen ? (
          <AlignRightOutlined className={styles} />
        ) : (
          <AlignLeftOutlined className={styles} />
        )}
      </>
    </div>
  );
};

export default NavIcon;
