import React, { useEffect, useCallback } from "react";

// Container
import PagerContainer from "../../layouts/PageContainer/PageContainer";

// Antd - Componets

import { Spin } from "antd";

// Icons
import { LoadingOutlined } from "@ant-design/icons";

// Hooks

import { useDispatch, useSelector } from "react-redux";

// Styles
import "./Admin.scss";

// @Redxu
import { fetchAdmins } from "../../redux/actions/auth";
import SingleAdmin from "../../components/SingleAdmin/SingleAdmin";

const antIcon = <LoadingOutlined style={{ fontSize: 128 }} spin />;

const Admin = () => {
  const dispatch = useDispatch();

  const loading = useSelector((state) => state.auth.loading);
  const admins = useSelector((state) => state.auth.admins);

  const getBuyer = useCallback(async () => {
    dispatch(fetchAdmins());
  }, [dispatch]);

  useEffect(() => {
    getBuyer();
  }, [getBuyer]);

  return (
    <PagerContainer>
      {loading ? (
        <Spin indicator={antIcon} className="spinner" />
      ) : (
        <>
          <div className="seller-title">
            <h1>Panel Kullanıcıları</h1>
          </div>

          <div className="seller-container">
            {admins.length !== 0 ? (
              <>
                {admins.map((b) => (
                  <SingleAdmin key={b._id} {...b} />
                ))}
              </>
            ) : (
              <h1>Admins bulunamadı </h1>
            )}
          </div>
        </>
      )}
    </PagerContainer>
  );
};

export default Admin;
