import React from "react";

// Icons
import FeatherIcon from "feather-icons-react";

// Redux + Hooks
import { useDispatch } from "react-redux";

// Redux - Actions
import { logout } from "../../redux/actions/auth";

const LogoutButton = ({ styles }) => {
  const dispatch = useDispatch();
  const logOutHandler = () => {
    dispatch(logout());
  };
  return (
    <button onClick={logOutHandler} style={styles}>
      <FeatherIcon icon="log-out" width="1.5rem" /> <span> çıkış yap</span>
    </button>
  );
};

export default LogoutButton;
