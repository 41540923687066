// Styles
import "./Alerts.scss";

// Redux
import { useSelector } from "react-redux";

// Components
import Alert from "../Alert/Alert";

const Alerts = () => {
  const alerts = useSelector((state) => state.alerts);
  return (
    <>
      {alerts.length > 0 && (
        <ul className="alertsContainer">
          {alerts.map((alert) => (
            <Alert key={alert.id} {...alert} />
          ))}
        </ul>
      )}
    </>
  );
};

export default Alerts;
