import React from "react";

// @Hooks
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

// Antd - Componets
import { Button } from "antd";

// @Redux
import { fetchCategory } from "../../redux/actions/auth";

// @styles
import "./SingleCategory.scss";

const SingleCategory = ({ value, _id, icon, sortOrderNumber }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const onLink = async () => {
    await dispatch(fetchCategory(_id));
    history.push(`/update-category`);
  };
  return (
    <div className="single-category-container">
      <h1>{value}</h1>
      <h3>Sıra No : { sortOrderNumber }</h3>
      {icon && <img src={icon} alt="category Icon" />}
      <Button type="primary" className="discount-title-link" onClick={onLink}>
        <span>Düzenle</span>
      </Button>
    </div>
  );
};

export default SingleCategory;
