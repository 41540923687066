import React, { useState } from "react";
import { useHistory } from "react-router-dom";

// Container
import PagerContainer from "../../layouts/PageContainer/PageContainer";

// Antd - Componets
import { Button, DatePicker, Spin } from "antd";

// Icons
import { LoadingOutlined } from "@ant-design/icons";
import FeatherIconsReact from "feather-icons-react";

// Hooks
import { useDispatch, useSelector } from "react-redux";

// @Redux Actions
import { fetchMonthStatistics } from "../../redux/actions/auth";

const antIcon = <LoadingOutlined style={{ fontSize: 128 }} spin />;

const PickMonth = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const loading = useSelector((state) => state.auth.loading);

  const [date, setDate] = useState();

  const onLink = () => {
    history.push(`/admins`);
  };

  const onChange = (date, dateString) => {
    console.log(date, dateString);
    setDate(dateString);
  };

  const onSubmit = () => {
    dispatch(fetchMonthStatistics(date));
    history.push(`/month`);
  };

  return (
    <PagerContainer>
      {loading ? (
        <Spin indicator={antIcon} className="spinner" />
      ) : (
        <>
          <div className="add-discount-container">
            <div className="discount-title">
              <h1>Monthly</h1>
              <div>
                <Button
                  type="primary"
                  className="discount-title-link"
                  onClick={onLink}
                >
                  <FeatherIconsReact icon="plus" width="1.5rem" />
                  <span>Geri Dön</span>
                </Button>
              </div>
            </div>

            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                marginTop: "2rem",
                width: "100%",
                backgroundColor: "#fff",
                padding: "1rem",
                borderRadius: "0.5rem",
                boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
              }}
            >
              <DatePicker onChange={onChange} picker="month" />
              <Button type="primary" className="form-button" onClick={onSubmit}>
                Kaydet
              </Button>
            </div>
          </div>
        </>
      )}
    </PagerContainer>
  );
};

export default PickMonth;
