import React from "react";

// Styles
import "./Navbar.scss";

// components -antd
import { Avatar, Dropdown } from "antd";

// Icons
import FeatherIcon from "feather-icons-react";

// components
import Logo from "../../components/Logo/Logo";
import NavIcon from "../../components/NavIcon/NavIcon";
import DropdownAvatar from "../../components/DropdownAvatar/DropdownAvatar";

const Navbar = () => {
  return (
    <>
      <nav className="navbarContainer">
        <NavIcon styles="navIcon" />
        <Logo styles="Logo" />
        <Dropdown overlay={DropdownAvatar} placement="bottomLeft" arrow>
          <Avatar size={40}>
            <FeatherIcon icon="settings" className="icon" size={20} />
          </Avatar>
        </Dropdown>
      </nav>
    </>
  );
};

export default Navbar;
