import React from "react";

// Container
import PagerContainer from "../../layouts/PageContainer/PageContainer";

// Components
import Infinite from "react-infinite-scroll-component";

// Antd - Componets
import { Spin, DatePicker } from "antd";

// Icons
import { LoadingOutlined } from "@ant-design/icons";

// Hook
import { useDispatch, useSelector } from "react-redux";

// Styles
import "./Invoices.scss";

// @Redxu
import { fetchInvoices } from "../../redux/actions/auth";
import SingleInvoices from "../../components/SingleInvoices/SingleInvoices.jsx";

// @Utils
import moment from "moment";
import "moment/locale/tr";
import locale from "antd/es/date-picker/locale/tr_TR";
//moment.locale("tr");

const antIcon = <LoadingOutlined style={{ fontSize: 128 }} spin />;
const antIcon2 = <LoadingOutlined style={{ fontSize: 64 }} spin />;

const { RangePicker } = DatePicker;
const Invoices = () => {
  const dispatch = useDispatch();

  const loading = useSelector((state) => state.auth.loading);
  const Invoices = useSelector((state) => state.auth.Invoices);
  const listLoading = useSelector((state) => state.auth.listLoading);

  const changeHandeler = (value) => {
    const startDate = moment(value[0]).format("YYYY/MM/DD");
    const endDate = moment(value[1]).format("YYYY/MM/DD");
    dispatch(fetchInvoices({ startDate, endDate }));
  };

  return (
    <PagerContainer>
      {loading ? (
        <Spin indicator={antIcon} className="spinner" />
      ) : (
        <>
          <div className="discount-title">
            <h1>Faturalar</h1>
          </div>
          <div className="date-container">
            <RangePicker
              placeholder={["Başlangıç Tarihi", "Bitiş Tarihi"]}
              locale={locale}
              onChange={changeHandeler}
            />
          </div>
          <Infinite
            endMessage={
              <p style={{ textAlign: "center" }}>
                <b>Yay! You have seen it all</b>
              </p>
            }
            loader={
              listLoading && <Spin indicator={antIcon2} className="spinner" />
            }
            hasMore={true}
            // next={() => {
            //     dispatch(fetchNextSale())
            // }}
            dataLength={Invoices.length}
            scrollThreshold={0}
            style={{ overflow: "hidden" }}
          >
            <div className="discount-container">
              {Invoices.length !== 0 ? (
                <>
                  {Invoices.map((b) => (
                    <SingleInvoices key={b._id} {...b} />
                  ))}
                </>
              ) : (
                <h1>_____</h1>
              )}
            </div>
          </Infinite>
        </>
      )}
    </PagerContainer>
  );
};

export default Invoices;
