import React from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

// Container
import PagerContainer from "../../layouts/PageContainer/PageContainer";

// Antd - Componets
import { Button, Form, Input, Spin } from "antd";

// Icons
import { LoadingOutlined } from "@ant-design/icons";
import FeatherIconsReact from "feather-icons-react";

// @redux
import { updateSellerPassword, createLogs } from "../../redux/actions/auth";

const antIcon = <LoadingOutlined style={{ fontSize: 128 }} spin />;

const EditSellerPassword = () => {
  const history = useHistory();
  const loading = useSelector((state) => state.auth.loading);
  const singleSeller = useSelector((state) => state.auth.singleSeller);
  const adminName = useSelector((state) => state.auth.user.displayName);
  const dispatch = useDispatch();

  const [form] = Form.useForm();

  const onLink = () => {
    history.push("/seller");
  };

  const handleSubmit = async (value) => {
    await dispatch(updateSellerPassword(value, singleSeller._id));
    let log = {
      ProcessName: "Change seller password",
      ProcessDescription: `(${adminName}) ${singleSeller.companyName} şifresi değiştirildi. `,
      SellerId: singleSeller._id,
      UserId: null,
      Request: JSON.stringify(value),
    };
    dispatch(createLogs(log));
    history.push("/seller");
  };

  return (
    <PagerContainer>
      {loading ? (
        <Spin indicator={antIcon} className="spinner" />
      ) : (
        <>
          <div className="add-discount-container">
            <div className="discount-title">
              <h1>Satıcı Şifre Düzenle</h1>
              <div>
                <Button
                  type="primary"
                  className="discount-title-link"
                  onClick={onLink}
                >
                  <FeatherIconsReact icon="plus" width="1.5rem" />
                  <span>Geri Dön</span>
                </Button>
              </div>
            </div>
            <div className="form-container">
              <Form
                layout="vertical"
                onFinish={handleSubmit}
                className="discount-add-form"
                form={form}
                name="editBuyer"
              >
                <Form.Item
                  rules={[
                    {
                      message: "Lütfen Yeni Şifrenizi giriniz!",
                      required: true,
                    },
                  ]}
                  name="password"
                  label="Şifre"
                >
                  <Input.Password />
                </Form.Item>

                <Form.Item
                  dependencies={["password"]}
                  rules={[
                    {
                      required: true,
                      message: "Lütfen Şifre Tekrarı giriniz!",
                    },
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (!value || getFieldValue("password") === value) {
                          return Promise.resolve();
                        }
                        return Promise.reject(
                          new Error("Girdiğiniz şifreler eşleşmiyor!")
                        );
                      },
                    }),
                  ]}
                  name="confirmPassword"
                  label="Şifre Tekrar"
                >
                  <Input.Password />
                </Form.Item>

                <Form.Item>
                  <Button
                    className="btn-add-q"
                    htmlType="submit"
                    type="primary"
                    size="large"
                    disabled={loading}
                  >
                    {loading ? "Yükleniyor..." : "Kaydet"}
                  </Button>
                </Form.Item>
              </Form>
            </div>
          </div>
        </>
      )}
    </PagerContainer>
  );
};

export default EditSellerPassword;
