// @ Navbar Panel
export const SET_NAV_PANLE = "SET_NAV_PANLE";
export const SET_URL_PATH = "SET_URL_PATH";

// @ Alerts
export const SET_ALERT = "SET_ALERT";
export const REMOVE_ALERT = "REMOVE_ALERT";

// @ Auth
export const LOGIN_START = "LOGIN_START";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGOUT = "LOGOUT";
export const LOAD_USER_START = "LOAD_USER_START";
export const USER_LOADED = "USER_LOADED";
export const AUTH_ERROR = "AUTH_ERROR";
export const CHANGE_PASSWORD_START = "CHANGE_PASSWORD_START";
export const CHANGE_PASSWORD_SUCCESS = "CHANGE_PASSWORD_SUCCESS";
export const CHANGE_PASSWORD_FAIL = "CHANGE_PASSWORD_FAIL";
export const FETCH_DROPDWON_INFO_START = "FETCH_DROPDWON_INFO_START";
export const FETCH_DROPDWON_INFO_SUCCESS = "FETCH_DROPDWON_INFO_SUCCESS";

//@Buyers
export const FETCH_BUYER_START = "FETCH_BUYER_START";
export const FETCH_BUYER_SUCCESS = "FETCH_BUYER_SUCCESS";
export const FETCH_BUYER_FAIL = "FETCH_BUYER_FAIL";
export const GET_BUYER_START = "GET_BUYER_START";
export const GET_BUYER_SUCCESS = "GET_BUYER_SUCCESS";
export const GET_BUYER_FAIL = "GET_BUYER_FAIL";
export const SEARCH_BUYER_START = "SEARCH_BUYER_START";
export const SEARCH_BUYER_SUCCESS = "SEARCH_BUYER_SUCCESS";
export const SEARCH_BUYER_FAIL = "SEARCH_BUYER_FAIL";
export const GET_SINGLE_BUYER_START = "GET_SINGLE_BUYER_START";
export const GET_SINGLE_BUYER_SUCCESS = "GET_SINGLE_BUYER_SUCCESS";
export const GET_SINGLE_BUYER_FAIL = "GET_SINGLE_BUYER_FAIL";
export const UPDATE_BUYER_START = "UPDATE_BUYER_START";
export const UPDATE_BUYER_SUCCESS = "UPDATE_BUYER_SUCCESS";
export const UPDATE_BUYER_FAIL = "UPDATE_BUYER_FAIL";
export const SET_BUYER_SECTOR = "SET_BUYER_SECTOR";
export const SET_BUYER_CITY = "SET_BUYER_CITY";
export const SET_ORDER_BUYER = "SET_ORDER_BUYER";
export const FILTER_BUYER_START = "FILTER_BUYER_START";
export const FILTER_BUYER_SUCCESS = "FILTER_BUYER_SUCCESS";
export const FILTER_BUYER_FAIL = "FILTER_BUYER_FAIL";
export const GET_BUYER_INFO_START = "GET_BUYER_INFO_START";
export const GET_BUYER_INFO_SUCCESS = "GET_BUYER_INFO_SUCCESS";
export const GET_BUYER_INFO_FAIL = "GET_BUYER_INFO_FAIL";
export const CHANGE_BUYER_EMAIL_START = "CHANGE_BUYER_EMAIL_START";
export const CHANGE_BUYER_EMAIL_SUCCESS = "CHANGE_BUYER_EMAIL_SUCCESS";
export const CHANGE_BUYER_EMAIL_FAIL = "CHANGE_BUYER_EMAIL_FAIL";
export const CHANGE_BUYER_PASSWORD_START = "CHANGE_BUYER_PASSWORD_START";
export const CHANGE_BUYER_PASSWORD_SUCCESS = "CHANGE_BUYER_PASSWORD_SUCCESS";
export const CHANGE_BUYER_PASSWORD_FAIL = "CHANGE_BUYER_PASSWORD_FAIL";
export const SEARCH_ADVANCED_BUYER_START = "SEARCH_ADVANCED_BUYER_START";
export const SEARCH_ADVANCED_BUYER_SUCCESS = "SEARCH_ADVANCED_BUYER_SUCCESS";
export const SEARCH_ADVANCED_BUYER_FAIL = "SEARCH_ADVANCED_BUYER_FAIL";
export const SET_ADVANCED_BUYER = "SET_ADVANCED_BUYER";
// @Sellers
export const FETCH_SELLER_START = "FETCH_SELLER_START";
export const FETCH_SELLER_SUCCESS = "FETCH_SELLER_SUCCESS";
export const FETCH_SELLER_FAIL = "FETCH_SELLER_FAIL";
export const GET_SELLER_START = "GET_SELLER_START";
export const GET_SELLER_SUCCESS = "GET_SELLER_SUCCESS";
export const GET_SELLER_FAIL = "GET_SELLER_FAIL";
export const SEARCH_SELLER_START = "SEARCH_SELLER_START";
export const SEARCH_SELLER_SUCCESS = "SEARCH_SELLER_SUCCESS";
export const SEARCH_SELLER_FAIL = "SEARCH_SELLER_FAIL";
export const GET_SINGLE_SELLER_START = "GET_SINGLE_SELLER_START";
export const GET_SINGLE_SELLER_SUCCESS = "GET_SINGLE_SELLER_SUCCESS";
export const GET_SINGLE_SELLER_FAIL = "GET_SINGLE_SELLER_FAIL";
export const UPDATE_SELLER_START = "UPDATE_SELLER_START";
export const UPDATE_SELLER_SUCCESS = "UPDATE_SELLER_SUCCESS";
export const UPDATE_SELLER_FAIL = "UPDATE_SELLER_FAIL";
export const GET_SELLER_INFO_START = "GET_SELLER_INFO_START";
export const GET_SELLER_INFO_SUCCESS = "GET_SELLER_INFO_SUCCESS";
export const GET_SELLER_INFO_FAIL = "GET_SELLER_INFO_FAIL";

// @ Admins
export const FETCH_ADMINS_START = "FETCH_ADMINS_START";
export const FETCH_ADMINS_SUCCESS = "FETCH_ADMINS_SUCCESS";
export const FETCH_ADMINS_FAIL = "FETCH_ADMINS_FAIL";
export const CREATE_ADMIN_START = "CREATE_ADMIN_START";
export const CREATE_ADMIN_SUCCESS = "CREATE_ADMIN_SUCCESS";
export const CREATE_ADMIN_FAIL = "CREATE_ADMIN_FAIL";
// @Discount
export const FETCH_DISCOUNT_START = "FETCH_DISCOUNT_START";
export const FETCH_DISCOUNT_SUCCESS = "FETCH_DISCOUNT_SUCCESS";
export const FETCH_DISCOUNT_FAIL = "FETCH_DISCOUNT_FAIL";
export const GET_DISCOUNT_START = "GET_DISCOUNT_START";
export const GET_DISCOUNT_SUCCESS = "GET_DISCOUNT_SUCCESS";
export const GET_DISCOUNT_FAIL = "GET_DISCOUNT_FAIL";
export const SEARCH_DISCOUNT_START = "SEARCH_DISCOUNT_START";
export const SEARCH_DISCOUNT_SUCCESS = "SEARCH_DISCOUNT_SUCCESS";
export const SEARCH_DISCOUNT_FAIL = "SEARCH_DISCOUNT_FAIL";
export const CREATE_DISCOUNT_START = "CREATE_DISCOUNT_START";
export const CREATE_DISCOUNT_SUCCESS = "CREATE_DISCOUNT_SUCCESS";
export const CREATE_DISCOUNT_FAIL = "CREATE_DISCOUNT_FAIL";
export const CREATE_LOGS_START = "CREATE_LOGS_START";
export const CREATE_LOGS_SUCCESS = "CREATE_LOGS_SUCCESS";
export const CREATE_LOGS_FAIL = "CREATE_LOGS_FAIL";
export const FETCH_SINGLE_DISCOUNT_START = "FETCH_SINGLE_DISCOUNT_START";
export const FETCH_SINGLE_DISCOUNT_SUCCESS = "FETCH_SINGLE_DISCOUNT_SUCCESS";
export const FETCH_SINGLE_DISCOUNT_FAIL = "FETCH_SINGLE_DISCOUNT_FAIL";
export const UPDATE_DISCOUNT_START = "UPDATE_DISCOUNT_START";
export const UPDATE_DISCOUNT_SUCCESS = "UPDATE_DISCOUNT_SUCCESS";
export const UPDATE_DISCOUNT_FAIL = "UPDATE_DISCOUNT_FAIL";

// @ Cities
export const FETCH_CITIES_START = "FETCH_CITIES_START";
export const FETCH_CITIES_SUCCESS = "FETCH_CITIES_SUCCESS";
export const FETCH_CITIES_FAIL = "FETCH_CITIES_FAIL";
export const CREATE_CITY_START = "CREATE_CITY_START";
export const CREATE_CITY_SUCCESS = "CREATE_CITY_SUCCESS";
export const CREATE_CITY_FAIL = "CREATE_CITY_FAIL";
export const FETCH_CITY_START = "FETCH_CITY_START";
export const FETCH_CITY_SUCCESS = "FETCH_CITY_SUCCESS";
export const FETCH_CITY_FAIL = "FETCH_CITY_FAIL";
export const UPDATE_CITY_START = "UPDATE_CITY_START";
export const UPDATE_CITY_SUCCESS = "UPDATE_CITY_SUCCESS";
export const UPDATE_CITY_FAIL = "UPDATE_CITY_FAIL";

// @ Categories
export const FETCH_CATEGORIES_START = "FETCH_CATEGORIES_START";
export const FETCH_CATEGORIES_SUCCESS = "FETCH_CATEGORIES_SUCCESS";
export const FETCH_CATEGORIES_FAIL = "FETCH_CATEGORIES_FAIL";
export const CREATE_CATEGORY_START = "CREATE_CATEGORY_START";
export const CREATE_CATEGORY_SUCCESS = "CREATE_CATEGORY_SUCCESS";
export const CREATE_CATEGORY_FAIL = "CREATE_CATEGORY_FAIL";
export const FETCH_CATEGORY_START = "FETCH_CATEGORY_START";
export const FETCH_CATEGORY_SUCCESS = "FETCH_CATEGORY_SUCCESS";
export const FETCH_CATEGORY_FAIL = "FETCH_CATEGORY_FAIL";
export const UPDATE_CATEGORY_START = "UPDATE_CATEGORY_START";
export const UPDATE_CATEGORY_SUCCESS = "UPDATE_CATEGORY_SUCCESS";
export const UPDATE_CATEGORY_FAIL = "UPDATE_CATEGORY_FAIL";

// @ Sliders
export const FETCH_SLIDERS_START = "FETCH_SLIDERS_START";
export const FETCH_SLIDERS_SUCCESS = "FETCH_SLIDERS_SUCCESS";
export const FETCH_SLIDERS_FAIL = "FETCH_SLIDERS_FAIL";
export const CREATE_SLIDER_START = "CREATE_SLIDER_START";
export const CREATE_SLIDER_SUCCESS = "CREATE_SLIDER_SUCCESS";
export const CREATE_SLIDER_FAIL = "CREATE_SLIDER_FAIL";
export const FETCH_SLIDER_START = "FETCH_SLIDER_START";
export const FETCH_SLIDER_SUCCESS = "FETCH_SLIDER_SUCCESS";
export const FETCH_SLIDER_FAIL = "FETCH_SLIDER_FAIL";
export const UPDATE_SLIDER_START = "UPDATE_SLIDER_START";
export const UPDATE_SLIDER_SUCCESS = "UPDATE_SLIDER_SUCCESS";
export const UPDATE_SLIDER_FAIL = "UPDATE_SLIDER_FAIL";
export const DELETE_SLIDER_START = "DELETE_SLIDER_START";
export const DELETE_SLIDER_SUCCESS = "DELETE_SLIDER_SUCCESS";
export const DELETE_SLIDER_FAIL = "DELETE_SLIDER_FAIL";

// @Statistics
export const FETCH_STATISTICS_START = "FETCH_STATISTICS_START";
export const FETCH_STATISTICS_SUCCESS = "FETCH_STATISTICS_SUCCESS";
export const FETCH_STATISTICS_FAIL = "FETCH_STATISTICS_FAIL";
export const FETCH_USERS_STATISTICS_START = "FETCH_USERS_STATISTICS_START";
export const FETCH_USERS_STATISTICS_SUCCESS = "FETCH_USERS_STATISTICS_SUCCESS";
export const FETCH_USERS_STATISTICS_FAIL = "FETCH_USERS_STATISTICS_FAIL";
export const FETCH_SALES_STATISTICS_START = "FETCH_SALES_STATISTICS_START";
export const FETCH_SALES_STATISTICS_SUCCESS = "FETCH_SALES_STATISTICS_SUCCESS";
export const FETCH_SALES_STATISTICS_FAIL = "FETCH_SALES_STATISTICS_FAIL";
export const FETCH_MONTHLY_STATISTICS_START = "FETCH_MONTHLY_STATISTICS_START";
export const FETCH_MONTHLY_STATISTICS_SUCCESS =
  "FETCH_MONTHLY_STATISTICS_SUCCESS";
export const FETCH_MONTHLY_STATISTICS_FAIL = "FETCH_MONTHLY_STATISTICS_FAIL";
export const FETCH_WEEKLY_STATISTICS_START = "FETCH_WEEKLY_STATISTICS_START";
export const FETCH_WEEKLY_STATISTICS_SUCCESS =
  "FETCH_WEEKLY_STATISTICS_SUCCESS";
export const FETCH_WEEKLY_STATISTICS_FAIL = "FETCH_WEEKLY_STATISTICS_FAIL";

export const FETCH_DAILY_STATISTICS_START = "FETCH_DAILY_STATISTICS_START";
export const FETCH_DAILY_STATISTICS_SUCCESS = "FETCH_DAILY_STATISTICS_SUCCESS";
export const FETCH_DAILY_STATISTICS_FAIL = "FETCH_DAILY_STATISTICS_FAIL";

// Sale
export const FETCH_SALE_START = "FETCH_SALE_START";
export const FETCH_SALE_SUCCESS = "FETCH_SALE_SUCCESS";
export const FETCH_SALE_FAIL = "FETCH_SALE_FAIL";
export const GET_SALE_START = "GET_SALE_START";
export const GET_SALE_SUCCESS = "GET_SALE_SUCCESS";
export const GET_SALE_FAIL = "GET_SALE_FAIL";
export const SEARCH_SALE_START = "SEARCH_SALE_START";
export const SEARCH_SALE_SUCCESS = "SEARCH_SALE_SUCCESS";
export const SEARCH_SALE_FAIL = "SEARCH_SALE_FAIL";
export const SET_BUTTON_LOADING = "SET_BUTTON_LOADING";
export const CANCEL_BUTTON_LOADING = "CANCEL_BUTTON_LOADING";

// Add money request
export const FETCH_COMPLETE_ADD_MONEY_REQUEST_START =
  "FETCH_COMPLETE_ADD_MONEY_REQUEST_START";
export const FETCH_COMPLETE_ADD_MONEY_REQUEST_SUCCESS =
  "FETCH_COMPLETE_ADD_MONEY_REQUEST_SUCCESS";
export const FETCH_COMPLETE_ADD_MONEY_REQUEST_FAIL =
  "FETCH_COMPLETE_ADD_MONEY_REQUEST_FAIL";
export const GET_COMPLETE_ADD_MONEY_REQUEST_START =
  "GET_COMPLETE_ADD_MONEY_REQUEST_START";
export const GET_COMPLETE_ADD_MONEY_REQUEST_SUCCESS =
  "GET_COMPLETE_ADD_MONEY_REQUEST_SUCCESS";
export const GET_COMPLETE_ADD_MONEY_REQUEST_FAIL =
  "GET_COMPLETE_ADD_MONEY_REQUEST_FAIL";
export const FETCH_ADDMONEY_REQ_START = "FETCH_ADDMONEY_REQ_START";
export const FETCH_ADDMONEY_REQ_SUCCESS = "FETCH_ADDMONEY_REQ_SUCCESS";
export const FETCH_ADDMONEY_REQ_FAIL = "FETCH_ADDMONEY_REQ_FAIL";
export const ACCEPT_ADD_MONEY_REQUEST_START = "ACCEPT_ADD_MONEY_REQUEST_START";
export const ACCEPT_ADD_MONEY_REQUEST_SUCCESS =
  "ACCEPT_ADD_MONEY_REQUEST_SUCCESS";
export const ACCEPT_ADD_MONEY_REQUEST_FAIL = "ACCEPT_ADD_MONEY_REQUEST_FAIL";
export const REJECT_ADD_MONEY_REQUEST_START = "REJECT_ADD_MONEY_REQUEST_START";
export const REJECT_ADD_MONEY_REQUEST_SUCCESS =
  "REJECT_ADD_MONEY_REQUEST_SUCCESS";
export const REJECT_ADD_MONEY_REQUEST_FAIL = "REJECT_ADD_MONEY_REQUEST_FAIL";

// Invoices
export const FETCH_INVOICES_START = "FETCH_INVOICES_START";
export const FETCH_INVOICES_SUCCESS = "FETCH_INVOICES_SUCCESS";
export const FETCH_INVOICES_FAIL = "FETCH_INVOICES_FAIL";

export const CREATE_INVOICE_START = "CREATE_INVOICE_START";
export const CREATE_INVOICE_SUCCESS = "CREATE_INVOICE_SUCCESS";
export const CREATE_INVOICE_FAIL = "CREATE_INVOICE_FAIL";

// @ Withdraw
export const FETCH_WITHDRAW_START = "FETCH_WITHDRAW_START";
export const FETCH_WITHDRAW_SUCCESS = "FETCH_WITHDRAW_SUCCESS";
export const FETCH_WITHDRAW_FAIL = "FETCH_WITHDRAW_FAIL";
export const ACCEPT_WITHDRAW_START = "ACCEPT_WITHDRAW_START";
export const ACCEPT_WITHDRAW_SUCCESS = "ACCEPT_WITHDRAW_SUCCESS";
export const ACCEPT_WITHDRAW_FAIL = "ACCEPT_WITHDRAW_FAIL";
export const REJECT_WITHDRAW_START = "REJECT_WITHDRAW_START";
export const REJECT_WITHDRAW_SUCCESS = "REJECT_WITHDRAW_SUCCESS";
export const REJECT_WITHDRAW_FAIL = "REJECT_WITHDRAW_FAIL";
export const FETCH_COMPLETED_WITHDRAW_START = "FETCH_COMPLETED_WITHDRAW_START";
export const FETCH_COMPLETED_WITHDRAW_SUCCESS =
  "FETCH_COMPLETED_WITHDRAW_SUCCESS";
export const FETCH_COMPLETED_WITHDRAW_FAIL = "FETCH_COMPLETED_WITHDRAW_FAIL";

// @ Notfications
export const CREATE_NOTFICATION_START = "CREATE_NOTFICATION_START";
export const CREATE_NOTFICATION_SUCCESS = "CREATE_NOTFICATION_SUCCESS";
export const CREATE_NOTFICATION_FAIL = "CREATE_NOTFICATION_FAIL";
