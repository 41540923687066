import React from "react";

// Components
import PageContainer from "../../layouts/PageContainer/PageContainer";
import FirstSaleReport from "../../components/FirstSaleReport/FirstSaleReport";
import BuyerReport from "../../components/BuyerReport/BuyerReport";

// Styles
import "./Reports.scss";

const Reports = () => {
  return (
    <PageContainer>
      <h1 className="report-title">Raporlar</h1>
      <div className="first-report-container">
        <h1>İlk satış raporları</h1>
        <FirstSaleReport />
      </div>
      <div className="second-report-container">
        <h1>Alıcılar raporları</h1>
        <BuyerReport />
      </div>
    </PageContainer>
  );
};

export default Reports;
