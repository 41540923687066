import React, { useEffect, useCallback } from "react";

// Container
import PagerContainer from "../../layouts/PageContainer/PageContainer";

// Components
import Infinite from "react-infinite-scroll-component";

// Antd - Componets
import { Spin } from "antd";

// Icons
import { LoadingOutlined } from "@ant-design/icons";

// Hook
import { useDispatch, useSelector } from "react-redux";

// Styles
import "./CompletedAddMoneyRequest.scss";

// @Redxu
import {
    fetchCompletedAddMoneyRequest,
    fetchNextCompletedAddMoneyRequest,
} from "../../redux/actions/auth";
import SingleCompletedAddMoneyRequest from "../../components/SingleCompletedAddMoneyRequest/SingleCompletedAddMoneyRequest";

const antIcon = <LoadingOutlined style={{ fontSize: 128 }} spin />;
const antIcon2 = <LoadingOutlined style={{ fontSize: 64 }} spin />;

const CompletedAddMoneyRequest = () => {
    const dispatch = useDispatch();

    const loading = useSelector((state) => state.auth.loading);
    const CompletedAddMoneyRequest= useSelector((state) => state.auth.CompletedAddMoneyRequest);
    const listLoading = useSelector((state) => state.auth.listLoading);

    const getBuyer = useCallback(async () => {
        dispatch(fetchCompletedAddMoneyRequest());
    }, [dispatch]);

    useEffect(() => {
        getBuyer();
    }, [getBuyer]);

    return (
        <PagerContainer>
            {loading ? (
                <Spin indicator={antIcon} className="spinner" />
            ) : (
                <>
                    <div className="discount-title">
                        <h1>Yüklenen Bakiyeler</h1>
                    </div>
                    <Infinite
                        endMessage={
                            <p style={{ textAlign: "center" }}>
                                <b>Yay! You have seen it all</b>
                            </p>
                        }
                        loader={
                            listLoading && <Spin indicator={antIcon2} className="spinner" />
                        }
                        hasMore={true}
                        next={() => {
                            dispatch(fetchNextCompletedAddMoneyRequest())
                        }}
                        dataLength={CompletedAddMoneyRequest.length}
                        scrollThreshold={0}
                        style={{ overflow: "hidden" }}
                    >
                        <div className="discount-container">
                            {CompletedAddMoneyRequest.length !== 0 ? (
                                <>
                                    {CompletedAddMoneyRequest.map((b) => (
                                        <SingleCompletedAddMoneyRequest key={b._id} {...b} />
                                    ))}
                                </>
                            ) : (
                                <h1>Yüklenen Bakiye bulunamadı</h1>
                            )}
                        </div>
                    </Infinite>
                </>
            )}
        </PagerContainer>
    );
};

export default CompletedAddMoneyRequest;