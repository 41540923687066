// Types
import {
  LOGIN_START,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT,
  USER_LOADED,
  AUTH_ERROR,
  LOAD_USER_START,
  CHANGE_PASSWORD_START,
  CHANGE_PASSWORD_SUCCESS,
  CHANGE_PASSWORD_FAIL,
  FETCH_BUYER_START,
  FETCH_BUYER_SUCCESS,
  FETCH_BUYER_FAIL,
  GET_BUYER_START,
  GET_BUYER_SUCCESS,
  GET_BUYER_FAIL,
  SEARCH_BUYER_START,
  SEARCH_BUYER_SUCCESS,
  SEARCH_BUYER_FAIL,
  FETCH_SELLER_START,
  FETCH_SELLER_SUCCESS,
  FETCH_SELLER_FAIL,
  GET_SELLER_START,
  GET_SELLER_SUCCESS,
  GET_SELLER_FAIL,
  SEARCH_SELLER_START,
  SEARCH_SELLER_SUCCESS,
  SEARCH_SELLER_FAIL,
  FETCH_ADMINS_START,
  FETCH_ADMINS_SUCCESS,
  FETCH_ADMINS_FAIL,
  FETCH_DISCOUNT_START,
  FETCH_DISCOUNT_SUCCESS,
  FETCH_DISCOUNT_FAIL,
  GET_DISCOUNT_START,
  GET_DISCOUNT_SUCCESS,
  GET_DISCOUNT_FAIL,
  SEARCH_DISCOUNT_START,
  SEARCH_DISCOUNT_SUCCESS,
  SEARCH_DISCOUNT_FAIL,
  FETCH_SALE_START,
  FETCH_SALE_SUCCESS,
  FETCH_SALE_FAIL,
  FETCH_INVOICES_START,
  FETCH_INVOICES_SUCCESS,
  FETCH_INVOICES_FAIL,
  GET_SALE_START,
  GET_SALE_SUCCESS,
  GET_SALE_FAIL,
  FETCH_COMPLETE_ADD_MONEY_REQUEST_START,
  FETCH_COMPLETE_ADD_MONEY_REQUEST_SUCCESS,
  FETCH_COMPLETE_ADD_MONEY_REQUEST_FAIL,
  GET_COMPLETE_ADD_MONEY_REQUEST_START,
  GET_COMPLETE_ADD_MONEY_REQUEST_SUCCESS,
  GET_COMPLETE_ADD_MONEY_REQUEST_FAIL,
  SEARCH_SALE_START,
  SEARCH_SALE_SUCCESS,
  SEARCH_SALE_FAIL,
  FETCH_DROPDWON_INFO_START,
  FETCH_DROPDWON_INFO_SUCCESS,
  CREATE_DISCOUNT_START,
  CREATE_DISCOUNT_SUCCESS,
  CREATE_DISCOUNT_FAIL,
  CREATE_INVOICE_START,
  CREATE_INVOICE_SUCCESS,
  CREATE_INVOICE_FAIL,
  CREATE_LOGS_START,
  CREATE_LOGS_SUCCESS,
  CREATE_LOGS_FAIL,
  UPDATE_DISCOUNT_START,
  UPDATE_DISCOUNT_SUCCESS,
  UPDATE_DISCOUNT_FAIL,
  FETCH_SINGLE_DISCOUNT_START,
  FETCH_SINGLE_DISCOUNT_SUCCESS,
  FETCH_SINGLE_DISCOUNT_FAIL,
  GET_SINGLE_SELLER_START,
  GET_SINGLE_SELLER_SUCCESS,
  GET_SINGLE_SELLER_FAIL,
  UPDATE_SELLER_START,
  UPDATE_SELLER_SUCCESS,
  UPDATE_SELLER_FAIL,
  CREATE_CITY_START,
  CREATE_CITY_SUCCESS,
  CREATE_CITY_FAIL,
  FETCH_CITIES_START,
  FETCH_CITIES_SUCCESS,
  FETCH_CITIES_FAIL,
  FETCH_STATISTICS_START,
  FETCH_STATISTICS_SUCCESS,
  FETCH_STATISTICS_FAIL,
  FETCH_CITY_START,
  FETCH_CITY_SUCCESS,
  FETCH_CITY_FAIL,
  UPDATE_CITY_START,
  UPDATE_CITY_SUCCESS,
  UPDATE_CITY_FAIL,
  FETCH_CATEGORIES_START,
  FETCH_CATEGORIES_SUCCESS,
  FETCH_CATEGORIES_FAIL,
  CREATE_CATEGORY_START,
  CREATE_CATEGORY_SUCCESS,
  CREATE_CATEGORY_FAIL,
  FETCH_CATEGORY_START,
  FETCH_CATEGORY_SUCCESS,
  FETCH_CATEGORY_FAIL,
  UPDATE_CATEGORY_START,
  UPDATE_CATEGORY_SUCCESS,
  UPDATE_CATEGORY_FAIL,
  FETCH_SLIDERS_START,
  FETCH_SLIDERS_SUCCESS,
  FETCH_SLIDERS_FAIL,
  CREATE_SLIDER_START,
  CREATE_SLIDER_SUCCESS,
  CREATE_SLIDER_FAIL,
  FETCH_SLIDER_START,
  FETCH_SLIDER_SUCCESS,
  FETCH_SLIDER_FAIL,
  UPDATE_SLIDER_START,
  UPDATE_SLIDER_SUCCESS,
  UPDATE_SLIDER_FAIL,
  FETCH_WITHDRAW_START,
  FETCH_WITHDRAW_SUCCESS,
  FETCH_WITHDRAW_FAIL,
  ACCEPT_WITHDRAW_START,
  ACCEPT_WITHDRAW_SUCCESS,
  ACCEPT_WITHDRAW_FAIL,
  REJECT_WITHDRAW_START,
  REJECT_WITHDRAW_SUCCESS,
  REJECT_WITHDRAW_FAIL,
  FETCH_COMPLETED_WITHDRAW_START,
  FETCH_COMPLETED_WITHDRAW_SUCCESS,
  FETCH_COMPLETED_WITHDRAW_FAIL,
  FETCH_ADDMONEY_REQ_START,
  FETCH_ADDMONEY_REQ_SUCCESS,
  FETCH_ADDMONEY_REQ_FAIL,
  ACCEPT_ADD_MONEY_REQUEST_START,
  ACCEPT_ADD_MONEY_REQUEST_SUCCESS,
  ACCEPT_ADD_MONEY_REQUEST_FAIL,
  REJECT_ADD_MONEY_REQUEST_START,
  REJECT_ADD_MONEY_REQUEST_SUCCESS,
  REJECT_ADD_MONEY_REQUEST_FAIL,
  CREATE_NOTFICATION_START,
  CREATE_NOTFICATION_SUCCESS,
  CREATE_NOTFICATION_FAIL,
  DELETE_SLIDER_START,
  DELETE_SLIDER_SUCCESS,
  DELETE_SLIDER_FAIL,
  CREATE_ADMIN_START,
  CREATE_ADMIN_SUCCESS,
  CREATE_ADMIN_FAIL,
  GET_SINGLE_BUYER_START,
  GET_SINGLE_BUYER_SUCCESS,
  GET_SINGLE_BUYER_FAIL,
  UPDATE_BUYER_START,
  UPDATE_BUYER_SUCCESS,
  UPDATE_BUYER_FAIL,
  SET_BUTTON_LOADING,
  CANCEL_BUTTON_LOADING,
  SET_BUYER_SECTOR,
  SET_BUYER_CITY,
  FILTER_BUYER_START,
  FILTER_BUYER_SUCCESS,
  FILTER_BUYER_FAIL,
  SET_ORDER_BUYER,
  GET_BUYER_INFO_START,
  GET_BUYER_INFO_SUCCESS,
  GET_BUYER_INFO_FAIL,
  CHANGE_BUYER_EMAIL_START,
  CHANGE_BUYER_EMAIL_SUCCESS,
  CHANGE_BUYER_EMAIL_FAIL,
  CHANGE_BUYER_PASSWORD_START,
  CHANGE_BUYER_PASSWORD_SUCCESS,
  CHANGE_BUYER_PASSWORD_FAIL,
  GET_SELLER_INFO_START,
  GET_SELLER_INFO_SUCCESS,
  GET_SELLER_INFO_FAIL,
  SET_ADVANCED_BUYER,
  SEARCH_ADVANCED_BUYER_START,
  SEARCH_ADVANCED_BUYER_SUCCESS,
  SEARCH_ADVANCED_BUYER_FAIL,
  FETCH_USERS_STATISTICS_START,
  FETCH_USERS_STATISTICS_SUCCESS,
  FETCH_USERS_STATISTICS_FAIL,
  FETCH_SALES_STATISTICS_START,
  FETCH_SALES_STATISTICS_SUCCESS,
  FETCH_SALES_STATISTICS_FAIL,
  FETCH_MONTHLY_STATISTICS_START,
  FETCH_MONTHLY_STATISTICS_SUCCESS,
  FETCH_MONTHLY_STATISTICS_FAIL,
  FETCH_WEEKLY_STATISTICS_START,
  FETCH_WEEKLY_STATISTICS_SUCCESS,
  FETCH_WEEKLY_STATISTICS_FAIL,
  FETCH_DAILY_STATISTICS_START,
  FETCH_DAILY_STATISTICS_SUCCESS,
  FETCH_DAILY_STATISTICS_FAIL,
} from "../types";

const initialState = {
  token: localStorage.getItem("token"),
  isAuth: null,
  loading: false,
  buttonLoading: false,
  user: null,
  listLoading: false,
  buyer: [],
  buyerSector: [],
  buyerCity: [],
  orderBuyer: {},
  buyerInfo: {},
  advancedOptions: {
    isActive: false,
    isVerify: false,
    isComplated: false,
  },
  sellerInfo: {},
  seller: [],
  admins: [],
  cities: [],
  sliders: [],
  categories: [],
  localdiscount: [],
  sale: [],
  Invoices: [],
  CompletedAddMoneyRequest: [],
  withdraws: [],
  AddMoneyRequest: [],
  discount: {},
  singleSeller: {},
  singleBuyer: {},
  city: {},
  category: {},
  slider: {},
  totalSalesAmount: 0,
  totalMoney: 0,
  totalBonus: 0,
  totalUserBonusEarned: 0,
  totalDiscount: 0,
  totalJetdProfit: 0,
  totalSalesCount: 0,
  totalSalesByCreditCardCount: 0,
  totalSalesByMoneyCount: 0,
  totalSalesByBonusCount: 0,
  totalSalesByMixCount: 0,
  // Users Statistics
  totalBuyer: 0,
  totalCommercialUserCount: 0,
  totalComplatedAccount: 0,
  totalLocalDiscountCount: 0,
  totalPersonalUserCount: 0,
  totalPhoneVerifyUser: 0,
  totalSellerCount: 0,
  totaldeactiveAccount: 0,
  // end Users Statistics

  // Monthly Statistics
  sellersWithMostSalesCount: [],
  sellersWithMostSalesAmount: [],
  usersWithMostSalesCount: [],
  usersWithMostSalesAmount: [],
  bsetJetdProfitForThisMonth: [],
  totalJetdProfitForLastMonth: 0,
  totalJetdProfitForThisMonth: 0,
  totalNewBuyerForThisMonth: 0,
  totalNewBuyerLastMonth: 0,
  totalNewDiscountForThisMonth: 0,
  totalNewDiscountLastMonth: 0,
  totalNewSellerForThisMonth: 0,
  totalNewSellerLastMonth: 0,
  totalSalesAmountBounsForLastMonth: 0,
  totalSalesAmountBounsForThisMonth: 0,
  totalSalesAmountCreditCardForLastMonth: 0,
  totalSalesAmountCreditCardForThisMonth: 0,
  totalSalesAmountForLastMonth: 0,
  totalSalesAmountForThisMonth: 0,
  totalSalesAmountMixForLastMonth: 0,
  totalSalesAmountMixForThisMonth: 0,
  totalSalesAmountMoneyForLastMonth: 0,
  totalSalesAmountMoneyForThisMonth: 0,
  totalSalesCountBounsForLastMonth: 0,
  totalSalesCountBounsForThisMonth: 0,
  totalSalesCountCreditCardForLastMonth: 0,
  totalSalesCountCreditCardForThisMonth: 0,
  totalSalesCountForLastMonth: 0,
  totalSalesCountForThisMonth: 0,
  totalSalesCountMixForLastMonth: 0,
  totalSalesCountMixForThisMonth: 0,
  totalSalesCountMoneyForLastMonth: 0,
  totalSalesCountMoneyForThisMonth: 0,
  // End Of Monthly Statistics

  // Weekly Statistics
  bestSellerForThisWeek: [],
  bestUsersSalesForThisWeek: [],
  bsetJetdProfitForThisWeek: [],
  totalJetdProfitForLastWeek: 0,
  totalJetdProfitForThisWeek: 0,
  totalNewBuyerForThisWeek: 0,
  totalNewBuyerLastWeek: 0,
  totalNewDiscountForThisWeek: 0,
  totalNewDiscountLastWeek: 0,
  totalNewSellerForThisWeek: 0,
  totalNewSellerLastWeek: 0,
  totalSalesAmountBounsForLastWeek: 0,
  totalSalesAmountBounsForThisWeek: 0,
  totalSalesAmountCreditCardForLastWeek: 0,
  totalSalesAmountCreditCardForThisWeek: 0,
  totalSalesAmountForLastWeek: 0,
  totalSalesAmountForThisWeek: 0,
  totalSalesAmountMixForLastWeek: 0,
  totalSalesAmountMixForThisWeek: 0,
  totalSalesAmountMoneyForLastWeek: 0,
  totalSalesAmountMoneyForThisWeek: 0,
  totalSalesCountBounsForLastWeek: 0,
  totalSalesCountBounsForThisWeek: 0,
  totalSalesCountCreditCardForLastWeek: 0,
  totalSalesCountCreditCardForThisWeek: 0,
  totalSalesCountForLastWeek: 0,
  totalSalesCountForThisWeek: 0,
  totalSalesCountMixForLastWeek: 0,
  totalSalesCountMixForThisWeek: 0,
  totalSalesCountMoneyForLastWeek: 0,
  totalSalesCountMoneyForThisWeek: 0,
  // End Of Weekly Statistics

  // DAYLY Statistics
  totalJetdProfitForLastDay: 0,
  totalJetdProfitForThisDay: 0,
  totalNewBuyerForThisDay: 0,
  totalNewBuyerLastDay: 0,
  totalNewDiscountForThisDay: 0,
  totalNewDiscountLastDay: 0,
  totalNewSellerForThisDay: 0,
  totalNewSellerLastDay: 0,
  totalSalesAmountBounsForLastDay: 0,
  totalSalesAmountBounsForThisDay: 0,
  totalSalesAmountCreditCardForLastDay: 0,
  totalSalesAmountCreditCardForThisDay: 0,
  totalSalesAmountForLastDay: 0,
  totalSalesAmountForThisDay: 0,
  totalSalesAmountMixForLastDay: 0,
  totalSalesAmountMixForThisDay: 0,
  totalSalesAmountMoneyForLastDay: 0,
  totalSalesAmountMoneyForThisDay: 0,
  totalSalesCountBounsForLastDay: 0,
  totalSalesCountBounsForThisDay: 0,
  totalSalesCountCreditCardForLastDay: 0,
  totalSalesCountCreditCardForThisDay: 0,
  totalSalesCountForLastDay: 0,
  totalSalesCountForThisDay: 0,
  totalSalesCountMixForLastDay: 0,
  totalSalesCountMixForThisDay: 0,
  totalSalesCountMoneyForLastDay: 0,
  totalSalesCountMoneyForThisDay: 0,

  // End Of DAYLY Statistics

  todaySales: [],
  logs: [],
};

export default function auth(state = initialState, action) {
  const { payload, type } = action;
  switch (type) {
    case LOGIN_START:
    case LOAD_USER_START:
    case CHANGE_PASSWORD_START:
    case FETCH_BUYER_START:
    case SEARCH_BUYER_START:
    case FETCH_SELLER_START:
    case SEARCH_SELLER_START:
    case FETCH_ADMINS_START:
    case FETCH_DISCOUNT_START:
    case FETCH_SALE_START:
    case FETCH_INVOICES_START:
    case FETCH_COMPLETE_ADD_MONEY_REQUEST_START:
    case SEARCH_DISCOUNT_START:
    case SEARCH_SALE_START:
    case FETCH_DROPDWON_INFO_START:
    case CREATE_DISCOUNT_START:
    case FETCH_SINGLE_DISCOUNT_START:
    case UPDATE_DISCOUNT_START:
    case GET_SINGLE_SELLER_START:
    case UPDATE_SELLER_START:
    case CREATE_LOGS_START:
    case CREATE_CITY_START:
    case FETCH_CITIES_START:
    case FETCH_STATISTICS_START:
    case FETCH_CITY_START:
    case UPDATE_CITY_START:
    case FETCH_CATEGORIES_START:
    case CREATE_CATEGORY_START:
    case CREATE_INVOICE_START:
    case FETCH_CATEGORY_START:
    case UPDATE_CATEGORY_START:
    case FETCH_SLIDERS_START:
    case CREATE_SLIDER_START:
    case FETCH_SLIDER_START:
    case UPDATE_SLIDER_START:
    case FETCH_WITHDRAW_START:
    case ACCEPT_WITHDRAW_START:
    case REJECT_WITHDRAW_START:
    case FETCH_COMPLETED_WITHDRAW_START:
    case FETCH_ADDMONEY_REQ_START:
    case ACCEPT_ADD_MONEY_REQUEST_START:
    case REJECT_ADD_MONEY_REQUEST_START:
    case CREATE_NOTFICATION_START:
    case DELETE_SLIDER_START:
    case CREATE_ADMIN_START:
    case GET_SINGLE_BUYER_START:
    case UPDATE_BUYER_START:
    case GET_BUYER_INFO_START:
    case CHANGE_BUYER_EMAIL_START:
    case CHANGE_BUYER_PASSWORD_START:
    case GET_SELLER_INFO_START:
    case SEARCH_ADVANCED_BUYER_START:
    case FETCH_USERS_STATISTICS_START:
    case FETCH_SALES_STATISTICS_START:
    case FETCH_MONTHLY_STATISTICS_START:
    case FETCH_WEEKLY_STATISTICS_START:
    case FETCH_DAILY_STATISTICS_START:
      return {
        ...state,
        loading: true,
      };
    case GET_BUYER_START:
    case GET_SELLER_START:
    case GET_DISCOUNT_START:
    case GET_SALE_START:
    case GET_COMPLETE_ADD_MONEY_REQUEST_START:
    case FILTER_BUYER_START:
      return {
        ...state,
        listLoading: true,
      };
    case LOGIN_SUCCESS:
      localStorage.setItem("token", payload.token);
      return {
        ...state,
        ...payload,
        loading: false,
        isAuth: true,
      };
    case USER_LOADED:
      return {
        ...state,
        loading: false,
        isAuth: true,
        user: payload,
      };
    case LOGIN_FAIL:
    case LOGOUT:
    case AUTH_ERROR:
      localStorage.removeItem("token");
      return {
        isAuth: null,
        loading: false,
        user: null,
      };

    case CHANGE_PASSWORD_SUCCESS:
    case FETCH_BUYER_FAIL:
    case SEARCH_BUYER_FAIL:
    case FETCH_SELLER_FAIL:
    case SEARCH_SELLER_FAIL:
    case FETCH_ADMINS_FAIL:
    case FETCH_DISCOUNT_FAIL:
    case FETCH_SALE_FAIL:
    case FETCH_INVOICES_FAIL:
    case FETCH_COMPLETE_ADD_MONEY_REQUEST_FAIL:
    case SEARCH_DISCOUNT_FAIL:
    case SEARCH_SALE_FAIL:
    case FETCH_DROPDWON_INFO_SUCCESS:
    case CREATE_DISCOUNT_SUCCESS:
    case CREATE_INVOICE_SUCCESS:
    case CREATE_DISCOUNT_FAIL:
    case CREATE_INVOICE_FAIL:
    case FETCH_SINGLE_DISCOUNT_FAIL:
    case UPDATE_DISCOUNT_SUCCESS:
    case UPDATE_DISCOUNT_FAIL:
    case GET_SINGLE_SELLER_FAIL:
    case UPDATE_SELLER_FAIL:
    case UPDATE_SELLER_SUCCESS:
    case CREATE_LOGS_FAIL:
    case CREATE_LOGS_SUCCESS:
    case CREATE_CITY_FAIL:
    case CREATE_CITY_SUCCESS:
    case FETCH_CITIES_FAIL:
    case FETCH_STATISTICS_FAIL:
    case FETCH_CITY_FAIL:
    case UPDATE_CITY_FAIL:
    case UPDATE_CITY_SUCCESS:
    case FETCH_CATEGORIES_FAIL:
    case CREATE_CATEGORY_FAIL:
    case CREATE_CATEGORY_SUCCESS:
    case FETCH_CATEGORY_FAIL:
    case UPDATE_CATEGORY_FAIL:
    case UPDATE_CATEGORY_SUCCESS:
    case FETCH_SLIDERS_FAIL:
    case CREATE_SLIDER_FAIL:
    case CREATE_SLIDER_SUCCESS:
    case FETCH_SLIDER_FAIL:
    case UPDATE_SLIDER_FAIL:
    case UPDATE_SLIDER_SUCCESS:
    case FETCH_WITHDRAW_FAIL:
    case ACCEPT_WITHDRAW_FAIL:
    case REJECT_WITHDRAW_FAIL:
    case ACCEPT_WITHDRAW_SUCCESS:
    case REJECT_WITHDRAW_SUCCESS:
    case FETCH_COMPLETED_WITHDRAW_FAIL:
    case FETCH_ADDMONEY_REQ_FAIL:
    case ACCEPT_ADD_MONEY_REQUEST_FAIL:
    case REJECT_ADD_MONEY_REQUEST_FAIL:
    case ACCEPT_ADD_MONEY_REQUEST_SUCCESS:
    case REJECT_ADD_MONEY_REQUEST_SUCCESS:
    case CREATE_NOTFICATION_FAIL:
    case CREATE_NOTFICATION_SUCCESS:
    case DELETE_SLIDER_FAIL:
    case DELETE_SLIDER_SUCCESS:
    case CREATE_ADMIN_FAIL:
    case CREATE_ADMIN_SUCCESS:
    case GET_SINGLE_BUYER_FAIL:
    case UPDATE_BUYER_FAIL:
    case UPDATE_BUYER_SUCCESS:
    case CHANGE_PASSWORD_FAIL:
    case GET_BUYER_INFO_FAIL:
    case CHANGE_BUYER_EMAIL_FAIL:
    case CHANGE_BUYER_EMAIL_SUCCESS:
    case CHANGE_BUYER_PASSWORD_FAIL:
    case CHANGE_BUYER_PASSWORD_SUCCESS:
    case GET_SELLER_INFO_FAIL:
    case SEARCH_ADVANCED_BUYER_FAIL:
    case FETCH_USERS_STATISTICS_FAIL:
    case FETCH_SALES_STATISTICS_FAIL:
    case FETCH_MONTHLY_STATISTICS_FAIL:
    case FETCH_WEEKLY_STATISTICS_FAIL:
    case FETCH_DAILY_STATISTICS_FAIL:
      return {
        ...state,
        loading: false,
      };

    case FETCH_CITIES_SUCCESS:
      return {
        ...state,
        cities: payload,
        loading: false,
      };

    case FETCH_STATISTICS_SUCCESS:
    case FETCH_USERS_STATISTICS_SUCCESS:
    case FETCH_SALES_STATISTICS_SUCCESS:
    case FETCH_MONTHLY_STATISTICS_SUCCESS:
    case FETCH_WEEKLY_STATISTICS_SUCCESS:
    case FETCH_DAILY_STATISTICS_SUCCESS:
      return {
        ...state,
        ...payload,
        loading: false,
      };

    case FETCH_CITY_SUCCESS:
      return {
        ...state,
        city: payload,
        loading: false,
      };

    case FETCH_CATEGORIES_SUCCESS:
      return {
        ...state,
        categories: payload,
        loading: false,
      };

    case FETCH_CATEGORY_SUCCESS:
      return {
        ...state,
        category: payload,
        loading: false,
      };

    case FETCH_SLIDERS_SUCCESS:
      return {
        ...state,
        sliders: payload,
        loading: false,
      };

    case FETCH_SLIDER_SUCCESS:
      return {
        ...state,
        slider: payload,
        loading: false,
      };

    case FETCH_BUYER_SUCCESS:
    case SEARCH_BUYER_SUCCESS:
    case SEARCH_ADVANCED_BUYER_SUCCESS:
      return {
        ...state,
        buyer: payload,
        loading: false,
      };
    case FETCH_ADMINS_SUCCESS:
      return {
        ...state,
        admins: payload,
        loading: false,
      };

    case FETCH_DISCOUNT_SUCCESS:
    case SEARCH_DISCOUNT_SUCCESS:
      return {
        ...state,
        localdiscount: payload,
        loading: false,
      };

    case FETCH_SALE_SUCCESS:
    case SEARCH_SALE_SUCCESS:
      return {
        ...state,
        sale: payload,
        loading: false,
      };

    case FETCH_INVOICES_SUCCESS:
      return {
        ...state,
        Invoices: payload,
        loading: false,
      };

    case FETCH_COMPLETE_ADD_MONEY_REQUEST_SUCCESS:
      return {
        ...state,
        CompletedAddMoneyRequest: payload,
        loading: false,
      };

    case FETCH_ADDMONEY_REQ_SUCCESS:
      return {
        ...state,
        AddMoneyRequest: payload,
        loading: false,
      };

    case FETCH_WITHDRAW_SUCCESS:
    case FETCH_COMPLETED_WITHDRAW_SUCCESS:
      return {
        ...state,
        withdraws: payload,
        loading: false,
      };

    case FETCH_SELLER_SUCCESS:
    case SEARCH_SELLER_SUCCESS:
      return {
        ...state,
        seller: payload,
        loading: false,
      };
    case GET_BUYER_SUCCESS:
      return {
        ...state,
        buyer: [...state.buyer, ...payload],
        listLoading: false,
      };

    case SET_BUYER_SECTOR:
      return {
        ...state,
        buyerSector: payload,
      };
    case SET_BUYER_CITY:
      return {
        ...state,
        buyerCity: payload,
      };
    case SET_ORDER_BUYER:
      return {
        ...state,
        orderBuyer: payload,
      };

    case FILTER_BUYER_SUCCESS:
      return {
        ...state,
        buyer: payload,
        listLoading: false,
      };
    case GET_BUYER_INFO_SUCCESS:
      return {
        ...state,
        buyerInfo: payload,
        loading: false,
      };

    case GET_SELLER_INFO_SUCCESS:
      return {
        ...state,
        sellerInfo: payload,
        loading: false,
      };
    case GET_SELLER_SUCCESS:
      return {
        ...state,
        seller: [...state.seller, ...payload],
        listLoading: false,
      };

    case GET_DISCOUNT_SUCCESS:
      return {
        ...state,
        localdiscount: [...state.localdiscount, ...payload],
        listLoading: false,
      };

    case GET_SALE_SUCCESS:
      return {
        ...state,
        sale: [...state.sale, ...payload],
        listLoading: false,
      };

    case GET_COMPLETE_ADD_MONEY_REQUEST_SUCCESS:
      return {
        ...state,
        CompletedAddMoneyRequest: [
          ...state.CompletedAddMoneyRequest,
          ...payload,
        ],
        listLoading: false,
      };

    case FETCH_SINGLE_DISCOUNT_SUCCESS:
      return {
        ...state,
        discount: payload,
        loading: false,
      };

    case GET_BUYER_FAIL:
    case GET_SELLER_FAIL:
    case GET_DISCOUNT_FAIL:
    case GET_SALE_FAIL:
    case GET_COMPLETE_ADD_MONEY_REQUEST_FAIL:
    case FILTER_BUYER_FAIL:
      return {
        ...state,
        listLoading: false,
      };

    case SET_ADVANCED_BUYER:
      return {
        ...state,
        advancedOptions: payload,
      };

    case GET_SINGLE_SELLER_SUCCESS:
      return {
        ...state,
        singleSeller: payload,
        loading: false,
      };

    case GET_SINGLE_BUYER_SUCCESS:
      return {
        ...state,
        singleBuyer: payload,
        loading: false,
      };

    case SET_BUTTON_LOADING:
      return {
        ...state,
        buttonLoading: true,
      };
    case CANCEL_BUTTON_LOADING:
      return {
        ...state,
        buttonLoading: false,
      };

    default:
      return state;
  }
}
