import React from "react";

// @assets
import logo from "../../assets/wlogo.svg";

// @Styles
import "./UserImage.scss";

const UserImage = ({ photoURL, isSeller }) => {
  return (
    <div
      className="image-container-buyer"
      style={isSeller && { backgroundColor: "#fb6d3a" }}
    >
      {photoURL === "NOT_SET" || !photoURL ? (
        <img src={logo} alt="jetd logo" className="logo-buyer-container" />
      ) : (
        <img src={photoURL} alt="user avater" className="av-container" />
      )}
    </div>
  );
};

export default UserImage;
