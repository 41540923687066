import React, { useState } from "react";

// Styles
import "./NavPanle.scss";

// Redux
import { useSelector } from "react-redux";

// Components
import AsideLink from "../../components/AsideLink/AsideLink";
import CollapseMenu from "../../components/CollapseMenu/CollapseMenu";
import SPanle from "../../components/SPanle/SPanle";

const NavPanle = () => {
  const [collapse, setCollapse] = useState({
    userOne: false,
    userTwo: false,
    userThree: false,
    userFor: false,
    userFive: false,
  });
  const isNavbarPanelOpen = useSelector(
    (state) => state.navbarPanel.isNavbarPanelOpen
  );

  const user = useSelector((state) => state.auth.user);

  const rule = user?.adminRule ? user.adminRule : "user";

  const collapseHandler = (user) => {
    setCollapse({
      userOne: false,
      userTwo: false,
      userThree: false,
      userFor: false,
      userFive: false,
      ...user,
    });
  };
  const { userOne, userTwo, userThree, userFor, userFive } = collapse;
  return (
    <aside className={`navPanleContainer ${isNavbarPanelOpen ? "open" : null}`}>
      {isNavbarPanelOpen ? (
        <>
          <AsideLink
            icon="pie-chart"
            title="Kontrol Paneli"
            isIcon={true}
            path=""
          />

          {rule === "superAdmin" && (
            <CollapseMenu
              icon="bar-chart-2"
              title="istatistikler"
              kind="userFive"
              collapseHandler={collapseHandler}
              user={userFive}
            >
              <AsideLink
                icon="dollar-sign"
                title="Alıcı / Satıcı İstatistikleri"
                isIcon={false}
                path="users-statistics"
              />
              <AsideLink
                icon="send"
                title="Satış İstatistikleri"
                isIcon={false}
                path="sales-statistics"
              />
              <AsideLink
                icon="send"
                title="Aylık İstatistikler"
                isIcon={false}
                path="monthly-statistics"
              />
              <AsideLink
                icon="send"
                title="Haftalık İstatistikler"
                isIcon={false}
                path="weekly-statistics"
              />
              <AsideLink
                icon="send"
                title="Günlük İstatistik"
                isIcon={false}
                path="daily-statistics"
              />

              <AsideLink
                icon="send"
                title="Monthly"
                isIcon={false}
                path="pick-month"
              />
            </CollapseMenu>
          )}

          {rule === "superAdmin" && (
            <>
              <AsideLink
                icon="clipboard"
                title="Raporlar"
                isIcon={true}
                path="reports"
              />
            </>
          )}

          {rule !== "user" && (
            <AsideLink
              icon="printer"
              title="Faturalar"
              isIcon={true}
              path="invoices"
            />
          )}

          <CollapseMenu
            icon="users"
            title="Kullanıcılar"
            kind="userOne"
            collapseHandler={collapseHandler}
            user={userOne}
          >
            <AsideLink
              icon="home"
              title="Alıcılar"
              isIcon={false}
              path="buyer"
            />
            <AsideLink
              icon="home"
              title="Satıcılar"
              isIcon={false}
              path="seller"
            />

            {rule === "superAdmin" && (
              <>
                <AsideLink
                  icon="home"
                  title="Panel Kullanıcıları"
                  isIcon={false}
                  path="admins"
                />
                <AsideLink
                  icon="home"
                  title="Panel Kullanıcısı Ekle"
                  isIcon={false}
                  path="add-admin"
                />
              </>
            )}
          </CollapseMenu>
          <AsideLink
            icon="gift"
            title="Kampanyalar"
            isIcon={true}
            path="local-discount"
          />
          <AsideLink
            icon="dollar-sign"
            title="Tüm Satışlar"
            isIcon={true}
            path="sales"
          />

          <CollapseMenu
            icon="briefcase"
            title="Bakiye İşlemleri"
            kind="userTwo"
            collapseHandler={collapseHandler}
            user={userTwo}
          >
            <AsideLink
              icon="dollar-sign"
              title="Yüklenen Bakiyeler"
              isIcon={false}
              path="acceptAddMoneyRequest"
            />

            {rule !== "user" && (
              <AsideLink
                icon="send"
                title="Bakiye Talepleri"
                isIcon={false}
                path="addMoney-request"
              />
            )}
          </CollapseMenu>

          <CollapseMenu
            icon="send"
            title="Para Çekme Talepleri"
            kind="userThree"
            collapseHandler={collapseHandler}
            user={userThree}
          >
            <AsideLink
              icon="dollar-sign"
              title="Para Çekme Sonuçları"
              isIcon={false}
              path="withdraws-completed"
            />
            {rule !== "user" && (
              <AsideLink
                icon="send"
                title="Para Çekme Talepleri"
                isIcon={false}
                path="withdraw-request"
              />
            )}
          </CollapseMenu>

          {rule !== "user" && (
            <>
              <AsideLink
                icon="image"
                title="Slider"
                isIcon={true}
                path="sliders"
              />
              <AsideLink
                icon="compass"
                title="Şehirler"
                isIcon={true}
                path="cities"
              />
              <AsideLink
                icon="copy"
                title="Kategoriler"
                isIcon={true}
                path="categories"
              />

              <CollapseMenu
                icon="bell"
                title="Bildirimler"
                kind="userFor"
                collapseHandler={collapseHandler}
                user={userFor}
              >
                <AsideLink
                  icon="dollar-sign"
                  title="şehir bildirimi"
                  isIcon={false}
                  path="notfications"
                />
                <AsideLink
                  icon="send"
                  title="kullanıcı bildirimi"
                  isIcon={false}
                  path="user-notfications"
                />
              </CollapseMenu>
            </>
          )}
        </>
      ) : (
        <>
          <SPanle />
        </>
      )}
    </aside>
  );
};

export default NavPanle;
