import React, { useEffect, useCallback } from 'react';

// Styles
import './SPanleButton.scss';

// Icons
import FeatherIcon from 'feather-icons-react';

// antd-Component
import { Popover } from 'antd';

// Hooks
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

// Redux - Actions
import { setUrlPath } from '../../redux/actions/navbarPanel';

const SPanleButton = ({ icon, content, path }) => {
  const urlPath = useSelector((state) => state.navbarPanel.urlPath);
  const dispatch = useDispatch();
  const history = useHistory();

  const onButtonClick = () => {
    dispatch(setUrlPath(path));
    history.push(`/${path}`);
  };

  const setUrlPathOnFirstLoad = useCallback(() => {
    if (urlPath !== '') return;
    dispatch(setUrlPath(history.location.pathname.replace('/', '')));
  }, [urlPath, dispatch, history.location.pathname]);

  useEffect(() => {
    setUrlPathOnFirstLoad();
  }, [setUrlPathOnFirstLoad]);

  return (
    <Popover placement='rightTop' content={content} trigger='hover'>
      <button
        className={`SPanleButtonContainer ${
          path === urlPath ? 'active' : null
        }`}
        onClick={onButtonClick}
      >
        <FeatherIcon
          icon={icon}
          width='1.5rem'
          className='SPanleButton__icon'
        />
      </button>
    </Popover>
  );
};

export default SPanleButton;
