import React from 'react';

// Icons
import FeatherIcon from 'feather-icons-react';

// Hooks
import { useHistory } from 'react-router-dom';

const ChangePasswordButton = () => {
  const history = useHistory();

  const onButtonClick = () => {
    history.push('/change-password');
  };
  return (
    <button onClick={onButtonClick}>
      <FeatherIcon icon='lock' width='1.5rem' /> <span>şifre değiştir </span>
    </button>
  );
};

export default ChangePasswordButton;
