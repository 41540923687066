import React from "react";

// Styles
import "./ChangePassword.scss";

// Layout
import PageContainer from "../../layouts/PageContainer/PageContainer";

// Antd - Components
import { Form, Input, Button } from "antd";

// Redux - Hooks
import { useSelector, useDispatch } from "react-redux";

// Redux - Actions
import { changePassword } from "../../redux/actions/auth";

const ChangePassword = () => {
  const [form] = Form.useForm();
  const { loading } = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  const handleSubmit = (value) => {
    dispatch(changePassword(value));
    form.resetFields();
  };
  return (
    <PageContainer>
      <div className="changePasswordContainer">
        <h1>şifre değiştir</h1>

        <Form
          name="login"
          form={form}
          layout="vertical"
          onFinish={handleSubmit}
          className="changePasswordForm"
        >
          <Form.Item
            rules={[{ message: "Lütfen mevcut şifrenizi giriniz!", required: true }]}
            name="oldPassword"
            label="Mevcut Şifre"
            className="login-input-group"
          >
            <Input.Password
              placeholder="Mevcut Şifre"
              size="large"
              className="login-input"
            />
          </Form.Item>

          <Form.Item
            rules={[{ message: "Lütfen Yeni Şifrenizi giriniz!", required: true }]}
            name="newPassword"
            label="Yeni Şifre"
            className="login-input-group"
          >
            <Input.Password
              placeholder="Yeni Şifre"
              size="large"
              className="login-input"
            />
          </Form.Item>

          <Form.Item
            dependencies={["newPassword"]}
            rules={[
              {
                required: true,
                message: "Lütfen Şifre Tekrarı giriniz!",
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue("newPassword") === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    new Error("Girdiğiniz şifreler eşleşmiyor!")
                  );
                },
              }),
            ]}
            name="confirmPassword"
            label="Şifre Tekrar"
            className="login-input-group"
          >
            <Input.Password
              placeholder="Şifre Tekrar"
              size="large"
              className="login-input"
            />
          </Form.Item>
          <div className="auth-form-action"></div>
          {/* {error ? <p>{error.message}</p> : null} */}
          <Form.Item>
            <Button
              className="btn-login"
              htmlType="submit"
              type="primary"
              size="large"
              disabled={loading}
            >
              {loading ? "Yükleniyor..." : "Kaydet"}
            </Button>
          </Form.Item>
        </Form>
      </div>
    </PageContainer>
  );
};

export default ChangePassword;
