import React from "react";

// Styles
import "./DropdownAvatar.scss";

// Components
import LogoutButton from "../LogoutButton/LogoutButton";
import ChangePasswordButton from "../ChangePasswordButton/ChangePasswordButton";

const DropdownAvatar = () => {
  return (
    <section className="DropdownAvatarContainer">
      <ChangePasswordButton />
      <LogoutButton />
    </section>
  );
};

export default DropdownAvatar;
