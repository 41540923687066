import React from "react";

// Hooks
import { useSelector, useDispatch } from "react-redux";
import { Redirect, useHistory } from "react-router-dom";

// Container
import PagerContainer from "../../layouts/PageContainer/PageContainer";

// Antd - Componets
import { Button, Tabs } from "antd";
import SellerInfoTab from "../../components/SellerInfoTab/SellerInfoTab";
import WithDrawalTabs from "../../components/WithDrawalTabs/WithDrawalTabs";
import SalesTabs from "../../components/SalesTabs/SalesTabs";
import DiscountSellerTab from "../../components/DiscountSellerTab/DiscountSellerTab";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { setAlert } from "../../redux/actions/alerts";

// @assets
import Logo from "../../assets/wlogo.svg";

// @utils
import moment from "moment";
import "moment/locale/tr";
moment.locale("tr");

const { TabPane } = Tabs;
const SellerDetails = () => {
  const history = useHistory();
  const singleSeller = useSelector((state) => state.auth.singleSeller);
  const sellerInfo = useSelector((state) => state.auth.sellerInfo);
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.user);
  if (Object.keys(singleSeller).length === 0) return <Redirect to="/seller" />;

  const rule = user?.adminRule ? user.adminRule : "user";

  const onMoney = () => {
    if (rule === "user") {
      dispatch(setAlert("Başarısız", "bu eylemi yapmaya yetkiniz yok", "fail"));
      return;
    }
    history.push("/edit-seller-money");
  };

  const onInfo = () => {
    history.push(`edit-seller`);
  };

  const onEmail = () => {
    history.push("/seller-email");
  };

  const onPassword = () => {
    history.push("/seller-password");
  };

  const onDiscount = () => {
    history.push("/add-seller-discount");
  };

  const getLastSales = () => {
    if (sellerInfo.sales.length === 0) {
      return "Henüz satış yapılmamış";
    } else {
      return moment(sellerInfo.sales[0].payedAt).format(
        "Do MMMM YYYY - HH:mm "
      );
    }
  };

  const getTotalWithdraw = () => {
    if (sellerInfo.withDrawalRequest.length === 0) {
      return 0;
    }
    let total = 0;
    sellerInfo.withDrawalRequest.forEach((sale) => {
      if (sale.Status === "Approved") {
        total += sale.RequestAmount;
      }
    });
    return total;
  };
  const onCopy = () => {
    dispatch(setAlert("", "SATICI ID kopyalandı", "success"));
  };

  const {
    photoURL,
    companyEmail,
    companyName,
    companyAdress,
    city,
    area,
    createdDate,
    sales,
    currentSalesAmount,
    totalSalesAmount,
    totalJetdCommission,
    _id,
  } = singleSeller;
  return (
    <PagerContainer>
      <section className="buyer-details-container">
        <div className="buyer-details-container__image">
          <div
            className="buyer-details-img"
            style={{ backgroundColor: "#fb6d3a" }}
          >
            {photoURL === "NOT_SET" || !photoURL ? (
              <img
                src={Logo}
                alt="jetd logo"
                className="logo-image-container"
              />
            ) : (
              <img
                src={photoURL}
                alt="user avater"
                className="av-image-container"
              />
            )}
          </div>
          <div className="email-name">
            <p className="email-name__email">{companyEmail}</p>
            <p className="email-name__name">{companyName}</p>
            <p className="email-name__addr">{companyAdress}</p>
          </div>
          <div className="city-area">
            <p className="city-area__city">{city}</p>
            <p className="city-area__area">{area}</p>
            <p className="city-area__time">
              {moment(createdDate).format("Do MMMM YYYY: HH:mm")}
            </p>
          </div>
          <div className="money-bouns">
            <p className="money-bouns__money">
              Güncel Cari Tutar: {currentSalesAmount.toFixed(2)} TL
            </p>
            <p className="money-bouns__bouns">
              Toplam İndirimli Satış: {totalSalesAmount.toFixed(2)} TL
            </p>
            <p className="money-bouns__sales">Satış Sayısı: {sales.length}</p>
          </div>
          <div className="money-bouns">
            <CopyToClipboard text={_id}>
              <Button type="primary" onClick={onCopy}>
                {_id}
              </Button>
            </CopyToClipboard>
          </div>
        </div>
        <div className="button-info-container">
          <Button type="danger" onClick={onPassword}>
            Şifreyi Değiştir
          </Button>
          <Button type="danger" onClick={onEmail}>
            Email Değiştir
          </Button>
          <Button type="primary" onClick={onMoney}>
            Cari Tutar Düzenle
          </Button>
          <Button type="primary" onClick={onInfo}>
            Satıcı bilgisi değişikliği
          </Button>
          <Button type="primary" onClick={onDiscount}>
            Yeni Kampanya Ekle
          </Button>
        </div>
      </section>
      <section className="tabs-container">
        <Tabs defaultActiveKey="1" centered>
          <TabPane tab="Özet" key="1">
            <SellerInfoTab
              totalSalesAmount={totalSalesAmount}
              totalWithDraw={getTotalWithdraw()}
              lastSales={getLastSales()}
              totalJetdCommission={totalJetdCommission}
            />
          </TabPane>
          <TabPane tab="Kampanyalar" key="2">
            <DiscountSellerTab data={sellerInfo.discounts} />
          </TabPane>
          <TabPane tab="Para Çekme Talepleri" key="3">
            <WithDrawalTabs data={sellerInfo.withDrawalRequest} />
          </TabPane>
          <TabPane tab="Satış" key="4">
            <SalesTabs data={sellerInfo.sales} />
          </TabPane>
        </Tabs>
      </section>
    </PagerContainer>
  );
};

export default SellerDetails;
