import React, { useCallback, useEffect } from "react";

// Antd - components
import { Row, Col, Spin } from "antd";

// Hooks
import { useDispatch, useSelector } from "react-redux";

// Components
import PageContainer from "../../layouts/PageContainer/PageContainer";
import { Doughnut, Bar } from "react-chartjs-2";

// Icons
import { LoadingOutlined } from "@ant-design/icons";

// Redux -  Actions
import { fetchDailyStatistics } from "../../redux/actions/auth";

// Utils
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
} from "chart.js";

// // Styles
// import "./UsersStatistics.scss";
const antIcon = <LoadingOutlined style={{ fontSize: 128 }} spin />;

ChartJS.register(
  ArcElement,
  Tooltip,
  Legend,
  BarElement,
  Title,
  CategoryScale,
  LinearScale
);

const DailyStatistics = () => {
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.auth.loading);

  const fetchData = useCallback(() => {
    dispatch(fetchDailyStatistics());
  }, [dispatch]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const {
    totalJetdProfitForLastDay,
    totalJetdProfitForThisDay,
    totalNewBuyerForThisDay,
    totalNewBuyerLastDay,
    totalNewDiscountForThisDay,
    totalNewDiscountLastDay,
    totalNewSellerForThisDay,
    totalNewSellerLastDay,
    totalSalesAmountBounsForLastDay,
    totalSalesAmountBounsForThisDay,
    totalSalesAmountCreditCardForLastDay,
    totalSalesAmountCreditCardForThisDay,
    totalSalesAmountForLastDay,
    totalSalesAmountForThisDay,
    totalSalesAmountMixForLastDay,
    totalSalesAmountMixForThisDay,
    totalSalesAmountMoneyForLastDay,
    totalSalesAmountMoneyForThisDay,
    totalSalesCountBounsForLastDay,
    totalSalesCountBounsForThisDay,
    totalSalesCountCreditCardForLastDay,
    totalSalesCountCreditCardForThisDay,
    totalSalesCountForLastDay,
    totalSalesCountForThisDay,
    totalSalesCountMixForLastDay,
    totalSalesCountMixForThisDay,
    totalSalesCountMoneyForLastDay,
    totalSalesCountMoneyForThisDay,
  } = useSelector((state) => state.auth);

  const firstChar = {
    labels: [
      "Bonus Satış bugün",
      "Bakiye Satış bugün",
      "Kredi Kartı Satış bugün",
      "Mix Satış bugün",
    ],
    datasets: [
      {
        label: "# of Votes",
        data: [
          totalSalesCountBounsForThisDay,
          totalSalesCountMoneyForThisDay,
          totalSalesCountCreditCardForThisDay,
          totalSalesCountMixForThisDay,
        ],
        backgroundColor: [
          "rgba(255, 99, 132, 0.2)",
          "rgba(54, 162, 235, 0.2)",
          "rgba(255, 206, 86, 0.2)",
          "rgba(75, 192, 192, 0.2)",
        ],
        borderColor: [
          "rgba(255, 99, 132, 1)",
          "rgba(54, 162, 235, 1)",
          "rgba(255, 206, 86, 1)",
          "rgba(75, 192, 192, 1)",
        ],
        borderWidth: 0.5,
      },
    ],
  };

  const optionsOne = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: false,
        text: " ",
      },
    },
  };

  const labelsOne = [
    "Toplam  Satış Tutarı ",
    "Toplam Satış tutarı (Kredi Kartı) ",
    "Toplam Satış tutarı (Bakiye) ",
    "Toplam Satış tutarı (Bonus) ",
    "Toplam Satış tutarı (Mix) ",
  ];

  const labelsTwo = [
    "Toplam  Satış Sayısı ",
    "Toplam Satış Sayısı (Kredi Kartı) ",
    "Toplam Satış Sayısı (Bakiye) ",
    "Toplam Satış Sayısı (Bonus) ",
    "Toplam Satış Sayısı (Mix) ",
  ];

  const labelsThree = [
    "Toplam  Yeni Alıcı",
    "Toplam Yeni Satıcı",
    "Toplam Yeni Kampanya ",
  ];

  const dataOne = {
    labels: labelsOne,
    datasets: [
      {
        label: "Satış tutarı bugün",
        data: [
          totalSalesAmountForThisDay,
          totalSalesAmountCreditCardForThisDay,
          totalSalesAmountMoneyForThisDay,
          totalSalesAmountBounsForThisDay,
          totalSalesAmountMixForThisDay,
        ],
        backgroundColor: "rgba(255, 99, 132, 0.5)",
      },
      {
        label: "Satış tutarı dün",
        data: [
          totalSalesAmountForLastDay,
          totalSalesAmountCreditCardForLastDay,
          totalSalesAmountMoneyForLastDay,
          totalSalesAmountBounsForLastDay,
          totalSalesAmountMixForLastDay,
        ],
        backgroundColor: "rgba(53, 162, 235, 0.5)",
      },
    ],
  };

  const dataThree = {
    labels: labelsThree,
    datasets: [
      {
        label: "bugün",
        data: [
          totalNewBuyerForThisDay,
          totalNewSellerForThisDay,
          totalNewDiscountForThisDay,
        ],
        backgroundColor: "rgba(255, 99, 132, 0.5)",
      },
      {
        label: "dün",
        data: [
          totalNewBuyerLastDay,
          totalNewSellerLastDay,
          totalNewDiscountLastDay,
        ],
        backgroundColor: "rgba(53, 162, 235, 0.5)",
      },
    ],
  };

  const dataTwo = {
    labels: labelsTwo,
    datasets: [
      {
        label: "Satış Sayısı bugün",
        data: [
          totalSalesCountForThisDay,
          totalSalesCountCreditCardForThisDay,
          totalSalesCountMoneyForThisDay,
          totalSalesCountBounsForThisDay,
          totalSalesCountMixForThisDay,
        ],
        backgroundColor: "rgba(255, 99, 132, 0.5)",
      },
      {
        label: "Satış Sayısı dün",
        data: [
          totalSalesCountForLastDay,
          totalSalesCountCreditCardForLastDay,
          totalSalesCountMoneyForLastDay,
          totalSalesCountBounsForLastDay,
          totalSalesCountMixForLastDay,
        ],
        backgroundColor: "rgba(53, 162, 235, 0.5)",
      },
    ],
  };

  return (
    <PageContainer>
      {loading ? (
        <Spin indicator={antIcon} className="spinner" />
      ) : (
        <div
          style={{
            width: "100%",
            justifyContent: "center",
            alignItems: "center",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <h1 className="report-title">Günlük İstatistikleri</h1>
          <Row gutter={[0, 10]}>
            <Col xs={24} sm={24} md={12} lg={12} xl={8} xxl={6}>
              <div
                className="user-info-card"
                style={{ width: "30rem", gap: "2rem" }}
              >
                <h1>Toplam İndirimli Satış Tutarı bugün </h1>
                <h2 style={{ color: "#5f63f2", fontSize: "2.4rem" }}>
                  {totalSalesAmountForThisDay.toFixed(2)}₺
                </h2>
              </div>
            </Col>

            <Col xs={24} sm={24} md={12} lg={12} xl={8} xxl={6}>
              <div
                className="user-info-card"
                style={{ width: "30rem", gap: "2rem" }}
              >
                <h1>Toplam Jetd Kazancı bugün </h1>
                <h2 style={{ color: "#5f63f2", fontSize: "2.4rem" }}>
                  {totalJetdProfitForThisDay.toFixed(2)}₺
                </h2>
              </div>
            </Col>

            <Col xs={24} sm={24} md={12} lg={12} xl={8} xxl={6}>
              <div
                className="user-info-card"
                style={{ width: "30rem", gap: "2rem" }}
              >
                <h1>Toplam Jetd Kazancı dün </h1>
                <h2 style={{ color: "#5f63f2", fontSize: "2.4rem" }}>
                  {totalJetdProfitForLastDay.toFixed(2)}₺
                </h2>
              </div>
            </Col>

            <Col xs={24} sm={24} md={12} lg={12} xl={8} xxl={6}>
              <div
                className="user-info-card"
                style={{ width: "30rem", gap: "2rem" }}
              >
                <h1>Toplam Satış Sayısı bugün </h1>
                <h2 style={{ color: "#5f63f2", fontSize: "2.4rem" }}>
                  {totalSalesCountForThisDay}
                </h2>
              </div>
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={8} xxl={6}>
              <div
                className="user-info-card"
                style={{ width: "30rem", gap: "2rem" }}
              >
                <h1>Toplam Satış tutarı (Kredi Kartı) bugün </h1>
                <h2 style={{ color: "#5f63f2", fontSize: "2.4rem" }}>
                  {totalSalesAmountCreditCardForThisDay.toFixed(2)}₺
                </h2>
              </div>
            </Col>

            <Col xs={24} sm={24} md={12} lg={12} xl={8} xxl={6}>
              <div
                className="user-info-card"
                style={{ width: "30rem", gap: "2rem" }}
              >
                <h1>Toplam Satış tutarı (Bakiye) bugün </h1>
                <h2 style={{ color: "#5f63f2", fontSize: "2.4rem" }}>
                  {totalSalesAmountMoneyForThisDay.toFixed(2)}₺
                </h2>
              </div>
            </Col>

            <Col xs={24} sm={24} md={12} lg={12} xl={8} xxl={6}>
              <div
                className="user-info-card"
                style={{ width: "30rem", gap: "2rem" }}
              >
                <h1>Toplam Satış tutarı (Bonus) bugün </h1>
                <h2 style={{ color: "#5f63f2", fontSize: "2.4rem" }}>
                  {totalSalesAmountBounsForThisDay.toFixed(2)}₺
                </h2>
              </div>
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={8} xxl={6}>
              <div
                className="user-info-card"
                style={{ width: "30rem", gap: "2rem" }}
              >
                <h1>Toplam Satış tutarı (Karışık) </h1>
                <h2 style={{ color: "#5f63f2", fontSize: "2.4rem" }}>
                  {totalSalesAmountMixForThisDay.toFixed(2)}₺
                </h2>
              </div>
            </Col>

            <Col xs={24} sm={24} md={12} lg={12} xl={8} xxl={6}>
              <div
                className="user-info-card"
                style={{ width: "30rem", gap: "2rem" }}
              >
                <h1>Toplam Satış Sayısı (Kredi Kartı) bugün </h1>
                <h2 style={{ color: "#5f63f2", fontSize: "2.4rem" }}>
                  {totalSalesCountCreditCardForThisDay}
                </h2>
              </div>
            </Col>

            <Col xs={24} sm={24} md={12} lg={12} xl={8} xxl={6}>
              <div
                className="user-info-card"
                style={{ width: "30rem", gap: "2rem" }}
              >
                <h1>Toplam Satış Sayısı (Bakiye) bugün </h1>
                <h2 style={{ color: "#5f63f2", fontSize: "2.4rem" }}>
                  {totalSalesCountMoneyForThisDay}
                </h2>
              </div>
            </Col>

            <Col xs={24} sm={24} md={12} lg={12} xl={8} xxl={6}>
              <div
                className="user-info-card"
                style={{ width: "30rem", gap: "2rem" }}
              >
                <h1>Toplam Satış Sayısı (Bonus) bugün </h1>
                <h2 style={{ color: "#5f63f2", fontSize: "2.4rem" }}>
                  {totalSalesCountBounsForThisDay}
                </h2>
              </div>
            </Col>

            <Col xs={24} sm={24} md={12} lg={12} xl={8} xxl={6}>
              <div
                className="user-info-card"
                style={{ width: "30rem", gap: "2rem" }}
              >
                <h1>Toplam Satış Sayısı (Mix) bugün </h1>
                <h2 style={{ color: "#5f63f2", fontSize: "2.4rem" }}>
                  {totalSalesCountMixForThisDay}
                </h2>
              </div>
            </Col>

            <Col xs={24} sm={24} md={12} lg={12} xl={8} xxl={6}>
              <div
                className="user-info-card"
                style={{ width: "30rem", gap: "2rem" }}
              >
                <h1>Toplam yeni yeni alıcı bugün </h1>
                <h2 style={{ color: "#5f63f2", fontSize: "2.4rem" }}>
                  {totalNewBuyerForThisDay}
                </h2>
              </div>
            </Col>

            <Col xs={24} sm={24} md={12} lg={12} xl={8} xxl={6}>
              <div
                className="user-info-card"
                style={{ width: "30rem", gap: "2rem" }}
              >
                <h1>Toplam yeni satıcı Sayıs bugün </h1>
                <h2 style={{ color: "#5f63f2", fontSize: "2.4rem" }}>
                  {totalNewSellerForThisDay}
                </h2>
              </div>
            </Col>

            <Col xs={24} sm={24} md={12} lg={12} xl={8} xxl={6}>
              <div
                className="user-info-card"
                style={{ width: "30rem", gap: "2rem" }}
              >
                <h1>Toplam Kampanya Sayıs bugün </h1>
                <h2 style={{ color: "#5f63f2", fontSize: "2.4rem" }}>
                  {totalNewDiscountForThisDay}
                </h2>
              </div>
            </Col>
          </Row>
          <Row gutter={[24, 24]}>
            <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
              <div className="char-container">
                <h1>Satış çizelge</h1>
                <Doughnut data={firstChar} />
              </div>
            </Col>
            <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
              <div className="char-container">
                <h1>Satış tutarı çizelge</h1>
                <Bar options={optionsOne} data={dataOne} />
              </div>
            </Col>

            <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
              <div className="char-container">
                <h1>Satış Sayıs çizelge</h1>
                <Bar options={optionsOne} data={dataTwo} />
              </div>
            </Col>

            <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
              <div className="char-container">
                <h1>kullanıcılar çizelge</h1>
                <Bar options={optionsOne} data={dataThree} />
              </div>
            </Col>
          </Row>
        </div>
      )}
    </PageContainer>
  );
};

export default DailyStatistics;
