import React from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

// Container
import PagerContainer from "../../layouts/PageContainer/PageContainer";

// Antd - Componets
import { Button, Form, Input, Spin } from "antd";

// Icons
import { LoadingOutlined } from "@ant-design/icons";
import FeatherIconsReact from "feather-icons-react";

// @redux
import { updateBuyerEmail, createLogs } from "../../redux/actions/auth";

const antIcon = <LoadingOutlined style={{ fontSize: 128 }} spin />;

const EditBuyerEmail = () => {
  const history = useHistory();
  const loading = useSelector((state) => state.auth.loading);
  const singleBuyer = useSelector((state) => state.auth.singleBuyer);
  const adminName = useSelector((state) => state.auth.user.displayName);
  const dispatch = useDispatch();

  const [form] = Form.useForm();

  const onLink = () => {
    history.push("/buyer");
  };

  const handleSubmit = async (value) => {
    await dispatch(updateBuyerEmail(value, singleBuyer._id));
    let log = {
      ProcessName: "Change buyer email",
      ProcessDescription: `(${adminName})Admin , ${singleBuyer.displayName} alıcı email değiştirdi`,
      SellerId: null,
      UserId: singleBuyer._id,
      Request: JSON.stringify(value),
    };
    dispatch(createLogs(log));
    history.push("/buyer");
  };

  return (
    <PagerContainer>
      {loading ? (
        <Spin indicator={antIcon} className="spinner" />
      ) : (
        <>
          <div className="add-discount-container">
            <div className="discount-title">
              <h1>Satıcı Düzenle</h1>
              <div>
                <Button
                  type="primary"
                  className="discount-title-link"
                  onClick={onLink}
                >
                  <FeatherIconsReact icon="plus" width="1.5rem" />
                  <span>Geri Dön</span>
                </Button>
              </div>
            </div>
            <div className="form-container">
              <Form
                layout="vertical"
                onFinish={handleSubmit}
                className="discount-add-form"
                form={form}
                name="editBuyer"
              >
                <Form.Item
                  name="email"
                  label="Email"
                  initialValue={singleBuyer.email}
                  rules={[
                    {
                      message: "Email Giriniz.",
                      required: true,
                    },
                  ]}
                >
                  <Input />
                </Form.Item>

                <Form.Item>
                  <Button
                    className="btn-add-q"
                    htmlType="submit"
                    type="primary"
                    size="large"
                    disabled={loading}
                  >
                    {loading ? "Yükleniyor..." : "Kaydet"}
                  </Button>
                </Form.Item>
              </Form>
            </div>
          </div>
        </>
      )}
    </PagerContainer>
  );
};

export default EditBuyerEmail;
