import React, { createRef } from "react";

import html2canvas from "html2canvas";

// Antd - components
import { Row, Col, Spin, Button } from "antd";

// Hooks
import { useSelector } from "react-redux";

// Components
import PageContainer from "../../layouts/PageContainer/PageContainer";

// Icons
import { LoadingOutlined } from "@ant-design/icons";

// Redux -  Actions

// // Styles
// import "./UsersStatistics.scss";

import BestSellerMonth from "../../components/BestSellerForThisMonth/BestSellerForThisMonth";
import BestBuyerForThisMonth from "../../components/BestBuyerForThisMonth/BestBuyerForThisMonth";
import BestJetdSalesForThisMonth from "../../components/BestJetdSalesForThisMonth/BestJetdSalesForThisMonth";
const antIcon = <LoadingOutlined style={{ fontSize: 128 }} spin />;

const MonthlyStatistics = () => {
  const loading = useSelector((state) => state.auth.loading);

  const ref = createRef(null);

  const handleClickTakeScreenShot = () => {
    const { cropPositionTop, cropPositionLeft, cropWidth, cropHeigth } = {
      cropPositionTop: 0,
      cropPositionLeft: 0,
      cropWidth: 5000,
      cropHeigth: 20000,
    };

    html2canvas(ref.current).then((canvas) => {
      let croppedCanvas = document.createElement("canvas");
      let croppedCanvasContext = croppedCanvas.getContext("2d");

      croppedCanvas.width = cropWidth;
      croppedCanvas.height = cropHeigth;

      croppedCanvasContext.drawImage(canvas, cropPositionLeft, cropPositionTop);

      const a = document.createElement("a");
      a.href = croppedCanvas.toDataURL();
      a.download = "screenShot.png";
      a.click();
    });
  };

  const {
    sellersWithMostSalesAmount,
    sellersWithMostSalesCount,
    usersWithMostSalesCount,
    usersWithMostSalesAmount,
    bsetJetdProfitForThisMonth,
    totalJetdProfitForThisMonth,
    totalNewBuyerForThisMonth,
    totalNewDiscountForThisMonth,
    totalNewSellerForThisMonth,
    totalSalesAmountBounsForThisMonth,
    totalSalesAmountCreditCardForThisMonth,
    totalSalesAmountForThisMonth,
    totalSalesAmountMixForThisMonth,
    totalSalesAmountMoneyForThisMonth,
    totalSalesCountBounsForThisMonth,
    totalSalesCountCreditCardForThisMonth,
    totalSalesCountForThisMonth,
    totalSalesCountMixForThisMonth,
    totalSalesCountMoneyForThisMonth,
  } = useSelector((state) => state.auth);

  return (
    <PageContainer>
      {loading ? (
        <Spin indicator={antIcon} className="spinner" />
      ) : (
        <div
          style={{
            width: "100%",
            justifyContent: "center",
            alignItems: "center",
            display: "flex",
            flexDirection: "column",
          }}
          ref={ref}
        >
          <h1 className="report-title">Aylık İstatistikleri</h1>

          <Button
            onClick={handleClickTakeScreenShot}
            style={{ marginBottom: "2rem" }}
          >
            {" "}
            Screen Shot
          </Button>
          <Row gutter={[0, 10]}>
            <Col xs={24} sm={24} md={12} lg={12} xl={8} xxl={6}>
              <div
                className="user-info-card"
                style={{ width: "30rem", gap: "2rem" }}
              >
                <h1>Toplam İndirimli Satış Tutarı </h1>
                <h2 style={{ color: "#5f63f2", fontSize: "2.4rem" }}>
                  {totalSalesAmountForThisMonth.toFixed(2)}₺
                </h2>
              </div>
            </Col>

            <Col xs={24} sm={24} md={12} lg={12} xl={8} xxl={6}>
              <div
                className="user-info-card"
                style={{ width: "30rem", gap: "2rem" }}
              >
                <h1>Toplam Jetd Kazancı </h1>
                <h2 style={{ color: "#5f63f2", fontSize: "2.4rem" }}>
                  {totalJetdProfitForThisMonth.toFixed(2)}₺
                </h2>
              </div>
            </Col>

            <Col xs={24} sm={24} md={12} lg={12} xl={8} xxl={6}>
              <div
                className="user-info-card"
                style={{ width: "30rem", gap: "2rem" }}
              >
                <h1>Toplam Satış Sayısı </h1>
                <h2 style={{ color: "#5f63f2", fontSize: "2.4rem" }}>
                  {totalSalesCountForThisMonth}
                </h2>
              </div>
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={8} xxl={6}>
              <div
                className="user-info-card"
                style={{ width: "30rem", gap: "2rem" }}
              >
                <h1>Toplam Satış tutarı (Kredi Kartı) </h1>
                <h2 style={{ color: "#5f63f2", fontSize: "2.4rem" }}>
                  {totalSalesAmountCreditCardForThisMonth.toFixed(2)}₺
                </h2>
              </div>
            </Col>

            <Col xs={24} sm={24} md={12} lg={12} xl={8} xxl={6}>
              <div
                className="user-info-card"
                style={{ width: "30rem", gap: "2rem" }}
              >
                <h1>Toplam Satış tutarı (Bakiye) </h1>
                <h2 style={{ color: "#5f63f2", fontSize: "2.4rem" }}>
                  {totalSalesAmountMoneyForThisMonth.toFixed(2)}₺
                </h2>
              </div>
            </Col>

            <Col xs={24} sm={24} md={12} lg={12} xl={8} xxl={6}>
              <div
                className="user-info-card"
                style={{ width: "30rem", gap: "2rem" }}
              >
                <h1>Toplam Satış tutarı (Bonus) </h1>
                <h2 style={{ color: "#5f63f2", fontSize: "2.4rem" }}>
                  {totalSalesAmountBounsForThisMonth.toFixed(2)}₺
                </h2>
              </div>
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={8} xxl={6}>
              <div
                className="user-info-card"
                style={{ width: "30rem", gap: "2rem" }}
              >
                <h1>Toplam Satış tutarı (Karışık) </h1>
                <h2 style={{ color: "#5f63f2", fontSize: "2.4rem" }}>
                  {totalSalesAmountMixForThisMonth.toFixed(2)}₺
                </h2>
              </div>
            </Col>

            <Col xs={24} sm={24} md={12} lg={12} xl={8} xxl={6}>
              <div
                className="user-info-card"
                style={{ width: "30rem", gap: "2rem" }}
              >
                <h1>Toplam Satış Sayısı (Kredi Kartı) </h1>
                <h2 style={{ color: "#5f63f2", fontSize: "2.4rem" }}>
                  {totalSalesCountCreditCardForThisMonth}
                </h2>
              </div>
            </Col>

            <Col xs={24} sm={24} md={12} lg={12} xl={8} xxl={6}>
              <div
                className="user-info-card"
                style={{ width: "30rem", gap: "2rem" }}
              >
                <h1>Toplam Satış Sayısı (Bakiye) </h1>
                <h2 style={{ color: "#5f63f2", fontSize: "2.4rem" }}>
                  {totalSalesCountMoneyForThisMonth}
                </h2>
              </div>
            </Col>

            <Col xs={24} sm={24} md={12} lg={12} xl={8} xxl={6}>
              <div
                className="user-info-card"
                style={{ width: "30rem", gap: "2rem" }}
              >
                <h1>Toplam Satış Sayısı (Bonus) </h1>
                <h2 style={{ color: "#5f63f2", fontSize: "2.4rem" }}>
                  {totalSalesCountBounsForThisMonth}
                </h2>
              </div>
            </Col>

            <Col xs={24} sm={24} md={12} lg={12} xl={8} xxl={6}>
              <div
                className="user-info-card"
                style={{ width: "30rem", gap: "2rem" }}
              >
                <h1>Toplam Satış Sayısı (Mix) </h1>
                <h2 style={{ color: "#5f63f2", fontSize: "2.4rem" }}>
                  {totalSalesCountMixForThisMonth}
                </h2>
              </div>
            </Col>

            <Col xs={24} sm={24} md={12} lg={12} xl={8} xxl={6}>
              <div
                className="user-info-card"
                style={{ width: "30rem", gap: "2rem" }}
              >
                <h1>Toplam yeni yeni alıcı </h1>
                <h2 style={{ color: "#5f63f2", fontSize: "2.4rem" }}>
                  {totalNewBuyerForThisMonth}
                </h2>
              </div>
            </Col>

            <Col xs={24} sm={24} md={12} lg={12} xl={8} xxl={6}>
              <div
                className="user-info-card"
                style={{ width: "30rem", gap: "2rem" }}
              >
                <h1>Toplam yeni satıcı Sayıs </h1>
                <h2 style={{ color: "#5f63f2", fontSize: "2.4rem" }}>
                  {totalNewSellerForThisMonth}
                </h2>
              </div>
            </Col>

            <Col xs={24} sm={24} md={12} lg={12} xl={8} xxl={6}>
              <div
                className="user-info-card"
                style={{ width: "30rem", gap: "2rem" }}
              >
                <h1>Toplam Kampanya Sayıs </h1>
                <h2 style={{ color: "#5f63f2", fontSize: "2.4rem" }}>
                  {totalNewDiscountForThisMonth}
                </h2>
              </div>
            </Col>
          </Row>
          <Row gutter={[24, 24]}></Row>
          <div
            style={{
              marginBottom: "2rem",
              maxWidth: "100%",
            }}
          >
            <BestSellerMonth
              sellerData={sellersWithMostSalesCount}
              header="most seller sales (Count)"
            />
          </div>

          <div
            style={{
              marginBottom: "2rem",
              maxWidth: "100%",
            }}
          >
            <BestSellerMonth
              sellerData={sellersWithMostSalesAmount}
              header="most seller sales (Amount)"
            />
          </div>

          <div
            style={{
              marginBottom: "2rem",
              maxWidth: "100%",
            }}
          >
            <BestBuyerForThisMonth
              buyerData={usersWithMostSalesCount}
              header="most users sales (count)"
            />
          </div>
          <div
            style={{
              marginBottom: "2rem",
              maxWidth: "100%",
            }}
          >
            <BestBuyerForThisMonth
              buyerData={usersWithMostSalesAmount}
              header="most users sales (Amount)"
            />
          </div>
          <div
            style={{
              marginBottom: "2rem",
              maxWidth: "100%",
            }}
          >
            <BestJetdSalesForThisMonth salesData={bsetJetdProfitForThisMonth} />
          </div>
        </div>
      )}
    </PageContainer>
  );
};

export default MonthlyStatistics;
