import React, { useCallback, useEffect } from "react";
import { useHistory } from "react-router-dom";

// Container
import PagerContainer from "../../layouts/PageContainer/PageContainer";
import SingleCity from "../../components/SingleCity/SingleCity";

// Icons
import { LoadingOutlined } from "@ant-design/icons";
import FeatherIconsReact from "feather-icons-react";

// Antd - Componets
import { Spin, Button } from "antd";

// Hooks
import { useDispatch, useSelector } from "react-redux";

// @Redux
import { fetchCities } from "../../redux/actions/auth";

// Styles
import "./Cities.scss";
const antIcon = <LoadingOutlined style={{ fontSize: 128 }} spin />;

const Cities = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const loading = useSelector((state) => state.auth.loading);
  const cities = useSelector((state) => state.auth.cities);

  const onLink = () => {
    history.push(`/add-city`);
  };

  const fetchCitiesOnFirstLoad = useCallback(async () => {
    await dispatch(fetchCities());
  }, [dispatch]);

  useEffect(() => {
    fetchCitiesOnFirstLoad();
  }, [fetchCitiesOnFirstLoad]);
  return (
    <PagerContainer>
      {loading ? (
        <Spin indicator={antIcon} className="spinner" />
      ) : (
        <>
          <div className="cities-container__header">
            <h1>Şehirler</h1>
            <div>
              <Button
                type="primary"
                className="discount-title-link"
                onClick={onLink}
              >
                <FeatherIconsReact icon="plus" width="1.5rem" />
                <span>Yeni Şehir Ekle</span>
              </Button>
            </div>
          </div>
          <div className="cities-container__body">
            {cities.length !== 0 ? (
              <>
                {cities.map((c) => (
                  // <SingleBuyer key={b._id} {...b} />
                  <SingleCity key={c._id} {...c} />
                ))}
              </>
            ) : (
              <h1>Bu e-postayla hiçbir Alıcı bulunamadı </h1>
            )}
          </div>
        </>
      )}
    </PagerContainer>
  );
};

export default Cities;
