import React from "react";

// Hooks
import { useSelector, useDispatch } from "react-redux";
import { Redirect, useHistory } from "react-router-dom";

// Container
import PagerContainer from "../../layouts/PageContainer/PageContainer";

// Antd - Componets
import { Tag, Button, Tabs } from "antd";
import UserInfoTab from "../../components/UserInfoTab/UserInfoTab";
import MoneyTabs from "../../components/moneyTabs/MoneyTabs";
import WithDrawalTabs from "../../components/WithDrawalTabs/WithDrawalTabs";
import SalesTabs from "../../components/SalesTabs/SalesTabs";
import AccountStatementTabs from "../../components/AccountStatementTabs/AccountStatementTabs";
// Styles
import "./BuyerDetails.scss";

// @assets
import Logo from "../../assets/wlogo.svg";

// @Redux Actions
import { setAlert } from "../../redux/actions/alerts";

// @utils
import moment from "moment";
import "moment/locale/tr";
moment.locale("tr");

const { TabPane } = Tabs;
const BuyerDetails = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const singleBuyer = useSelector((state) => state.auth.singleBuyer);
  const buyerInfo = useSelector((state) => state.auth.buyerInfo);
  const user = useSelector((state) => state.auth.user);

  const rule = user?.adminRule ? user.adminRule : "user";

  if (Object.keys(singleBuyer).length === 0) return <Redirect to="/buyer" />;

  const onMoney = () => {
    if (rule === "user") {
      dispatch(setAlert("Başarısız", "bu eylemi yapmaya yetkiniz yok", "fail"));
      return;
    }
    history.push("/edit-buyer");
  };

  const onInfo = () => {
    if (rule === "user") {
      dispatch(setAlert("Başarısız", "bu eylemi yapmaya yetkiniz yok", "fail"));
      return;
    }
    history.push("/buyer-info");
  };

  const onEmail = () => {
    if (rule === "user") {
      dispatch(setAlert("Başarısız", "bu eylemi yapmaya yetkiniz yok", "fail"));
      return;
    }
    history.push("/buyer-email");
  };

  const onPassword = () => {
    if (rule === "user") {
      dispatch(setAlert("Başarısız", "bu eylemi yapmaya yetkiniz yok", "fail"));
      return;
    }
    history.push("/buyer-password");
  };

  const getLastSales = () => {
    if (buyerInfo.sales.length === 0) {
      return "Henüz satış yapılmamış";
    } else {
      return moment(buyerInfo.sales[0].payedAt).format("Do MMMM YYYY - HH:mm");
    }
  };

  const getTotalWithdraw = () => {
    if (buyerInfo.withDrawalRequest.length === 0) {
      return 0;
    }
    let total = 0;
    buyerInfo.withDrawalRequest.forEach((sale) => {
      if (sale.Status === "Approved") {
        total += sale.RequestAmount;
      }
    });
    return total;
  };

  const {
    photoURL,
    email,
    displayName,
    adress,
    city,
    area,
    createdAt,
    money,
    bonus,
    sales,
    provider,
    isComplated,
    isVerify,
    totalSalesAmount,
  } = singleBuyer;
  return (
    <PagerContainer>
      <section className="buyer-details-container">
        <div className="buyer-details-container__image">
          <div className="buyer-details-img">
            {photoURL === "NOT_SET" || !photoURL ? (
              <img
                src={Logo}
                alt="jetd logo"
                className="logo-image-container"
              />
            ) : (
              <img
                src={photoURL}
                alt="user avater"
                className="av-image-container"
              />
            )}
          </div>
          <div className="email-name">
            <p className="email-name__email">{email}</p>
            <p className="email-name__name">{displayName}</p>
            <p className="email-name__addr">{adress}</p>
          </div>
          <div className="city-area">
            <p className="city-area__city">{city}</p>
            <p className="city-area__area">{area}</p>
            <p className="city-area__time">
              {moment(createdAt).format("Do MMMM YYYY: HH:mm")}
            </p>
          </div>
          <div className="money-bouns">
            <p className="money-bouns__money">Bakiye: {money.toFixed(2)} TL</p>
            <p className="money-bouns__bouns">Bonus: {bonus.toFixed(2)} TL</p>
            <p className="money-bouns__sales">Satış Sayısı: {sales.length}</p>
          </div>
          <div className="more-info">
            <Tag color={pickColor(provider)}>{provider}</Tag>
            <Tag color={isVerify ? "green" : "error"}>
              {isVerify
                ? "kullanıcı telefon numarası doğrulandı"
                : "kullanıcı telefon numarası doğrulanmadı"}
            </Tag>
            <Tag color={isComplated ? "green" : "error"}>
              {isComplated
                ? "kullanıcı hesabındaki tüm bilgileri tamamlar"
                : "kullanıcı hesabındaki tüm bilgileri tamamlamaz"}
            </Tag>
          </div>
        </div>
        <div className="button-info-container">
          <Button type="danger" onClick={onPassword}>
            Şifreyi Değiştir
          </Button>
          <Button type="danger" onClick={onEmail}>
            Email Değiştir
          </Button>
          <Button type="primary" onClick={onMoney}>
            Bakiye / Bonus Düzenle
          </Button>
          <Button type="primary" onClick={onInfo}>
            kullanıcı bilgisi değişikliği
          </Button>
        </div>
      </section>
      <section className="tabs-container">
        <Tabs defaultActiveKey="1" centered>
          <TabPane tab="Özet" key="1">
            <UserInfoTab
              totalAmount={totalSalesAmount}
              lastSales={getLastSales()}
              salesCount={sales.length}
              totalWithDraw={getTotalWithdraw()}
            />
          </TabPane>
          <TabPane tab="Bakiye Talepleri" key="2">
            <MoneyTabs data={buyerInfo.moneyReq} />
          </TabPane>
          <TabPane tab="Para Çekme Talepleri" key="3">
            <WithDrawalTabs data={buyerInfo.withDrawalRequest} />
          </TabPane>
          <TabPane tab="Yaptığı Harcamalar" key="4">
            <SalesTabs data={buyerInfo.sales} />
          </TabPane>
          <TabPane tab="Hesap Dökümü" key="5">
            <AccountStatementTabs data={buyerInfo.processLogs} />
          </TabPane>
        </Tabs>
      </section>
    </PagerContainer>
  );
};

function pickColor(provider) {
  switch (provider) {
    case "facebook":
      return "#3b5999";
    case "google":
      return "#ff6f00";
    case "Apple":
      return "black";
    default:
      return "";
  }
}

export default BuyerDetails;
