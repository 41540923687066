import React, { useEffect, useState } from "react";

// Antd - Componets

import { Table, Tag, Button } from "antd";

// Icons
import { CloudDownloadOutlined } from "@ant-design/icons";

// @utils
import ExportJsonExcel from "js-export-excel";
import moment from "moment";
import "moment/locale/tr";
moment.locale("tr");

const SalesTabs = ({ data }) => {
  const [tableData, setTabelData] = useState([]);
  const columns = [
    {
      title: "Tarih",
      dataIndex: "date",
      key: "date",
    },
    {
      title: "Müşteri Adı",
      dataIndex: "displayName",
      key: "displayName",
    },
    {
      title: "Kampanya Adı",
      dataIndex: "LocalDiscount",
      key: "LocalDiscount",
    },
    {
      title: "Ana Fiyat",
      dataIndex: "salesAmountWithOutDiscount",
      key: "salesAmountWithOutDiscount",
    },
    {
      title: "Ödenen Tutar",
      dataIndex: "salesAmountWithDiscount",
      key: "salesAmountWithDiscount",
    },

    {
      title: "İndirim Oranı",
      dataIndex: "netDiscountRate",
      key: "netDiscountRate",
    },

    {
      title: "Jetd Komisyon",
      dataIndex: "jetdCommission",
      key: "jetdCommission",
    },
    {
      title: "Ödeme Yöntemi",
      dataIndex: "paymentType",
      key: "paymentType",
    },
  ];

  useEffect(() => {
    const dataList = [];
    data.forEach((b) => {
      dataList.push({
        key: b._id,
        date: <>{moment(b.createdAt).format("Do MMMM YYYY - HH:mm")}</>,
        displayName: <>{b.user.displayName}</>,
        LocalDiscount: <>{b.LocalDiscount.title}</>,
        salesAmountWithOutDiscount: (
          <>{b.salesAmountWithOutDiscount.toFixed(2)}₺</>
        ),
        salesAmountWithDiscount: <>{b.salesAmountWithDiscount.toFixed(2)}₺</>,
        netDiscountRate: <>{b.netDiscountRate}%</>,
        jetdCommission: <>{b.jetdCommission}%</>,
        type: (
          <Tag color={b.isBonus ? "blue" : "green"}>
            {b.isBonus ? "Bonus" : "Bakiye"}
          </Tag>
        ),
        paymentType: <Tag color={getColor(b.paymentType)}>{b.paymentType}</Tag>,
      });
    });
    setTabelData(dataList);
    // eslint-disable-next-line
  }, [data]);

  const onButtonSeClick = async () => {
    const dataList = [];
    data.forEach((b) => {
      dataList.push({
        displayName: b.user.displayName,
        discountName: b.LocalDiscount.title,
        net: b.netDiscountRate,
        paymentType: b.paymentType,
        plaka: b.plates ? b.plates.plate : "",
        salesAmountWithOutDiscount: b.salesAmountWithOutDiscount.toFixed(2),
        salesAmountWithDiscount: b.salesAmountWithDiscount.toFixed(2),
        jetdProfitAmount: b.jetdProfitAmount,
        date: moment(b.payedAt).format("Do MMMM YYYY HH:mm"),
      });
    });
    const option = {
      fileName: `Satış Raporu ${moment().format("DD-MM-YYYY HH:mm")}`,
      datas: [
        {
          sheetData: dataList,
          sheetName: `Satış Raporu ${moment().format("DD-MM-YYYY")}`,
          sheetFilter: [
            "displayName",
            "discountName",
            "net",
            "paymentType",
            "plaka",
            "salesAmountWithOutDiscount",
            "salesAmountWithDiscount",
            "jetdProfitAmount",
            "date",
          ],
          sheetHeader: [
            "Müşteri Adı",
            "Kampanya Adı",
            "Net İndirim",
            "Ödeme Yöntemi",
            "Plaka",
            "Normal Tutar",
            "Ödenen Tutar",
            "Jetd Komisyonu",
            "Tarih",
          ],
        },
      ],
    };
    const toExcel = new ExportJsonExcel(option);
    toExcel.saveExcel();
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
          width: "100%",
          marginBottom: "2rem",
        }}
      >
        <Button onClick={onButtonSeClick} icon={<CloudDownloadOutlined />}>
          Raporu İndir
        </Button>
      </div>

      <Table
        columns={columns}
        dataSource={tableData}
        pagination={false}
        className="tableContainer"
        scroll={{ x: 1200, y: "80%" }}
      />
    </>
  );
};

function getColor(data) {
  switch (data) {
    case "Bakiye":
      return "success";
    case "Bonus":
      return "lime";
    case "Kredi Kartı":
      return "cyan";
    default:
      return "volcano";
  }
}

export default SalesTabs;
