import React, { useEffect, useCallback, useState } from "react";

// Container
import PagerContainer from "../../layouts/PageContainer/PageContainer";

// Antd - Componets
import { Spin, Button, Table, Tag, DatePicker, Input } from "antd";

// Icons
import { LoadingOutlined, CloudDownloadOutlined } from "@ant-design/icons";

// Hook
import { useDispatch, useSelector } from "react-redux";

// Styles
import "./Sales.scss";
import UserImage from "../../layouts/UserImage/UserImage";

// @Redxu
import { fetchSale, fetchNextSale, searchSale } from "../../redux/actions/auth";
import { SET_BUTTON_LOADING, CANCEL_BUTTON_LOADING } from "../../redux/types";

// @utils
import axios from "axios";
import ExportJsonExcel from "js-export-excel";
import moment from "moment";
import "moment/locale/tr";
import locale from "antd/es/date-picker/locale/tr_TR";
moment.locale("tr");

const antIcon = <LoadingOutlined style={{ fontSize: 128 }} spin />;
const { RangePicker } = DatePicker;
const { Search } = Input;
const Sales = () => {
  const dispatch = useDispatch();

  const loading = useSelector((state) => state.auth.loading);
  const sale = useSelector((state) => state.auth.sale);
  const listLoading = useSelector((state) => state.auth.listLoading);
  const buttonLoading = useSelector((state) => state.auth.buttonLoading);
  const [tabelData, setTabelData] = useState([]);
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [searchText, setSearchText] = useState("");
  const [search, setSearch] = useState(false);

  const columns = [
    {
      title: "Müşteri Adı",
      dataIndex: "customer",
      key: "customer",
      width: 300,
    },
    {
      title: "Kampanya Adı",
      dataIndex: "title",
      key: "title",
    },
    {
      title: "Satcı Adı",
      dataIndex: "seller",
      key: "seller",
    },

    {
      title: "Tarihi",
      dataIndex: "date",
      key: "date",
    },

    {
      title: "Normal Tutar",
      dataIndex: "salesAmountWithOutDiscount",
      key: "salesAmountWithOutDiscount",
    },
    {
      title: "İndirimli Tutar",
      dataIndex: "salesAmountWithDiscount",
      key: "salesAmountWithDiscount",
    },
    {
      title: "Jetd Komisyonu",
      dataIndex: "jetdProfitAmount",
      key: "jetdProfitAmount",
    },
    {
      title: "Net",
      dataIndex: "netDiscountRate",
      key: "netDiscountRate",
    },

    {
      title: "Ödeme Yöntemi",
      dataIndex: "paymentType",
      key: "paymentType",
    },
    {
      title: "Kazanılan Bonus",
      dataIndex: "settingDetailRateEarned",
      key: "settingDetailRateEarned",
    },
  ];

  const getBuyer = useCallback(async () => {
    dispatch(fetchSale());
  }, [dispatch]);

  useEffect(() => {
    getBuyer();
  }, [getBuyer]);

  useEffect(() => {
    const dataList = [];
    sale.forEach((b) => {
      dataList.push({
        key: b._id,
        customer: (
          <div className="sale-customer">
            <UserImage
              photoURL={
                b.LocalDiscount ? b.LocalDiscount?.image : b.user.photoURL
              }
            />
            <div>{b.user?.displayName}</div>
          </div>
        ),
        //   image: <UserImage photoURL={b.LocalDiscount?.image} />,

        title: (
          <>
            {b.LocalDiscount?.title > 30
              ? b.LocalDiscount?.title.substring(0, 20) + "..."
              : b.LocalDiscount?.title}
          </>
        ),
        seller: <>{b.seller?.companyName}</>,

        date: <>{moment(b.payedAt).format("Do MMMM YYYY HH:mm")}</>,
        salesAmountWithOutDiscount: (
          <>{b.salesAmountWithOutDiscount.toFixed(2)}₺</>
        ),
        salesAmountWithDiscount: <>{b.salesAmountWithDiscount.toFixed(2)}₺</>,
        jetdProfitAmount: <>{b.jetdProfitAmount.toFixed(2)}₺</>,
        netDiscountRate: <>{b.netDiscountRate}%</>,
        grossDiscountRate: <>{b.grossDiscountRate}%</>,
        paymentType: (
          <Tag
            className="mr-0"
            color={b.paymentType === "Kredi Kartı" ? "blue" : "geekblue"}
          >
            {b.paymentType}
          </Tag>
        ),

        settingDetailRateEarned: (
          <>
            {b.settingDetailRateEarned
              ? b.settingDetailRateEarned.toFixed(2)
              : 0}
            ₺
          </>
        ),
      });
    });
    setTabelData(dataList);
    // eslint-disable-next-line
  }, [sale]);

  const handleChange = (date, dateString) => {
    const s = dateString[0].split("-").join("/");
    const e = dateString[1].split("-").join("/");
    setStartDate(s);
    setEndDate(e);
  };

  const onButtonClick = async () => {
    dispatch({ type: SET_BUTTON_LOADING });
    try {
      const res = await axios.put(
        `https://jetd-backend.herokuapp.com/api/v1/sales/salesReport?s=${startDate}&e=${endDate}`
      );
      const data = res.data.data;
      const dataList = [];

      data.forEach((b) => {
        const isPlate = b.plates ? b.plates.plate : "";
        dataList.push({
          displayName: b.user.displayName,
          discountName: b.LocalDiscount.title,
          seller: b.seller.companyName,
          net: b.netDiscountRate,
          paymentType: b.paymentType,
          plaka: isPlate,
          salesAmountWithOutDiscount: b.salesAmountWithOutDiscount.toFixed(2),
          salesAmountWithDiscount: b.salesAmountWithDiscount.toFixed(2),
          jetdProfitAmount: b.jetdProfitAmount,
          date: moment(b.payedAt).format("Do MMMM YYYY HH:mm"),
          month: moment(b.payedAt).format("DD MMMM"),
          hour: moment(b.payedAt).format("HH"),
        });
      });
      const option = {
        fileName: `Satış Raporu ${moment().format("DD-MM-YYYY HH:mm")}`,
        datas: [
          {
            sheetData: dataList,
            sheetName: `Satış Raporu ${moment().format("DD-MM-YYYY HH:mm")}`,
            sheetFilter: [
              "displayName",
              "discountName",
              "seller",
              "net",
              "paymentType",
              "plaka",
              "salesAmountWithOutDiscount",
              "salesAmountWithDiscount",
              "jetdProfitAmount",
              "date",
              "month",
              "hour",
            ],
            sheetHeader: [
              "Müşteri Adı",
              "Kampanya Adı",
              "Satcı Adı",
              "Net İndirim",
              "Ödeme Yöntemi",
              "Plaka",
              "Normal Tutar",
              "Ödenen Tutar",
              "Jetd Komisyonu",
              "Tarih",
              "Ay",
              "Saat",
            ],
          },
        ],
      };
      const toExcel = new ExportJsonExcel(option);
      toExcel.saveExcel();

      dispatch({ type: CANCEL_BUTTON_LOADING });
    } catch (error) {
      dispatch({ type: CANCEL_BUTTON_LOADING });
      console.log(error);
    }
  };

  const onButtonSeClick = async () => {
    const dataList = [];
    sale.forEach((b) => {
      const isPlate = b.plates ? b.plates.plate : "";

      dataList.push({
        displayName: b.user.displayName,
        discountName: b.LocalDiscount.title,
        net: b.netDiscountRate,
        paymentType: b.paymentType,
        plaka: isPlate,
        salesAmountWithOutDiscount: b.salesAmountWithOutDiscount.toFixed(2),
        salesAmountWithDiscount: b.salesAmountWithDiscount.toFixed(2),
        jetdProfitAmount: b.jetdProfitAmount,
        date: moment(b.payedAt).format("Do MMMM YYYY HH:mm"),
      });
    });
    const option = {
      fileName: `Satış Raporu ${moment().format("DD-MM-YYYY HH:mm")}`,
      datas: [
        {
          sheetData: dataList,
          sheetName: `Satış Raporu ${moment().format("DD-MM-YYYY")}`,
          sheetFilter: [
            "displayName",
            "discountName",
            "net",
            "paymentType",
            "plaka",
            "salesAmountWithOutDiscount",
            "salesAmountWithDiscount",
            "jetdProfitAmount",
            "date",
          ],
          sheetHeader: [
            "Müşteri Adı",
            "Kampanya Adı",
            "Net İndirim",
            "Ödeme Yöntemi",
            "Plaka",
            "Normal Tutar",
            "Ödenen Tutar",
            "Jetd Komisyonu",
            "Tarih",
          ],
        },
      ],
    };
    const toExcel = new ExportJsonExcel(option);
    toExcel.saveExcel();
  };

  const cahngTextHandler = (e) => {
    setSearchText(e.target.value);
  };

  const searchHandler = () => {
    if (searchText === "") {
      dispatch(fetchSale());
      setSearch(false);
    } else {
      dispatch(searchSale(searchText.trim()));
      setSearch(true);
    }
    console.log("search");
  };

  return (
    <PagerContainer>
      {loading ? (
        <Spin indicator={antIcon} className="spinner" />
      ) : (
        <>
          <div className="discount-title">
            <h1>Tüm Satışlar</h1>
          </div>
          <div className="sales-date-container">
            <Search
              enterButton
              size="large"
              placeholder="Müşteri Adı Veya Satcı Adı Ara..."
              onSearch={searchHandler}
              onChange={cahngTextHandler}
              value={searchText}
            />

            <>
              {!search ? (
                <>
                  <RangePicker
                    placeholder={["Başlangıç Tarihi", "Bitiş Tarihi"]}
                    locale={locale}
                    onChange={handleChange}
                    ranges={{
                      Bugün: [moment(), moment()],
                      "Bu Ay": [
                        moment().startOf("month"),
                        moment().endOf("month"),
                      ],
                      "Geçen Ay": [
                        moment().subtract(1, "months").startOf("month"),
                        moment().subtract(1, "months").endOf("month"),
                      ],
                    }}
                  />

                  <Button
                    onClick={onButtonClick}
                    icon={<CloudDownloadOutlined />}
                    loading={buttonLoading}
                    disabled={!startDate || !endDate}
                  >
                    Raporu İndir
                  </Button>
                </>
              ) : (
                <Button
                  onClick={onButtonSeClick}
                  icon={<CloudDownloadOutlined />}
                  loading={buttonLoading}
                >
                  Raporu İndir
                </Button>
              )}
            </>
          </div>

          {sale.length !== 0 ? (
            <>
              <Table
                columns={columns}
                dataSource={tabelData}
                pagination={false}
                className="tableContainer"
                loading={listLoading}
                scroll={{ x: 1200 }}
                bordered={false}
              />
              <div className="discount-container">
                <Button
                  type="primary"
                  onClick={() => {
                    dispatch(fetchNextSale());
                  }}
                  disabled={search}
                >
                  Daha Fazla Yükle
                </Button>
              </div>
            </>
          ) : (
            <h1>Satış bulunamadı</h1>
          )}
        </>
      )}
    </PagerContainer>
  );
};

export default Sales;
